import { Button } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import matchItRecording from "../../assets/MatchItRecording.mov";
import {
  CreatorPanel,
  DemoVideoPanel,
  MatchTitlePanel,
} from "../components/AnimatedSlideshow";
import PageLayout from "./PageLayout";
import styles from "./QuickPlayMatchHomePage.module.scss";

const QuickPlayMatchHomePage = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Update windowWidth whenever the window is resized
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [currentComponent, setCurrentComponent] = useState(0);
  const PANEL_SWITCH_DURATION = 4800;

  useEffect(() => {
    const timer = setTimeout(() => {
      // Switch to the next component after 4.5 seconds
      if (currentComponent < 2) {
        setCurrentComponent((prevComponent) => prevComponent + 1);
      }
    }, PANEL_SWITCH_DURATION);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, [currentComponent]);
  // declare the body component
  const body = (
    <>
      {currentComponent === 0 && <MatchTitlePanel />}
      {currentComponent === 1 && <CreatorPanel />}
      {currentComponent === 2 && (
        <DemoVideoPanel
          setCurrentComponent={setCurrentComponent}
          videoSrc={matchItRecording}
        />
      )}
      <Link to="/matchit/game" className={styles.startButtonArea}>
        <Button
          type="primary"
          block
          size="large"
          className={styles.startButton}
          style={{ marginTop: "1rem" }}
        >
          Play MatchIt!
        </Button>
      </Link>
    </>
  );
  return <PageLayout displayMode="overlay" body={body} />;
};

export default QuickPlayMatchHomePage;

import { PlusSquareOutlined } from "@ant-design/icons";
import { Button, ConfigProvider } from "antd";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getErrorCode, getErrorMessage } from "../../apiHandlers/apiUtils";
import {
  createClassroomTest,
  deleteClassroomTest,
  getAllClassroomTests,
  updateClassroomTest,
} from "../../apiHandlers/classroomTestApiHandler";
import { ACCESS_DENIED_ERROR_MSG } from "../../constants/error";
import { useAuth } from "../../contexts/AuthContext";
import { showOrUpdateExistingErrorToast, showOrUpdateExistingInfoToast } from "../../utils/toastUtils";
import ClassroomNavBar from "../components/ClassroomNavBar";
import ClassroomTestList from "../components/ClassroomTestList";
import ConfirmationModal from "../components/ConfirmationModal";
import EditTestModal, { CREATE_TEST_MODE, UPDATE_TEST_MODE } from "../components/EditTestModal";
import ErrorBody from "../components/ErrorBody";
import LoadingSpinBody from "../components/LoadingSpinBody";
import styles from "./ClassroomTestsPage.module.scss";
import PageLayout from "./PageLayout";

function ClassroomTestsPage() {
  const [tests, setTests] = useState([]);
  const [testsLoading, setTestsLoading] = useState(true);
  const [testsLoadingFailed, setTestsLoadingFailed] = useState(false);

  const [editTestModalOpen, setEditTestModalOpen] = useState(false);
  const [editConfirmLoading, setEditConfirmLoading] = useState(false);
  const [editModalMode, setEditModalMode] = useState(CREATE_TEST_MODE);

  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);
  const [deleteTestLoading, setDeleteTestLoading] = useState(false);
  const [deleteTestId, setDeleteTestId] = useState(null);
  const [deleteTestName, setDeleteTestName] = useState("");

  const [testBeingUpdated, setTestBeingUpdated] = useState(null);

  const { handleLogout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    setTestsLoading(true);
    // TODO: Lazy loading
    const fetchData = async () => {
      try {
        const tests = await getAllClassroomTests();
        // TODO: no need to check tests? code automatically jump to catch on error
        if (tests) {
          setTestsLoading(false);
          setTests(() => sortTestsByLastEdit(tests).map(test => convertTestModel(test)));
        }
      } catch (err) {
        if (getErrorCode(err) === 401) {
          await handleLogout();
          toast.error(ACCESS_DENIED_ERROR_MSG);
          navigate("/classroom/login", { replace: true });
        }
        setTestsLoading(false);
        setTestsLoadingFailed(true);
      }
    };
    fetchData();
  }, []);

  const sortTestsByLastEdit = (tests) => {
    return tests.sort((testA, testB) => testB.last_modified_at - testA.last_modified_at);
  };

  const convertTestModel = (test) => ({
    id: test.id,
    name: test.quiz_name,
    characters: test.characters,
    // TODO: look at case of variable name "created_at" vs "createdAt"
    // TODO: extract time utils if needed
    createdAt: DateTime.fromMillis(test.created_at).toLocaleString(DateTime.DATETIME_MED),
    lastModifiedAt: DateTime.fromMillis(test.last_modified_at).toLocaleString(DateTime.DATETIME_MED),
    imageUrls: test.imageUrls,
  });

  const openEditTestModal = (mode) => {
    setEditModalMode(mode);
    setEditTestModalOpen(true);
  };

  const handleCancelEditTest = (callback) => {
    setEditTestModalOpen(false);
    setTestBeingUpdated(null);
    callback();
  };

  const handleConfirmCreateTest = async (classroomTest, callback) => {
    setEditConfirmLoading(true);
    setTimeout(async () => {
      try {
        const createdTest = await createClassroomTest(classroomTest);
        if (createdTest) {
          setTests(prevTests => [
              convertTestModel(createdTest),
              ...prevTests,
            ],
          );
          toast.success("Success", { autoClose: 600 });
          setEditConfirmLoading(false);
          setEditTestModalOpen(false);
          // TODO: investigate if this callback is necessary (antd has built-in functions)
          callback();
        }
      } catch (err) {
        // TODO: update success toast to use the same ID so it's consistent
        const toastId = "create-test-error-toast";
        showOrUpdateExistingErrorToast(toastId, getErrorMessage(err));
        setEditConfirmLoading(false);
      }
    }, 50);  // the loading icon of the Create button in the modal does not always behave correctly
    // if the loading status is set to false too quickly, which may be an antd bug
  };

  const handleDeleteTest = (test) => {
    setDeleteTestId(test.id);
    setDeleteTestName(test.name);
    setDeleteConfirmModalOpen(true);
  };

  const handleCancelDeleteTest = () => {
    setDeleteConfirmModalOpen(false);
  };

  const handleConfirmDeleteTest = async () => {
    if (!deleteTestId) return;

    setDeleteTestLoading(true);
    setTimeout(async () => {
      try {
        await deleteClassroomTest(deleteTestId);
        setTests(prevTests => prevTests.filter(t => t.id !== deleteTestId));
        toast.success("Success", { autoClose: 600 });
        setDeleteConfirmModalOpen(false);
        setDeleteTestLoading(false);
      } catch (err) {
        const toastId = "delete-test-error-toast";
        showOrUpdateExistingErrorToast(toastId, getErrorMessage(err));
        setDeleteTestLoading(false);
      }
    }, 50);  // the loading icon of the Confirm button in the modal does not always behave correctly
    // if the loading status is set to false too quickly, which may be an antd bug
  };

  const isTestModified = (classroomTest) => {
    if (!classroomTest || !testBeingUpdated) return false;
    if (classroomTest.quiz_name !== testBeingUpdated.name) return true;
    if (classroomTest.character_ids.length !== testBeingUpdated.characters.length) return true;
    for (let i = 0; i < classroomTest.character_ids.length; i++) {
      if (classroomTest.character_ids[i] !== testBeingUpdated.characters[i].id) return true;
    }
    return true; // always set true for now, we are not checking for if imagesModified
  };

  const handleUpdateTest = (test) => {
    setTestBeingUpdated(test);
    openEditTestModal(UPDATE_TEST_MODE);
  };

  const handleConfirmUpdateTest = (classroomTest, callback) => {
    if (!testBeingUpdated) return;
    if (!isTestModified(classroomTest)) {
      showOrUpdateExistingInfoToast("test-not-modified-info-toast", "Test is not changed");
      return;
    }

    setEditConfirmLoading(true);
    setTimeout(async () => {
      try {
        const updatedTest = await updateClassroomTest(testBeingUpdated.id, classroomTest);
        if (updatedTest) {
          setTests(prevTests => [
              convertTestModel(updatedTest),
              ...prevTests.filter(t => t.id !== testBeingUpdated.id),
            ],
          );
          toast.success("Success", { autoClose: 600 });
          setEditConfirmLoading(false);
          setEditTestModalOpen(false);
          setTestBeingUpdated(null);
          // TODO: investigate if this callback is necessary (antd has built-in functions)
          callback();
        }
      } catch (err) {
        // TODO: handle test does not exist more elegantly
        // TODO: update success toast to use the same ID so it's consistent
        const toastId = "update-test-error-toast";
        showOrUpdateExistingErrorToast(toastId, getErrorMessage(err));
        setEditConfirmLoading(false);
      }
    }, 50);  // the loading icon of the Confirm button in the modal does not always behave correctly
    // if the loading status is set to false too quickly, which may be an antd bug
  };

  const header = (
    <ClassroomNavBar />
  );

  let body;
  if (testsLoading) {
    body = <LoadingSpinBody />;
  } else if (testsLoadingFailed) {
    body = <ErrorBody message="Failed to load tests." />;
  } else {
    body = (
      <div className={styles.body}>
        <div className={styles["btn-create-test-container"]}>
          <ConfigProvider theme={{
            components: {
              Button: {
                colorPrimary: "#139489",
                contentFontSizeLG: 22,
                fontWeight: "500",
                algorithm: true,
              },
            },
          }}>
            {/* TODO: Icon style*/}
            <Button type="primary" icon={<PlusSquareOutlined />} size="large"
                    onClick={() => openEditTestModal(CREATE_TEST_MODE)}>Create new test</Button>
          </ConfigProvider>
        </div>

        <EditTestModal
          open={editTestModalOpen}
          loading={editConfirmLoading}
          onCancel={handleCancelEditTest}
          onConfirm={editModalMode === CREATE_TEST_MODE ? handleConfirmCreateTest : handleConfirmUpdateTest}
          mode={editModalMode}
          existingTest={testBeingUpdated}
        />

        <ConfirmationModal
          title="Confirm test deletion"
          message={`This action cannot be undone. Are you sure you want to delete Test "${deleteTestName}"?`}
          open={deleteConfirmModalOpen}
          loading={deleteTestLoading}
          onCancel={handleCancelDeleteTest}
          onConfirm={handleConfirmDeleteTest}
          dangerConfirmButton={true}
        />
        <div>
          <ClassroomTestList tests={tests} onEditClick={handleUpdateTest} onDeleteClick={handleDeleteTest} />
        </div>
      </div>
    );
  }

  return (
    <PageLayout header={header} body={body} displayMode={"normal"} showNUSLogos={true} />
  );
}

export default ClassroomTestsPage;

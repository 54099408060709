import { Col, Row, Button, Flex } from "antd";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./SrsResearchPage.module.scss";
import PageLayout from "./PageLayout";

const SrsResearchPage = () => {
  const { state } = useLocation();
  const [userId, setUserId] = useState("");

  const handleSubmitUserDetails = (e) => {
    e.preventDefault();
  };

  const body = (
    <>
      <Row className={styles["main-container"]}>
        <Col span={18} className={styles["right-container"]}>
          <br />
          <br />
          <h1>Research Mode</h1>
          <p>
            The Pin-It Research Team is looking into the use of spaced
            repetition algorithms.
          </p>
          <b>This feature is not linked to your actual Pin-It account.</b>
          <br />
          <form onSubmit={handleSubmitUserDetails}>
            <label htmlFor="user_id">Enter User ID: </label>
            <input
              style={{
                width: "50%",
                marginTop: "5px",
                padding: "6px",
                border: "1px solid #ddd",
                borderRadius: "6px",
                fontSize: "13.5px",
                backgroundColor: "#fafafa",
                outline: "none",
                transition: "border 0.3s ease, box-shadow 0.3s ease",
              }}
              type="text"
              id="user_id"
              name="user_id"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
              required
            />
            <br />
            <br />
            <Flex gap="small" wrap>
              <Link
                to={`/srs-research/review?user_id=${encodeURIComponent(
                  userId
                )}`}
              >
                <Button type="primary" htmlType="submit" size="middle">
                  Start Today's Review
                </Button>
              </Link>
              {state?.isEmpty && (
                <p>There are no reviews scheduled for today in this account.</p>
              )}
            </Flex>
          </form>
        </Col>
      </Row>
    </>
  );
  return <PageLayout body={body} displayMode="normal" showNUSLogos={true} />;
};

export default SrsResearchPage;

import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import { AuthProvider } from "./contexts/AuthContext";
import ClassroomRoutes from "./routes/ClassroomRoutes";
import AppDownloadPage from "./views/pages/AppDownloadPage";
import ClassroomTestLiveParticipantPage from "./views/pages/ClassroomTestLiveParticipantPage";
import Home from "./views/pages/HomePage.js";
import QuickPlayGamePage from "./views/pages/QuickPlayGamePage.js";
import QuickPlayHomePage from "./views/pages/QuickPlayHomePage.js";
import QuickPlaySharingPage from "./views/pages/QuickPlaySharingPage.js";
import QuickPlaySummaryPage from "./views/pages/QuickPlaySummaryPage.js";
import ClassroomTestAssignmentPage from "./views/pages/ClassroomTestAssignmentPage";
import ClassroomRegisterPage from "./views/pages/ClassroomRegisterPage";
import QuickPlayMatchHomePage from "./views/pages/QuickPlayMatchHomePage.js";
import QuickPlayMatchGamePage from "./views/pages/QuickPlayMatchGamePage.js";
import QuickPlayGameWithHintsPage from "./views/pages/QuickPlayGameWithHintsPage.js";
import SrsResearchPage from "./views/pages/SrsResearchPage.js";
import SrsReviewSessionPage from "./views/pages/SrsReviewSessionPage.js";
import QuickPlayGameWithHintsTestPage from "./views/pages/QuickPlayGameWithHintsTestPage.js";
import QuickPlayGameTestPage from "./views/pages/QuickPlayGameTestPage.js";

export default function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />

          <Route path="/quickplay" element={<QuickPlayHomePage />} />
          <Route path="/quickplay/game" element={<QuickPlayGamePage />} />
          <Route path="/quickplay/summary" element={<QuickPlaySummaryPage />} />
          <Route
            path="/quickplay/share/:shareId"
            element={<QuickPlaySharingPage />}
          />

          <Route
            path="/quickplay/v2/game"
            element={<QuickPlayGameWithHintsPage />}
          />
          <Route
            path="/quickplay/experiment/game/g2v"
            element={<QuickPlayGameWithHintsTestPage />}
          />
          <Route
            path="/quickplay/experiment/game/g1c"
            element={<QuickPlayGameTestPage />}
          />
          <Route path="/matchit" element={<QuickPlayMatchHomePage />} />
          <Route path="/matchit/game" element={<QuickPlayMatchGamePage />} />

          <Route
            path="/classroom/*"
            element={
              <AuthProvider>
                <ClassroomRoutes />
              </AuthProvider>
            }
          />
          <Route path="/join" element={<ClassroomTestLiveParticipantPage />} />
          <Route path="/assignment" element={<ClassroomTestAssignmentPage />} />
          <Route path="/register" element={<ClassroomRegisterPage />} />

          <Route path="/download-app" element={<AppDownloadPage />} />

          <Route path="/srs-research" element={<SrsResearchPage />} />
          <Route
            path="/srs-research/review"
            element={<SrsReviewSessionPage />}
          />

          <Route path="/srs-research/finish" element={<SrsResearchPage />} />

          {/* default redirect to home page */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme="light"
        transition={Zoom}
      />
    </div>
  );
}

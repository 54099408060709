import { apiSecure } from "./apiSecure";

const IMAGES_ENDPOINT = "/web/images";

export const getImages = (characterId, query, page, imagesPerPage, isManualSearch) => {
  return apiSecure.get(`${IMAGES_ENDPOINT}/search`, {
    params: {
      query,
      page,
      perPage: imagesPerPage,
      characterId,
      isManualSearch
    },
  });
};

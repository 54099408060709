import { InfoCircleFilled } from "@ant-design/icons";
import styles from "./FeedbackNotification.module.scss";

function FeedbackNotification(svg, description) {
  return (
    <div className={styles.notificationBox}>
      <InfoCircleFilled className={styles.infoIcon} />
      <div className={styles.svg}>{svg && svg}</div>
      <span className={styles.description}>{description}</span>
    </div>
  );
}

export default FeedbackNotification;

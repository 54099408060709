import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { getQuickPlaySession } from "../../apiHandlers/gameApi";
import DragAndDropGameWithHints from "../components/DragAndDropGameWithHints";
import PageLayout from "./PageLayout";
import styles from "./QuickPlayGameWithHintsPage.module.scss";

const QuickPlayGameWithHintsPage = () => {
  const [gameData, setGameData] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const diffLevel = -1;
      try {
        const data = await getQuickPlaySession(diffLevel);
        setGameData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  const body = (
    <div className={styles.gameMenu}>
      {gameData === null ? (
        <div className={styles.loading}>
          <Spin size="large" />
        </div>
      ) : (
        <DragAndDropGameWithHints
          gameContent={gameData}
          maxRoundCount={15}
          gameMode={1}
        />
      )}
    </div>
  );

  return <PageLayout body={body} displayMode={"overlay"} />;
};

export default QuickPlayGameWithHintsPage;

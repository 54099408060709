import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  CreatorPanel,
  DemoPanel,
  TitlePanel,
} from "../components/AnimatedSlideshow";
import PageLayout from "./PageLayout";
import styles from "./QuickPlayHomePage.module.scss";
import pinDemonstrationGif from "../../assets/pin-illustration.gif";

const QuickPlayGamePage = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Update windowWidth whenever the window is resized
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [currentComponent, setCurrentComponent] = useState(0);
  const PANEL_SWITCH_DURATION = 4500;

  useEffect(() => {
    const timer = setTimeout(() => {
      // Switch to the next component after 4.5 seconds
      if (currentComponent < 2) {
        setCurrentComponent((prevComponent) => prevComponent + 1);
      }
    }, PANEL_SWITCH_DURATION);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, [currentComponent]);

  const body = (
    <>
      {currentComponent === 0 && <TitlePanel />}
      {currentComponent === 1 && <CreatorPanel />}
      {currentComponent === 2 && (
        <DemoPanel
          setCurrentComponent={setCurrentComponent}
          imageSrc={pinDemonstrationGif}
        />
      )}
      <Link to="/quickplay/game" className={styles.startButtonArea}>
        <Button
          type="primary"
          block
          size="large"
          className={styles.startButton}
        >
          Quick Play
        </Button>
      </Link>
    </>
  );

  return <PageLayout body={body} displayMode={"overlay"} />;
};

export default QuickPlayGamePage;

import { ReactComponent as EncloseInner } from "../assets/feedback_svg/ENCLOSE_INNER.svg";
import { ReactComponent as EncloseOuter } from "../assets/feedback_svg/ENCLOSE_OUTER.svg";
import { ReactComponent as HalfBottomCenterInner } from "../assets/feedback_svg/HALF_BOTTOM_CENTER_INNER.svg";
import { ReactComponent as HalfBottomCenterOuter } from "../assets/feedback_svg/HALF_BOTTOM_CENTER_OUTER.svg";
import { ReactComponent as HalfBottomLeftInner } from "../assets/feedback_svg/HALF_BOTTOM_LEFT_INNER.svg";
import { ReactComponent as HalfBottomLeftOuter } from "../assets/feedback_svg/HALF_BOTTOM_LEFT_OUTER.svg";
import { ReactComponent as HalfBottomRightInner } from "../assets/feedback_svg/HALF_BOTTOM_RIGHT_INNER.svg";
import { ReactComponent as HalfBottomRightOuter } from "../assets/feedback_svg/HALF_BOTTOM_RIGHT_OUTER.svg";
import { ReactComponent as HalfMidRightInner } from "../assets/feedback_svg/HALF_MID_RIGHT_INNER.svg";
import { ReactComponent as HalfMidRightOuter } from "../assets/feedback_svg/HALF_MID_RIGHT_OUTER.svg";
import { ReactComponent as HalfTopCenterInner } from "../assets/feedback_svg/HALF_TOP_CENTER_INNER.svg";
import { ReactComponent as HalfTopCenterOuter } from "../assets/feedback_svg/HALF_TOP_CENTER_OUTER.svg";
import { ReactComponent as HalfTopRightInner } from "../assets/feedback_svg/HALF_TOP_RIGHT_INNER.svg";
import { ReactComponent as HalfTopRightOuter } from "../assets/feedback_svg/HALF_TOP_RIGHT_OUTER.svg";
import { ReactComponent as LeftCenterRight1 } from "../assets/feedback_svg/LEFT_CENTER_RIGHT_1.svg";
import { ReactComponent as LeftCenterRight2 } from "../assets/feedback_svg/LEFT_CENTER_RIGHT_2.svg";
import { ReactComponent as LeftCenterRight3 } from "../assets/feedback_svg/LEFT_CENTER_RIGHT_3.svg";
import { ReactComponent as LeftRight1 } from "../assets/feedback_svg/LEFT_RIGHT_1.svg";
import { ReactComponent as LeftRight2 } from "../assets/feedback_svg/LEFT_RIGHT_2.svg";
import { ReactComponent as TopBottom1 } from "../assets/feedback_svg/TOP_BOTTOM_1.svg";
import { ReactComponent as TopBottom2 } from "../assets/feedback_svg/TOP_BOTTOM_2.svg";
import { ReactComponent as TopMidBottom1 } from "../assets/feedback_svg/TOP_MID_BOTTOM_1.svg";
import { ReactComponent as TopMidBottom2 } from "../assets/feedback_svg/TOP_MID_BOTTOM_2.svg";
import { ReactComponent as TopMidBottom3 } from "../assets/feedback_svg/TOP_MID_BOTTOM_3.svg";

export const RadicalPosition = {
  ENCLOSE_INNER: {
    path: "src/assets/feedback_svg/ENCLOSE_INNER.svg",
    svg: <EncloseInner width={40} height={40} />,
    description: "Enclose Structure Inner Position",
    url: "", // Fill up with s3 url
  },
  ENCLOSE_OUTER: {
    path: "src/assets/feedback_svg/ENCLOSE_OUTER.svg",
    svg: <EncloseOuter width={40} height={40} />,
    description: "Enclose Structure Outer Position",
    url: "",
  },
  HALF_BOTTOM_CENTER_INNER: {
    path: "src/assets/feedback_svg/HALF_BOTTOM_CENTER_INNER.svg",
    svg: <HalfBottomCenterInner width={40} height={40} />,
    description: "Bottom Center Structure Inner Position",
    url: "",
  },
  HALF_BOTTOM_CENTER_OUTER: {
    path: "src/assets/feedback_svg/HALF_BOTTOM_CENTER_OUTER.svg",
    svg: <HalfBottomCenterOuter width={40} height={40} />,
    description: "Bottom Center Structure Outer Position",
    url: "",
  },
  HALF_BOTTOM_LEFT_INNER: {
    path: "src/assets/feedback_svg/HALF_BOTTOM_LEFT_INNER.svg",
    svg: <HalfBottomLeftInner width={40} height={40} />,
    description: "Bottom Left Structure Inner Position",
    url: "",
  },
  HALF_BOTTOM_LEFT_OUTER: {
    path: "src/assets/feedback_svg/HALF_BOTTOM_LEFT_OUTER.svg",
    svg: <HalfBottomLeftOuter width={40} height={40} />,
    description: "Bottom Left Structure Outer Position",
    url: "",
  },
  HALF_BOTTOM_RIGHT_INNER: {
    path: "src/assets/feedback_svg/HALF_BOTTOM_RIGHT_INNER.svg",
    svg: <HalfBottomRightInner width={40} height={40} />,
    description: "Bottom Right Structure Inner Position",
    url: "",
  },
  HALF_BOTTOM_RIGHT_OUTER: {
    path: "src/assets/feedback_svg/HALF_BOTTOM_RIGHT_OUTER.svg",
    svg: <HalfBottomRightOuter width={40} height={40} />,
    description: "Bottom Right Structure Outer Position",
    url: "",
  },
  HALF_MID_RIGHT_INNER: {
    path: "src/assets/feedback_svg/HALF_MID_RIGHT_INNER.svg",
    svg: <HalfMidRightInner width={40} height={40} />,
    description: "Middle Right Structure Inner Position",
    url: "",
  },
  HALF_MID_RIGHT_OUTER: {
    path: "src/assets/feedback_svg/HALF_MID_RIGHT_OUTER.svg",
    svg: <HalfMidRightOuter width={40} height={40} />,
    description: "Middle Right Structure Outer Position",
    url: "",
  },
  HALF_TOP_CENTER_INNER: {
    path: "src/assets/feedback_svg/HALF_TOP_CENTER_INNER.svg",
    svg: <HalfTopCenterInner width={40} height={40} />,
    description: "Top Center Structure Inner Position",
    url: "",
  },
  HALF_TOP_CENTER_OUTER: {
    path: "src/assets/feedback_svg/HALF_TOP_CENTER_OUTER.svg",
    svg: <HalfTopCenterOuter width={40} height={40} />,
    description: "Top Center Structure Outer Position",
    url: "",
  },
  HALF_TOP_RIGHT_INNER: {
    path: "src/assets/feedback_svg/HALF_TOP_RIGHT_INNER.svg",
    svg: <HalfTopRightInner width={40} height={40} />,
    description: "Top Right Structure Inner Position",
    url: "",
  },
  HALF_TOP_RIGHT_OUTER: {
    path: "src/assets/feedback_svg/HALF_TOP_RIGHT_OUTER.svg",
    svg: <HalfTopRightOuter width={40} height={40} />,
    description: "Top Right Structure Outer Position",
    url: "",
  },
  LEFT_CENTER_RIGHT_1: {
    path: "src/assets/feedback_svg/LEFT_CENTER_RIGHT_1.svg",
    svg: <LeftCenterRight1 width={40} height={40} />,
    description: "Left Position",
    url: "",
  },
  LEFT_CENTER_RIGHT_2: {
    path: "src/assets/feedback_svg/LEFT_CENTER_RIGHT_2.svg",
    svg: <LeftCenterRight2 width={40} height={40} />,
    description: "Center Position",
    url: "",
  },
  LEFT_CENTER_RIGHT_3: {
    path: "src/assets/feedback_svg/LEFT_CENTER_RIGHT_3.svg",
    svg: <LeftCenterRight3 width={40} height={40} />,
    description: "Right Position",
    url: "",
  },
  LEFT_RIGHT_1: {
    path: "src/assets/feedback_svg/LEFT_RIGHT_1.svg",
    svg: <LeftRight1 width={40} height={40} />,
    description: "Left Position",
    url: "",
  },
  LEFT_RIGHT_2: {
    path: "src/assets/feedback_svg/LEFT_RIGHT_2.svg",
    svg: <LeftRight2 width={40} height={40} />,
    description: "Right Position",
    url: "",
  },
  TOP_BOTTOM_1: {
    path: "src/assets/feedback_svg/TOP_BOTTOM_1.svg",
    svg: <TopBottom1 width={40} height={40} />,
    description: "Top Position",
    url: "",
  },
  TOP_BOTTOM_2: {
    path: "src/assets/feedback_svg/TOP_BOTTOM_2.svg",
    svg: <TopBottom2 width={40} height={40} />,
    description: "Bottom Position",
    url: "",
  },
  TOP_MID_BOTTOM_1: {
    path: "src/assets/feedback_svg/TOP_MID_BOTTOM_1.svg",
    svg: <TopMidBottom1 width={40} height={40} />,
    description: "Top Position",
    url: "",
  },
  TOP_MID_BOTTOM_2: {
    path: "src/assets/feedback_svg/TOP_MID_BOTTOM_2.svg",
    svg: <TopMidBottom2 width={40} height={40} />,
    description: "Middle Position",
    url: "",
  },
  TOP_MID_BOTTOM_3: {
    path: "src/assets/feedback_svg/TOP_MID_BOTTOM_3.svg",
    svg: <TopMidBottom3 width={40} height={40} />,
    description: "Bottom Position",
    url: "",
  },
};

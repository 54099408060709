// This file contains demo data for DragAndDropGame.js

export const gameContent = {
  data: [
    {
      model_type: "TRAINING_DRAG_DROP",
      chinese_character: {
        id: 2076,
        character_unicode: "[8a93]",
        chinese: "誓",
        structure_type: "TOP_BOTTOM",
        pinyin: "shì",
        meaning: "vow, oath",
        word: null,
        word_meaning: null,
        word_pinyin: null,
        character_svg_path: "characters/2076/2076_char.svg",
        audio_path: "audio/shi4.mp3",
        image_path: null,
      },
      fragment_masks: [
        {
          id: 3714,
          character_unicode: "[8a93]",
          character_id: 2076,
          position: "BOTTOM",
          mask_svg_path: "characters/2076/2076_mask_b.svg",
        },
        {
          id: 3715,
          character_unicode: "[8a93]",
          character_id: 2076,
          position: "TOP",
          mask_svg_path: "characters/2076/2076_mask_t.svg",
        },
      ],
      fragment_options: [
        {
          id: 3714,
          character_unicode: "[8a93]",
          character_id: 2076,
          position: "BOTTOM",
          option_svg_path: "characters/2076/2076_option_b.svg",
          option_unicode: "[8a00]",
          option_info: "",
          temporary_remove: 1,
        },
        {
          id: 4636,
          character_unicode: "[7092]",
          character_id: 2551,
          position: "WRONG",
          option_svg_path: "characters/2551/2551_option_r.svg",
          option_unicode: "[5c11]",
          option_info: "",
          temporary_remove: 0,
        },
        {
          id: 4813,
          character_unicode: "[9635]",
          character_id: 2638,
          position: "WRONG",
          option_svg_path: "characters/2638/2638_option_r.svg",
          option_unicode: "[8f66]",
          option_info: "",
          temporary_remove: 0,
        },
        {
          id: 2588,
          character_unicode: "[5300]",
          character_id: 1509,
          position: "WRONG",
          option_svg_path: "characters/1509/1509_option_o.svg",
          option_unicode: "[52f9]",
          option_info: "",
          temporary_remove: 0,
        },
        {
          id: 3715,
          character_unicode: "[8a93]",
          character_id: 2076,
          position: "TOP",
          option_svg_path: "characters/2076/2076_option_t.svg",
          option_unicode: "[6298]",
          option_info: "",
          temporary_remove: 1,
        },
      ],
    },
    {
      model_type: "TRAINING_DRAG_DROP",
      chinese_character: {
        id: 17,
        character_unicode: "[53ef]",
        chinese: "可",
        structure_type: "HALF_BOTTOM_LEFT",
        pinyin: "kě",
        meaning: "can,may",
        word: "可以",
        word_meaning: "may,be able to",
        word_pinyin: "kěyǐ",
        character_svg_path: "characters/0017/0017_01_02_char.svg",
        audio_path: "audio/ke3.mp3",
        image_path: null,
      },
      fragment_masks: [
        {
          id: 5,
          character_unicode: "[53ef]",
          character_id: 17,
          position: "INNER",
          mask_svg_path: "characters/0017/0017_01_02_mask_i.svg",
        },
        {
          id: 6,
          character_unicode: "[53ef]",
          character_id: 17,
          position: "OUTER",
          mask_svg_path: "characters/0017/0017_01_02_mask_o.svg",
        },
      ],
      fragment_options: [
        {
          id: 253,
          character_unicode: "[8bfe]",
          character_id: 204,
          position: "WRONG",
          option_svg_path: "characters/0204/0204_02_09_option_l.svg",
          option_unicode: "[8ba0]",
          option_info: " 讠relates to language",
          temporary_remove: 0,
        },
        {
          id: 4,
          character_unicode: "[54e5]",
          character_id: 16,
          position: "WRONG",
          option_svg_path: "characters/0016/0016_01_02_option_t.svg",
          option_unicode: "[53efX]",
          option_info: "",
          temporary_remove: 0,
        },
        {
          id: 6,
          character_unicode: "[53ef]",
          character_id: 17,
          position: "OUTER",
          option_svg_path: "characters/0017/0017_01_02_option_o.svg",
          option_unicode: "[4e01X]",
          option_info: "",
          temporary_remove: 0,
        },
        {
          id: 607,
          character_unicode: "[961f]",
          character_id: 423,
          position: "WRONG",
          option_svg_path: "characters/0423/0423_03_15_option_l.svg",
          option_unicode: "[961d]",
          option_info: "",
          temporary_remove: 0,
        },
        {
          id: 5,
          character_unicode: "[53ef]",
          character_id: 17,
          position: "INNER",
          option_svg_path: "characters/0017/0017_01_02_option_i.svg",
          option_unicode: "[53e3]",
          option_info: "",
          temporary_remove: 0,
        },
      ],
    },
    {
      model_type: "TRAINING_DRAG_DROP",
      chinese_character: {
        id: 4,
        character_unicode: "[62fc]",
        chinese: "拼",
        structure_type: "LEFT_RIGHT",
        pinyin: "pīn",
        meaning: "to merge; to go all out",
        word: "拼字",
        word_meaning: "scrabble",
        word_pinyin: "pīn zì",
        character_svg_path: "characters/2067/2067_char.svg",
        audio_path: "audio/pin1.mp3",
        image_path: "mcq_images/2067.jpg",
      },
      fragment_masks: [
        {
          id: 3696,
          character_unicode: "[62fc]",
          character_id: 2067,
          position: "LEFT",
          mask_svg_path: "characters/2067/2067_mask_l.svg",
        },
        {
          id: 3697,
          character_unicode: "[62fc]",
          character_id: 2067,
          position: "RIGHT",
          mask_svg_path: "characters/2067/2067_mask_r.svg",
        },
      ],
      fragment_options: [
        {
          id: 3697,
          character_unicode: "[62fc]",
          character_id: 2067,
          position: "RIGHT",
          option_svg_path: "characters/2067/2067_option_r.svg",
          option_unicode: "[5e76]",
          option_info: "",
          temporary_remove: 0,
        },
        {
          id: 2,
          character_unicode: "[6797]",
          character_id: 4,
          position: "WRONG",
          option_svg_path: "characters/0004/0004_01_01_option_r.svg",
          option_unicode: "[6728]",
          option_info: " 木 relates to wood/tree",
          temporary_remove: 0,
        },
        {
          id: 607,
          character_unicode: "[961f]",
          character_id: 423,
          position: "WRONG",
          option_svg_path: "characters/0423/0423_03_15_option_l.svg",
          option_unicode: "[961d]",
          option_info: "",
          temporary_remove: 0,
        },
        {
          id: 3696,
          character_unicode: "[62fc]",
          character_id: 2067,
          position: "LEFT",
          option_svg_path: "characters/2067/2067_option_l.svg",
          option_unicode: "[624c]",
          option_info: " 扌 relates to hand",
          temporary_remove: 0,
        },
        {
          id: 253,
          character_unicode: "[8bfe]",
          character_id: 204,
          position: "WRONG",
          option_svg_path: "characters/0204/0204_02_09_option_l.svg",
          option_unicode: "[8ba0]",
          option_info: " 讠relates to language",
          temporary_remove: 0,
        },
      ],
    },
    {
      model_type: "TRAINING_DRAG_DROP",
      chinese_character: {
        id: 4,
        character_unicode: "[62fc]",
        chinese: "拼",
        structure_type: "LEFT_RIGHT",
        pinyin: "pīn",
        meaning: "to merge; to go all out",
        word: "拼字",
        word_meaning: "scrabble",
        word_pinyin: "pīn zì",
        character_svg_path: "characters/2067/2067_char.svg",
        audio_path: "audio/pin1.mp3",
        image_path: "mcq_images/2067.jpg",
      },
      fragment_masks: [
        {
          id: 3696,
          character_unicode: "[62fc]",
          character_id: 2067,
          position: "LEFT",
          mask_svg_path: "characters/2067/2067_mask_l.svg",
        },
        {
          id: 3697,
          character_unicode: "[62fc]",
          character_id: 2067,
          position: "RIGHT",
          mask_svg_path: "characters/2067/2067_mask_r.svg",
        },
      ],
      fragment_options: [
        {
          id: 3697,
          character_unicode: "[62fc]",
          character_id: 2067,
          position: "RIGHT",
          option_svg_path: "characters/2067/2067_option_r.svg",
          option_unicode: "[5e76]",
          option_info: "",
          temporary_remove: 0,
        },
        {
          id: 2,
          character_unicode: "[6797]",
          character_id: 4,
          position: "WRONG",
          option_svg_path: "characters/0004/0004_01_01_option_r.svg",
          option_unicode: "[6728]",
          option_info: " 木 relates to wood/tree",
          temporary_remove: 0,
        },
        {
          id: 607,
          character_unicode: "[961f]",
          character_id: 423,
          position: "WRONG",
          option_svg_path: "characters/0423/0423_03_15_option_l.svg",
          option_unicode: "[961d]",
          option_info: "",
          temporary_remove: 0,
        },
        {
          id: 3696,
          character_unicode: "[62fc]",
          character_id: 2067,
          position: "LEFT",
          option_svg_path: "characters/2067/2067_option_l.svg",
          option_unicode: "[624c]",
          option_info: " 扌 relates to hand",
          temporary_remove: 0,
        },
        {
          id: 253,
          character_unicode: "[8bfe]",
          character_id: 204,
          position: "WRONG",
          option_svg_path: "characters/0204/0204_02_09_option_l.svg",
          option_unicode: "[8ba0]",
          option_info: " 讠relates to language",
          temporary_remove: 0,
        },
      ],
    },
    {
      model_type: "TRAINING_DRAG_DROP",
      chinese_character: {
        id: 92,
        character_unicode: "[5019]",
        chinese: "候",
        structure_type: "LEFT_CENTER_RIGHT",
        pinyin: "hòu",
        meaning: "（时~）moment",
        word: "时候",
        word_meaning: "time,moment",
        word_pinyin: "shíhou",
        character_svg_path: "characters/0092/0092_01_06_char.svg",
        audio_path: "audio/hou4.mp3",
        image_path: null,
      },
      fragment_masks: [
        {
          id: 94,
          character_unicode: "[5019]",
          character_id: 92,
          position: "LEFT",
          mask_svg_path: "characters/0092/0092_01_06_mask_l.svg",
        },
        {
          id: 95,
          character_unicode: "[5019]",
          character_id: 92,
          position: "MID",
          mask_svg_path: "characters/0092/0092_01_06_mask_m.svg",
        },
        {
          id: 96,
          character_unicode: "[5019]",
          character_id: 92,
          position: "RIGHT",
          mask_svg_path: "characters/0092/0092_01_06_mask_r.svg",
        },
      ],
      fragment_options: [
        {
          id: 94,
          character_unicode: "[5019]",
          character_id: 92,
          position: "LEFT",
          option_svg_path: "characters/0092/0092_01_06_option_l.svg",
          option_unicode: "[4ebb]",
          option_info: "",
          temporary_remove: 0,
        },
        {
          id: 607,
          character_unicode: "[961f]",
          character_id: 423,
          position: "WRONG",
          option_svg_path: "characters/0423/0423_03_15_option_l.svg",
          option_unicode: "[961d]",
          option_info: "",
          temporary_remove: 0,
        },
        {
          id: 95,
          character_unicode: "[5019]",
          character_id: 92,
          position: "MID",
          option_svg_path: "characters/0092/0092_01_06_option_m.svg",
          option_unicode: "[4e28X]",
          option_info: "",
          temporary_remove: 0,
        },
        {
          id: 1,
          character_unicode: "[6797]",
          character_id: 4,
          position: "WRONG",
          option_svg_path: "characters/0004/0004_01_01_option_l.svg",
          option_unicode: "[6728X]",
          option_info: " 木 relates to wood/tree",
          temporary_remove: 0,
        },
        {
          id: 253,
          character_unicode: "[8bfe]",
          character_id: 204,
          position: "WRONG",
          option_svg_path: "characters/0204/0204_02_09_option_l.svg",
          option_unicode: "[8ba0]",
          option_info: " 讠relates to language",
          temporary_remove: 0,
        },
        {
          id: 96,
          character_unicode: "[5019]",
          character_id: 92,
          position: "RIGHT",
          option_svg_path: "characters/0092/0092_01_06_option_r.svg",
          option_unicode: "[4e5b4e0077e2X]",
          option_info: "",
          temporary_remove: 0,
        },
      ],
    },
    {
      model_type: "TRAINING_DRAG_DROP",
      chinese_character: {
        id: 16,
        character_unicode: "[54e5]",
        chinese: "哥",
        structure_type: "TOP_BOTTOM",
        pinyin: "gē",
        meaning: "elder brother",
        word: "哥哥",
        word_meaning: "elder brother",
        word_pinyin: "gēge",
        character_svg_path: "characters/0016/0016_01_02_char.svg",
        audio_path: "audio/ge1.mp3",
        image_path: null,
      },
      fragment_masks: [
        {
          id: 3,
          character_unicode: "[54e5]",
          character_id: 16,
          position: "BOTTOM",
          mask_svg_path: "characters/0016/0016_01_02_mask_b.svg",
        },
        {
          id: 4,
          character_unicode: "[54e5]",
          character_id: 16,
          position: "TOP",
          mask_svg_path: "characters/0016/0016_01_02_mask_t.svg",
        },
      ],
      fragment_options: [
        {
          id: 328,
          character_unicode: "[94b1]",
          character_id: 257,
          position: "WRONG",
          option_svg_path: "characters/0257/0257_02_10_option_l.svg",
          option_unicode: "[9485]",
          option_info: "钅relates to metal",
          temporary_remove: 0,
        },
        {
          id: 521,
          character_unicode: "[820d]",
          character_id: 375,
          position: "WRONG",
          option_svg_path: "characters/0375/0375_02_13_option_t.svg",
          option_unicode: "[4ebaX]",
          option_info: "",
          temporary_remove: 0,
        },
        {
          id: 253,
          character_unicode: "[8bfe]",
          character_id: 204,
          position: "WRONG",
          option_svg_path: "characters/0204/0204_02_09_option_l.svg",
          option_unicode: "[8ba0]",
          option_info: " 讠relates to language",
          temporary_remove: 0,
        },
        {
          id: 3,
          character_unicode: "[54e5]",
          character_id: 16,
          position: "BOTTOM",
          option_svg_path: "characters/0016/0016_01_02_option_b.svg",
          option_unicode: "[53ef]",
          option_info: "",
          temporary_remove: 0,
        },
        {
          id: 4,
          character_unicode: "[54e5]",
          character_id: 16,
          position: "TOP",
          option_svg_path: "characters/0016/0016_01_02_option_t.svg",
          option_unicode: "[53efX]",
          option_info: "",
          temporary_remove: 0,
        },
      ],
    },
    {
      model_type: "TRAINING_DRAG_DROP",
      chinese_character: {
        id: 64,
        character_unicode: "[8a00]",
        chinese: "言",
        structure_type: "TOP_MID_BOTTOM",
        pinyin: "yán",
        meaning: "speech",
        word: "语言",
        word_meaning: "language",
        word_pinyin: "yǔyán",
        character_svg_path: "characters/0064/0064_01_04_char.svg",
        audio_path: "audio/yan2.mp3",
        image_path: null,
      },
      fragment_masks: [
        {
          id: 61,
          character_unicode: "[8a00]",
          character_id: 64,
          position: "BOTTOM",
          mask_svg_path: "characters/0064/0064_01_04_mask_b.svg",
        },
        {
          id: 62,
          character_unicode: "[8a00]",
          character_id: 64,
          position: "MID",
          mask_svg_path: "characters/0064/0064_01_04_mask_m.svg",
        },
        {
          id: 63,
          character_unicode: "[8a00]",
          character_id: 64,
          position: "TOP",
          mask_svg_path: "characters/0064/0064_01_04_mask_t.svg",
        },
      ],
      fragment_options: [
        {
          id: 3,
          character_unicode: "[54e5]",
          character_id: 16,
          position: "WRONG",
          option_svg_path: "characters/0016/0016_01_02_option_b.svg",
          option_unicode: "[53ef]",
          option_info: "",
          temporary_remove: 0,
        },
        {
          id: 61,
          character_unicode: "[8a00]",
          character_id: 64,
          position: "BOTTOM",
          option_svg_path: "characters/0064/0064_01_04_option_b.svg",
          option_unicode: "[53e3]",
          option_info: "",
          temporary_remove: 0,
        },
        {
          id: 4,
          character_unicode: "[54e5]",
          character_id: 16,
          position: "WRONG",
          option_svg_path: "characters/0016/0016_01_02_option_t.svg",
          option_unicode: "[53efX]",
          option_info: "",
          temporary_remove: 0,
        },
        {
          id: 607,
          character_unicode: "[961f]",
          character_id: 423,
          position: "WRONG",
          option_svg_path: "characters/0423/0423_03_15_option_l.svg",
          option_unicode: "[961d]",
          option_info: "",
          temporary_remove: 0,
        },
        {
          id: 63,
          character_unicode: "[8a00]",
          character_id: 64,
          position: "TOP",
          option_svg_path: "characters/0064/0064_01_04_option_t.svg",
          option_unicode: "[4ea0]",
          option_info: "",
          temporary_remove: 0,
        },
        {
          id: 62,
          character_unicode: "[8a00]",
          character_id: 64,
          position: "MID",
          option_svg_path: "characters/0064/0064_01_04_option_m.svg",
          option_unicode: "[4e8c]",
          option_info: "",
          temporary_remove: 0,
        },
      ],
    },
    {
      model_type: "TRAINING_DRAG_DROP",
      chinese_character: {
        id: 29,
        character_unicode: "[56fd]",
        chinese: "国",
        structure_type: "ENCLOSE",
        pinyin: "guó",
        meaning: "country",
        word: "中国",
        word_meaning: "China",
        word_pinyin: "Zhōngguó",
        character_svg_path: "characters/0029/0029_01_03_char.svg",
        audio_path: "audio/guo2.mp3",
        image_path: null,
      },
      fragment_masks: [
        {
          id: 19,
          character_unicode: "[56fd]",
          character_id: 29,
          position: "INNER",
          mask_svg_path: "characters/0029/0029_01_03_mask_i.svg",
        },
        {
          id: 20,
          character_unicode: "[56fd]",
          character_id: 29,
          position: "OUTER",
          mask_svg_path: "characters/0029/0029_01_03_mask_o.svg",
        },
      ],
      fragment_options: [
        {
          id: 679,
          character_unicode: "[8111]",
          character_id: 465,
          position: "WRONG",
          option_svg_path: "characters/0465/0465_03_16_option_l.svg",
          option_unicode: "[6708]",
          option_info: "月 relates to body part",
          temporary_remove: 0,
        },
        {
          id: 853,
          character_unicode: "[5b83]",
          character_id: 562,
          position: "WRONG",
          option_svg_path: "characters/0562/0562_03_19_option_b.svg",
          option_unicode: "[5315]",
          option_info: "",
          temporary_remove: 0,
        },
        {
          id: 5,
          character_unicode: "[53ef]",
          character_id: 17,
          position: "WRONG",
          option_svg_path: "characters/0017/0017_01_02_option_i.svg",
          option_unicode: "[53e3]",
          option_info: "",
          temporary_remove: 0,
        },
        {
          id: 20,
          character_unicode: "[56fd]",
          character_id: 29,
          position: "OUTER",
          option_svg_path: "characters/0029/0029_01_03_option_o.svg",
          option_unicode: "[53e3]",
          option_info:
            "you may imagine this big box as the border of a country",
          temporary_remove: 0,
        },
        {
          id: 19,
          character_unicode: "[56fd]",
          character_id: 29,
          position: "INNER",
          option_svg_path: "characters/0029/0029_01_03_option_i.svg",
          option_unicode: "[7389]",
          option_info: "",
          temporary_remove: 0,
        },
      ],
    },
    {
      model_type: "TRAINING_DRAG_DROP",
      chinese_character: {
        id: 65,
        character_unicode: "[53cb]",
        chinese: "友",
        structure_type: "HALF_BOTTOM_RIGHT",
        pinyin: "yǒu",
        meaning: "friend",
        word: "朋友",
        word_meaning: "friend",
        word_pinyin: "péngyou",
        character_svg_path: "characters/0065/0065_01_04_char.svg",
        audio_path: "audio/you3.mp3",
        image_path: null,
      },
      fragment_masks: [
        {
          id: 64,
          character_unicode: "[53cb]",
          character_id: 65,
          position: "INNER",
          mask_svg_path: "characters/0065/0065_01_04_mask_i.svg",
        },
        {
          id: 65,
          character_unicode: "[53cb]",
          character_id: 65,
          position: "OUTER",
          mask_svg_path: "characters/0065/0065_01_04_mask_o.svg",
        },
      ],
      fragment_options: [
        {
          id: 3,
          character_unicode: "[54e5]",
          character_id: 16,
          position: "WRONG",
          option_svg_path: "characters/0016/0016_01_02_option_b.svg",
          option_unicode: "[53ef]",
          option_info: "",
          temporary_remove: 0,
        },
        {
          id: 1089,
          character_unicode: "[574f]",
          character_id: 694,
          position: "WRONG",
          option_svg_path: "characters/0694/0694_04_25_option_l.svg",
          option_unicode: "[571fX]",
          option_info: "",
          temporary_remove: 0,
        },
        {
          id: 64,
          character_unicode: "[53cb]",
          character_id: 65,
          position: "INNER",
          option_svg_path: "characters/0065/0065_01_04_option_i.svg",
          option_unicode: "[53c8]",
          option_info: "",
          temporary_remove: 0,
        },
        {
          id: 253,
          character_unicode: "[8bfe]",
          character_id: 204,
          position: "WRONG",
          option_svg_path: "characters/0204/0204_02_09_option_l.svg",
          option_unicode: "[8ba0]",
          option_info: " 讠relates to language",
          temporary_remove: 0,
        },
        {
          id: 65,
          character_unicode: "[53cb]",
          character_id: 65,
          position: "OUTER",
          option_svg_path: "characters/0065/0065_01_04_option_o.svg",
          option_unicode: "[4e004e3fXX]",
          option_info: "",
          temporary_remove: 0,
        },
      ],
    },
    {
      model_type: "TRAINING_DRAG_DROP",
      chinese_character: {
        id: 73,
        character_unicode: "[8fdb]",
        chinese: "进",
        structure_type: "HALF_TOP_RIGHT",
        pinyin: "jìn",
        meaning: "to enter",
        word: "进来",
        word_meaning: "to come in",
        word_pinyin: "jìnlai",
        character_svg_path: "characters/0073/0073_01_05_char.svg",
        audio_path: "audio/jin4.mp3",
        image_path: null,
      },
      fragment_masks: [
        {
          id: 74,
          character_unicode: "[8fdb]",
          character_id: 73,
          position: "INNER",
          mask_svg_path: "characters/0073/0073_01_05_mask_i.svg",
        },
        {
          id: 75,
          character_unicode: "[8fdb]",
          character_id: 73,
          position: "OUTER",
          mask_svg_path: "characters/0073/0073_01_05_mask_o.svg",
        },
      ],
      fragment_options: [
        {
          id: 1589,
          character_unicode: "[8349]",
          character_id: 955,
          position: "WRONG",
          option_svg_path: "characters/0955/0955_06_36_option_b.svg",
          option_unicode: "[65e9]",
          option_info: "",
          temporary_remove: 0,
        },
        {
          id: 65,
          character_unicode: "[53cb]",
          character_id: 65,
          position: "WRONG",
          option_svg_path: "characters/0065/0065_01_04_option_o.svg",
          option_unicode: "[4e004e3fXX]",
          option_info: "",
          temporary_remove: 0,
        },
        {
          id: 75,
          character_unicode: "[8fdb]",
          character_id: 73,
          position: "OUTER",
          option_svg_path: "characters/0073/0073_01_05_option_o.svg",
          option_unicode: "[8fb6]",
          option_info: "辶 relates to move",
          temporary_remove: 0,
        },
        {
          id: 74,
          character_unicode: "[8fdb]",
          character_id: 73,
          position: "INNER",
          option_svg_path: "characters/0073/0073_01_05_option_i.svg",
          option_unicode: "[4e95]",
          option_info: "",
          temporary_remove: 0,
        },
        {
          id: 607,
          character_unicode: "[961f]",
          character_id: 423,
          position: "WRONG",
          option_svg_path: "characters/0423/0423_03_15_option_l.svg",
          option_unicode: "[961d]",
          option_info: "",
          temporary_remove: 0,
        },
      ],
    },
    {
      model_type: "TRAINING_DRAG_DROP",
      chinese_character: {
        id: 45,
        character_unicode: "[533b]",
        chinese: "医",
        structure_type: "HALF_MID_RIGHT",
        pinyin: "yī",
        meaning: "medical related",
        word: "医生",
        word_meaning: "doctor, physician",
        word_pinyin: "yīshēng",
        character_svg_path: "characters/0045/0045_01_03_char.svg",
        audio_path: "audio/yi1.mp3",
        image_path: null,
      },
      fragment_masks: [
        {
          id: 41,
          character_unicode: "[533b]",
          character_id: 45,
          position: "INNER",
          mask_svg_path: "characters/0045/0045_01_03_mask_i.svg",
        },
        {
          id: 42,
          character_unicode: "[533b]",
          character_id: 45,
          position: "OUTER",
          mask_svg_path: "characters/0045/0045_01_03_mask_o.svg",
        },
      ],
      fragment_options: [
        {
          id: 41,
          character_unicode: "[533b]",
          character_id: 45,
          position: "INNER",
          option_svg_path: "characters/0045/0045_01_03_option_i.svg",
          option_unicode: "[77e2]",
          option_info: "",
          temporary_remove: 0,
        },
        {
          id: 665,
          character_unicode: "[9986]",
          character_id: 458,
          position: "WRONG",
          option_svg_path: "characters/0458/0458_03_16_option_l.svg",
          option_unicode: "[9963]",
          option_info: "",
          temporary_remove: 0,
        },
        {
          id: 75,
          character_unicode: "[8fdb]",
          character_id: 73,
          position: "WRONG",
          option_svg_path: "characters/0073/0073_01_05_option_o.svg",
          option_unicode: "[8fb6]",
          option_info: "辶 relates to move",
          temporary_remove: 0,
        },
        {
          id: 74,
          character_unicode: "[8fdb]",
          character_id: 73,
          position: "WRONG",
          option_svg_path: "characters/0073/0073_01_05_option_i.svg",
          option_unicode: "[4e95]",
          option_info: "",
          temporary_remove: 0,
        },
        {
          id: 42,
          character_unicode: "[533b]",
          character_id: 45,
          position: "OUTER",
          option_svg_path: "characters/0045/0045_01_03_option_o.svg",
          option_unicode: "[531a]",
          option_info: "",
          temporary_remove: 0,
        },
      ],
    },
    {
      model_type: "TRAINING_DRAG_DROP",
      chinese_character: {
        id: 83,
        character_unicode: "[7528]",
        chinese: "用",
        structure_type: "HALF_BOTTOM_CENTER",
        pinyin: "yòng",
        meaning: "to use",
        word: "用手",
        word_meaning: "use hand",
        word_pinyin: "yòng shǒu",
        character_svg_path: "characters/0083/0083_01_05_char.svg",
        audio_path: "audio/yong4.mp3",
        image_path: null,
      },
      fragment_masks: [
        {
          id: 86,
          character_unicode: "[7528]",
          character_id: 83,
          position: "INNER",
          mask_svg_path: "characters/0083/0083_01_05_mask_i.svg",
        },
        {
          id: 87,
          character_unicode: "[7528]",
          character_id: 83,
          position: "OUTER",
          mask_svg_path: "characters/0083/0083_01_05_mask_o.svg",
        },
      ],
      fragment_options: [
        {
          id: 75,
          character_unicode: "[8fdb]",
          character_id: 73,
          position: "WRONG",
          option_svg_path: "characters/0073/0073_01_05_option_o.svg",
          option_unicode: "[8fb6]",
          option_info: "辶 relates to move",
          temporary_remove: 0,
        },
        {
          id: 74,
          character_unicode: "[8fdb]",
          character_id: 73,
          position: "WRONG",
          option_svg_path: "characters/0073/0073_01_05_option_i.svg",
          option_unicode: "[4e95]",
          option_info: "",
          temporary_remove: 0,
        },
        {
          id: 86,
          character_unicode: "[7528]",
          character_id: 83,
          position: "INNER",
          option_svg_path: "characters/0083/0083_01_05_option_i.svg",
          option_unicode: "[4e30XX]",
          option_info: "",
          temporary_remove: 0,
        },
        {
          id: 87,
          character_unicode: "[7528]",
          character_id: 83,
          position: "OUTER",
          option_svg_path: "characters/0083/0083_01_05_option_o.svg",
          option_unicode: "[5182XX]",
          option_info: "",
          temporary_remove: 0,
        },
        {
          id: 1040,
          character_unicode: "[9634]",
          character_id: 665,
          position: "WRONG",
          option_svg_path: "characters/0665/0665_04_23_option_r.svg",
          option_unicode: "[6708]",
          option_info: "",
          temporary_remove: 0,
        },
      ],
    },
    {
      model_type: "TRAINING_DRAG_DROP",
      chinese_character: {
        id: 555,
        character_unicode: "[753b]",
        chinese: "画",
        structure_type: "HALF_TOP_CENTER",
        pinyin: "huà",
        meaning: "to paint",
        word: "画家",
        word_meaning: "painter, artist",
        word_pinyin: "huàjiā",
        character_svg_path: "characters/0555/0555_03_19_char.svg",
        audio_path: "audio/hua4.mp3",
        image_path: "mcq_images/0555.jpg",
      },
      fragment_masks: [
        {
          id: 839,
          character_unicode: "[753b]",
          character_id: 555,
          position: "INNER",
          mask_svg_path: "characters/0555/0555_03_19_mask_i.svg",
        },
        {
          id: 840,
          character_unicode: "[753b]",
          character_id: 555,
          position: "OUTER",
          mask_svg_path: "characters/0555/0555_03_19_mask_o.svg",
        },
      ],
      fragment_options: [
        {
          id: 87,
          character_unicode: "[7528]",
          character_id: 83,
          position: "WRONG",
          option_svg_path: "characters/0083/0083_01_05_option_o.svg",
          option_unicode: "[5182XX]",
          option_info: "",
          temporary_remove: 0,
        },
        {
          id: 840,
          character_unicode: "[753b]",
          character_id: 555,
          position: "OUTER",
          option_svg_path: "characters/0555/0555_03_19_option_o.svg",
          option_unicode: "[51f5]",
          option_info: "",
          temporary_remove: 0,
        },
        {
          id: 86,
          character_unicode: "[7528]",
          character_id: 83,
          position: "INNER",
          option_svg_path: "characters/0083/0083_01_05_option_i.svg",
          option_unicode: "[4e30XX]",
          option_info: "",
          temporary_remove: 0,
        },
        {
          id: 651,
          character_unicode: "[522b]",
          character_id: 451,
          position: "WRONG",
          option_svg_path: "characters/0451/0451_03_16_option_r.svg",
          option_unicode: "[5202]",
          option_info: "",
          temporary_remove: 0,
        },
        {
          id: 839,
          character_unicode: "[753b]",
          character_id: 555,
          position: "INNER",
          option_svg_path: "characters/0555/0555_03_19_option_i.svg",
          option_unicode: "[4e007531X]",
          option_info: "",
          temporary_remove: 0,
        },
      ],
    },
  ],
};

export const scaffoldingPresetData = {
  data: [
    {
      model_type: "TRAINING_DRAG_DROP",
      chinese_character: {
        id: 54,
        character_unicode: "[670b]",
        chinese: "朋",
        structure_type: "LEFT_RIGHT",
        pinyin: "péng",
        meaning: "friend",
        word: "朋友",
        word_meaning: "friend",
        word_pinyin: "péngyou",
        character_svg_path: "characters/0054/0054_01_04_char.svg",
        audio_path: "audio/peng2.mp3",
        image_path: null,
      },
      fragment_masks: [
        {
          id: 53,
          character_unicode: "[670b]",
          character_id: 54,
          position: "LEFT",
          mask_svg_path: "characters/0054/0054_01_04_mask_l.svg",
        },
        {
          id: 54,
          character_unicode: "[670b]",
          character_id: 54,
          position: "RIGHT",
          mask_svg_path: "characters/0054/0054_01_04_mask_r.svg",
        },
      ],
      fragment_options: [
        {
          id: 1316,
          character_unicode: "[5065]",
          character_id: 818,
          position: "WRONG",
          option_svg_path: "characters/0818/0818_05_30_option_l.svg",
          option_unicode: "[4ebb]",
          option_info: "亻 relates to person",
          temporary_remove: 0,
          specific_position: "LEFT_RIGHT_1",
        },
        {
          id: 54,
          character_unicode: "[670b]",
          character_id: 54,
          position: "RIGHT",
          option_svg_path: "characters/0054/0054_01_04_option_r.svg",
          option_unicode: "[6708]",
          option_info: "relates to moon",
          temporary_remove: 0,
          specific_position: "LEFT_RIGHT_2",
        },
        {
          id: 4931,
          character_unicode: "[6cfb]",
          character_id: 2704,
          position: "WRONG",
          option_svg_path: "characters/2704/2704_option_l.svg",
          option_unicode: "[6c35]",
          option_info: "氵relates to water/liquid",
          temporary_remove: 0,
          specific_position: "LEFT_RIGHT_1",
        },
        {
          id: 1686,
          character_unicode: "[621a]",
          character_id: 1005,
          position: "WRONG",
          option_svg_path: "characters/1005/1005_06_38_option_o.svg",
          option_unicode: "[620a]",
          option_info: "",
          temporary_remove: 0,
          specific_position: "HALF_BOTTOM_CENTER_OUTER",
        },
        {
          id: 53,
          character_unicode: "[670b]",
          character_id: 54,
          position: "LEFT",
          option_svg_path: "characters/0054/0054_01_04_option_l.svg",
          option_unicode: "[6708]",
          option_info: "relates to moon",
          temporary_remove: 0,
          specific_position: "LEFT_RIGHT_1",
        },
      ],
    },
    {
      model_type: "TRAINING_DRAG_DROP",
      chinese_character: {
        id: 364,
        character_unicode: "[6237]",
        chinese: "户",
        structure_type: "TOP_BOTTOM",
        pinyin: "hù",
        meaning: "household",
        word: "一户人家",
        word_meaning: "one family",
        word_pinyin: "yíhù rénjiā",
        character_svg_path: "characters/0364/0364_02_13_char.svg",
        audio_path: "audio/hu4.mp3",
        image_path: null,
      },
      fragment_masks: [
        {
          id: 499,
          character_unicode: "[6237]",
          character_id: 364,
          position: "BOTTOM",
          mask_svg_path: "characters/0364/0364_02_13_mask_b.svg",
        },
        {
          id: 500,
          character_unicode: "[6237]",
          character_id: 364,
          position: "TOP",
          mask_svg_path: "characters/0364/0364_02_13_mask_t.svg",
        },
      ],
      fragment_options: [
        {
          id: 796,
          character_unicode: "[6c7d]",
          character_id: 530,
          position: "WRONG",
          option_svg_path: "characters/0530/0530_03_18_option_l.svg",
          option_unicode: "[6c35]",
          option_info: "氵relates to water/liquid",
          temporary_remove: 0,
          specific_position: "LEFT_RIGHT_1",
        },
        {
          id: 499,
          character_unicode: "[6237]",
          character_id: 364,
          position: "BOTTOM",
          option_svg_path: "characters/0364/0364_02_13_option_b.svg",
          option_unicode: "[5c38]",
          option_info: "relates to corpse",
          temporary_remove: 0,
          specific_position: "TOP_BOTTOM_2",
        },
        {
          id: 707,
          character_unicode: "[886c]",
          character_id: 482,
          position: "WRONG",
          option_svg_path: "characters/0482/0482_03_17_option_l.svg",
          option_unicode: "[8864]",
          option_info: "衤relates to clothing",
          temporary_remove: 0,
          specific_position: "LEFT_RIGHT_1",
        },
        {
          id: 4727,
          character_unicode: "[53a6]",
          character_id: 2595,
          position: "WRONG",
          option_svg_path: "characters/2595/2595_option_o.svg",
          option_unicode: "[5382]",
          option_info: "relates to cliff",
          temporary_remove: 0,
          specific_position: "HALF_BOTTOM_RIGHT_OUTER",
        },
        {
          id: 500,
          character_unicode: "[6237]",
          character_id: 364,
          position: "TOP",
          option_svg_path: "characters/0364/0364_02_13_option_t.svg",
          option_unicode: "[4e36]",
          option_info: "relates to dot",
          temporary_remove: 0,
          specific_position: "TOP_BOTTOM_1",
        },
      ],
    },
    {
      model_type: "TRAINING_DRAG_DROP",
      chinese_character: {
        id: 158,
        character_unicode: "[8fd8]",
        chinese: "还",
        structure_type: "HALF_TOP_RIGHT",
        pinyin: "hái",
        meaning: "in addition,still",
        word: "还有谁",
        word_meaning: "who else",
        word_pinyin: "hái yǒu shéi",
        character_svg_path: "characters/0158/0158_01_08_char.svg",
        audio_path: "audio/hai2.mp3",
        image_path: null,
      },
      fragment_masks: [
        {
          id: 185,
          character_unicode: "[8fd8]",
          character_id: 158,
          position: "INNER",
          mask_svg_path: "characters/0158/0158_01_08_mask_i.svg",
        },
        {
          id: 186,
          character_unicode: "[8fd8]",
          character_id: 158,
          position: "OUTER",
          mask_svg_path: "characters/0158/0158_01_08_mask_o.svg",
        },
      ],
      fragment_options: [
        {
          id: 186,
          character_unicode: "[8fd8]",
          character_id: 158,
          position: "OUTER",
          option_svg_path: "characters/0158/0158_01_08_option_o.svg",
          option_unicode: "[8fb6]",
          option_info: "辶 relates to move",
          temporary_remove: 0,
          specific_position: "HALF_TOP_RIGHT_OUTER",
        },
        {
          id: 1833,
          character_unicode: "[9e45]",
          character_id: 1138,
          position: "WRONG",
          option_svg_path: "characters/1138/1138_option_r.svg",
          option_unicode: "[9e1f]",
          option_info: "鸟 means bird",
          temporary_remove: 0,
          specific_position: "LEFT_RIGHT_2",
        },
        {
          id: 4899,
          character_unicode: "[84ec]",
          character_id: 2686,
          position: "WRONG",
          option_svg_path: "characters/2686/2686_option_b.svg",
          option_unicode: "[9022]",
          option_info: "",
          temporary_remove: 0,
          specific_position: "TOP_BOTTOM_2",
        },
        {
          id: 185,
          character_unicode: "[8fd8]",
          character_id: 158,
          position: "INNER",
          option_svg_path: "characters/0158/0158_01_08_option_i.svg",
          option_unicode: "[4e0d]",
          option_info: "",
          temporary_remove: 0,
          specific_position: "HALF_TOP_RIGHT_INNER",
        },
        {
          id: 1240,
          character_unicode: "[7cd6]",
          character_id: 778,
          position: "WRONG",
          option_svg_path: "characters/0778/0778_05_28_option_l.svg",
          option_unicode: "[7c73]",
          option_info: "米 relates to rice",
          temporary_remove: 0,
          specific_position: "LEFT_RIGHT_1",
        },
      ],
    },
    {
      model_type: "TRAINING_DRAG_DROP",
      chinese_character: {
        id: 148,
        character_unicode: "[5b57]",
        chinese: "字",
        structure_type: "TOP_BOTTOM",
        pinyin: "zì",
        meaning: "Chinese character",
        word: "汉字",
        word_meaning: "Chinese character",
        word_pinyin: "hànzì",
        character_svg_path: "characters/0148/0148_01_07_char.svg",
        audio_path: "audio/zi4.mp3",
        image_path: null,
      },
      fragment_masks: [
        {
          id: 168,
          character_unicode: "[5b57]",
          character_id: 148,
          position: "BOTTOM",
          mask_svg_path: "characters/0148/0148_01_07_mask_b.svg",
        },
        {
          id: 169,
          character_unicode: "[5b57]",
          character_id: 148,
          position: "TOP",
          mask_svg_path: "characters/0148/0148_01_07_mask_t.svg",
        },
      ],
      fragment_options: [
        {
          id: 491,
          character_unicode: "[544a]",
          character_id: 360,
          position: "WRONG",
          option_svg_path: "characters/0360/0360_02_13_option_b.svg",
          option_unicode: "[53e3]",
          option_info: "口 relates to mouth",
          temporary_remove: 0,
          specific_position: "TOP_BOTTOM_2",
        },
        {
          id: 169,
          character_unicode: "[5b57]",
          character_id: 148,
          position: "TOP",
          option_svg_path: "characters/0148/0148_01_07_option_t.svg",
          option_unicode: "[5b80]",
          option_info: "relates to roof",
          temporary_remove: 0,
          specific_position: "TOP_BOTTOM_1",
        },
        {
          id: 3346,
          character_unicode: "[58c1]",
          character_id: 1891,
          position: "WRONG",
          option_svg_path: "characters/1891/1891_option_b.svg",
          option_unicode: "[571f]",
          option_info: "土 relates to ground",
          temporary_remove: 0,
          specific_position: "TOP_BOTTOM_2",
        },
        {
          id: 168,
          character_unicode: "[5b57]",
          character_id: 148,
          position: "BOTTOM",
          option_svg_path: "characters/0148/0148_01_07_option_b.svg",
          option_unicode: "[5b50]",
          option_info:
            "This part gives a hint of the pinyin spelling or pronunciation of the character",
          temporary_remove: 0,
          specific_position: "TOP_BOTTOM_2",
        },
        {
          id: 1326,
          character_unicode: "[68cb]",
          character_id: 822,
          position: "WRONG",
          option_svg_path: "characters/0822/0822_05_30_option_r.svg",
          option_unicode: "[5176]",
          option_info:
            "This part gives a hint of the pinyin spelling or pronunciation of the character",
          temporary_remove: 0,
          specific_position: "LEFT_RIGHT_2",
        },
      ],
    },
    {
      model_type: "TRAINING_DRAG_DROP",
      chinese_character: {
        id: 231,
        character_unicode: "[600e]",
        chinese: "怎",
        structure_type: "TOP_BOTTOM",
        pinyin: "zěn",
        meaning: "（~么） how",
        word: "怎么样",
        word_meaning: "how is…",
        word_pinyin: "zěnmeyàng",
        character_svg_path: "characters/0231/0231_02_09_char.svg",
        audio_path: "audio/zen3.mp3",
        image_path: null,
      },
      fragment_masks: [
        {
          id: 290,
          character_unicode: "[600e]",
          character_id: 231,
          position: "BOTTOM",
          mask_svg_path: "characters/0231/0231_02_09_mask_b.svg",
        },
        {
          id: 291,
          character_unicode: "[600e]",
          character_id: 231,
          position: "TOP",
          mask_svg_path: "characters/0231/0231_02_09_mask_t.svg",
        },
      ],
      fragment_options: [
        {
          id: 4483,
          character_unicode: "[6b49]",
          character_id: 2468,
          position: "WRONG",
          option_svg_path: "characters/2468/2468_option_r.svg",
          option_unicode: "[6b20]",
          option_info: "relates to lack",
          temporary_remove: 0,
          specific_position: "LEFT_RIGHT_2",
        },
        {
          id: 4633,
          character_unicode: "[6444]",
          character_id: 2550,
          position: "WRONG",
          option_svg_path: "characters/2550/2550_option_l.svg",
          option_unicode: "[624c]",
          option_info: "扌 relates to hand",
          temporary_remove: 0,
          specific_position: "LEFT_RIGHT_1",
        },
        {
          id: 291,
          character_unicode: "[600e]",
          character_id: 231,
          position: "TOP",
          option_svg_path: "characters/0231/0231_02_09_option_t.svg",
          option_unicode: "[4e4d]",
          option_info: "",
          temporary_remove: 0,
          specific_position: "TOP_BOTTOM_1",
        },
        {
          id: 290,
          character_unicode: "[600e]",
          character_id: 231,
          position: "BOTTOM",
          option_svg_path: "characters/0231/0231_02_09_option_b.svg",
          option_unicode: "[5fc3]",
          option_info: "relates to heart",
          temporary_remove: 0,
          specific_position: "TOP_BOTTOM_2",
        },
        {
          id: 2525,
          character_unicode: "[8c24]",
          character_id: 1477,
          position: "WRONG",
          option_svg_path: "characters/1477/1477_option_l.svg",
          option_unicode: "[8ba0]",
          option_info: " 讠relates to language",
          temporary_remove: 0,
          specific_position: "LEFT_RIGHT_1",
        },
      ],
    },
    {
      model_type: "TRAINING_DRAG_DROP",
      chinese_character: {
        id: 426,
        character_unicode: "[5942]",
        chinese: "奂",
        structure_type: "TOP_BOTTOM",
        pinyin: "huàn",
        meaning: "abundant",
        word: "美轮美奂",
        word_meaning: "extremely big and beautiful",
        word_pinyin: "měilúnměihuàn",
        character_svg_path: "characters/0426/0426_03_15_char.svg",
        audio_path: "audio/huan4.mp3",
        image_path: null,
      },
      fragment_masks: [
        {
          id: 613,
          character_unicode: "[5942]",
          character_id: 426,
          position: "BOTTOM",
          mask_svg_path: "characters/0426/0426_03_15_mask_b.svg",
        },
        {
          id: 614,
          character_unicode: "[5942]",
          character_id: 426,
          position: "TOP",
          mask_svg_path: "characters/0426/0426_03_15_mask_t.svg",
        },
      ],
      fragment_options: [
        {
          id: 928,
          character_unicode: "[8d5b]",
          character_id: 604,
          position: "WRONG",
          option_svg_path: "characters/0604/0604_04_21_option_b.svg",
          option_unicode: "[8d1d]",
          option_info: "贝 relates to money",
          temporary_remove: 0,
          specific_position: "TOP_MID_BOTTOM_3",
        },
        {
          id: 613,
          character_unicode: "[5942]",
          character_id: 426,
          position: "BOTTOM",
          option_svg_path: "characters/0426/0426_03_15_option_b.svg",
          option_unicode: "[51825927X]",
          option_info: "relates to upside-down box",
          temporary_remove: 0,
          specific_position: "TOP_BOTTOM_2",
        },
        {
          id: 4415,
          character_unicode: "[9063]",
          character_id: 2434,
          position: "WRONG",
          option_svg_path: "characters/2434/2434_option_o.svg",
          option_unicode: "[8fb6]",
          option_info: "辶 relates to move",
          temporary_remove: 0,
          specific_position: "HALF_TOP_RIGHT_OUTER",
        },
        {
          id: 614,
          character_unicode: "[5942]",
          character_id: 426,
          position: "TOP",
          option_svg_path: "characters/0426/0426_03_15_option_t.svg",
          option_unicode: "[e81c]",
          option_info: "",
          temporary_remove: 0,
          specific_position: "TOP_BOTTOM_1",
        },
        {
          id: 4398,
          character_unicode: "[6b67]",
          character_id: 2426,
          position: "WRONG",
          option_svg_path: "characters/2426/2426_option_l.svg",
          option_unicode: "[6b62]",
          option_info: "relates to stop",
          temporary_remove: 0,
          specific_position: "LEFT_RIGHT_1",
        },
      ],
    },
    {
      model_type: "TRAINING_DRAG_DROP",
      chinese_character: {
        id: 625,
        character_unicode: "[7c7b]",
        chinese: "类",
        structure_type: "TOP_BOTTOM",
        pinyin: "lèi",
        meaning: "kind, category",
        word: "种类",
        word_meaning: "type, variety",
        word_pinyin: "zhǒnglèi",
        character_svg_path: "characters/0625/0625_04_21_char.svg",
        audio_path: "audio/lei4.mp3",
        image_path: null,
      },
      fragment_masks: [
        {
          id: 970,
          character_unicode: "[7c7b]",
          character_id: 625,
          position: "BOTTOM",
          mask_svg_path: "characters/0625/0625_04_21_mask_b.svg",
        },
        {
          id: 971,
          character_unicode: "[7c7b]",
          character_id: 625,
          position: "TOP",
          mask_svg_path: "characters/0625/0625_04_21_mask_t.svg",
        },
      ],
      fragment_options: [
        {
          id: 971,
          character_unicode: "[7c7b]",
          character_id: 625,
          position: "TOP",
          option_svg_path: "characters/0625/0625_04_21_option_t.svg",
          option_unicode: "[7c73]",
          option_info: "relates to rice",
          temporary_remove: 0,
          specific_position: "TOP_BOTTOM_1",
        },
        {
          id: 970,
          character_unicode: "[7c7b]",
          character_id: 625,
          position: "BOTTOM",
          option_svg_path: "characters/0625/0625_04_21_option_b.svg",
          option_unicode: "[5927]",
          option_info: "relates to big",
          temporary_remove: 0,
          specific_position: "TOP_BOTTOM_2",
        },
        {
          id: 356,
          character_unicode: "[62dc]",
          character_id: 274,
          position: "WRONG",
          option_svg_path: "characters/0274/0274_02_11_option_r.svg",
          option_unicode: "[624bX]",
          option_info: "relates to hand",
          temporary_remove: 0,
          specific_position: "LEFT_RIGHT_2",
        },
        {
          id: 3863,
          character_unicode: "[5ac2]",
          character_id: 2154,
          position: "WRONG",
          option_svg_path: "characters/2154/2154_option_l.svg",
          option_unicode: "[5973]",
          option_info: "女 relates to female person",
          temporary_remove: 0,
          specific_position: "LEFT_RIGHT_1",
        },
        {
          id: 2977,
          character_unicode: "[60ee]",
          character_id: 1703,
          position: "WRONG",
          option_svg_path: "characters/1703/1703_option_l.svg",
          option_unicode: "[5fc4]",
          option_info: "忄relates to feeling",
          temporary_remove: 0,
          specific_position: "LEFT_RIGHT_1",
        },
      ],
    },
    {
      model_type: "TRAINING_DRAG_DROP",
      chinese_character: {
        id: 429,
        character_unicode: "[5c31]",
        chinese: "就",
        structure_type: "LEFT_RIGHT",
        pinyin: "jiù",
        meaning: "exactly, precisely",
        word: "就是这个",
        word_meaning: "exactly this one",
        word_pinyin: "jiù shì zhège",
        character_svg_path: "characters/0429/0429_03_15_char.svg",
        audio_path: "audio/jiu4.mp3",
        image_path: null,
      },
      fragment_masks: [
        {
          id: 617,
          character_unicode: "[5c31]",
          character_id: 429,
          position: "LEFT",
          mask_svg_path: "characters/0429/0429_03_15_mask_l.svg",
        },
        {
          id: 618,
          character_unicode: "[5c31]",
          character_id: 429,
          position: "RIGHT",
          mask_svg_path: "characters/0429/0429_03_15_mask_r.svg",
        },
      ],
      fragment_options: [
        {
          id: 3663,
          character_unicode: "[9488]",
          character_id: 2050,
          position: "WRONG",
          option_svg_path: "characters/2050/2050_option_l.svg",
          option_unicode: "[9485]",
          option_info: "钅relates to metal",
          temporary_remove: 0,
          specific_position: "LEFT_RIGHT_1",
        },
        {
          id: 4984,
          character_unicode: "[6241]",
          character_id: 2730,
          position: "WRONG",
          option_svg_path: "characters/2730/2730_option_o.svg",
          option_unicode: "[6237]",
          option_info: "",
          temporary_remove: 0,
          specific_position: "HALF_BOTTOM_RIGHT_OUTER",
        },
        {
          id: 1889,
          character_unicode: "[7599]",
          character_id: 1166,
          position: "WRONG",
          option_svg_path: "characters/1166/1166_option_o.svg",
          option_unicode: "[7592]",
          option_info: "疒 relates to disease",
          temporary_remove: 0,
          specific_position: "HALF_BOTTOM_RIGHT_OUTER",
        },
        {
          id: 617,
          character_unicode: "[5c31]",
          character_id: 429,
          position: "LEFT",
          option_svg_path: "characters/0429/0429_03_15_option_l.svg",
          option_unicode: "[4eac]",
          option_info: "",
          temporary_remove: 0,
          specific_position: "LEFT_RIGHT_1",
        },
        {
          id: 618,
          character_unicode: "[5c31]",
          character_id: 429,
          position: "RIGHT",
          option_svg_path: "characters/0429/0429_03_15_option_r.svg",
          option_unicode: "[5c24]",
          option_info: "",
          temporary_remove: 0,
          specific_position: "LEFT_RIGHT_2",
        },
      ],
    },
    {
      model_type: "TRAINING_DRAG_DROP",
      chinese_character: {
        id: 615,
        character_unicode: "[8fdc]",
        chinese: "远",
        structure_type: "HALF_TOP_RIGHT",
        pinyin: "yuǎn",
        meaning: "far",
        word: "很远",
        word_meaning: "very far",
        word_pinyin: "hěn yuǎn",
        character_svg_path: "characters/0615/0615_04_21_char.svg",
        audio_path: "audio/yuan3.mp3",
        image_path: null,
      },
      fragment_masks: [
        {
          id: 950,
          character_unicode: "[8fdc]",
          character_id: 615,
          position: "INNER",
          mask_svg_path: "characters/0615/0615_04_21_mask_i.svg",
        },
        {
          id: 951,
          character_unicode: "[8fdc]",
          character_id: 615,
          position: "OUTER",
          mask_svg_path: "characters/0615/0615_04_21_mask_o.svg",
        },
      ],
      fragment_options: [
        {
          id: 1845,
          character_unicode: "[8679]",
          character_id: 1144,
          position: "WRONG",
          option_svg_path: "characters/1144/1144_option_r.svg",
          option_unicode: "[5de5]",
          option_info: "",
          temporary_remove: 0,
          specific_position: "LEFT_RIGHT_2",
        },
        {
          id: 2077,
          character_unicode: "[8d9f]",
          character_id: 1257,
          position: "WRONG",
          option_svg_path: "characters/1257/1257_option_o.svg",
          option_unicode: "[8d70]",
          option_info: "走 relates to walk",
          temporary_remove: 0,
          specific_position: "HALF_TOP_RIGHT_OUTER",
        },
        {
          id: 950,
          character_unicode: "[8fdc]",
          character_id: 615,
          position: "INNER",
          option_svg_path: "characters/0615/0615_04_21_option_i.svg",
          option_unicode: "[5143]",
          option_info:
            "This part gives a hint of the pinyin spelling or pronunciation of the character",
          temporary_remove: 0,
          specific_position: "HALF_TOP_RIGHT_INNER",
        },
        {
          id: 1132,
          character_unicode: "[6b79]",
          character_id: 717,
          position: "WRONG",
          option_svg_path: "characters/0717/0717_04_26_option_b.svg",
          option_unicode: "[5915]",
          option_info: "relates to evening",
          temporary_remove: 0,
          specific_position: "TOP_BOTTOM_2",
        },
        {
          id: 951,
          character_unicode: "[8fdc]",
          character_id: 615,
          position: "OUTER",
          option_svg_path: "characters/0615/0615_04_21_option_o.svg",
          option_unicode: "[8fb6]",
          option_info: "辶 relates to move",
          temporary_remove: 0,
          specific_position: "HALF_TOP_RIGHT_OUTER",
        },
      ],
    },
    {
      model_type: "TRAINING_DRAG_DROP",
      chinese_character: {
        id: 594,
        character_unicode: "[7ae0]",
        chinese: "章",
        structure_type: "TOP_BOTTOM",
        pinyin: "zhāng",
        meaning: "chapter",
        word: "文章",
        word_meaning: "essay, article",
        word_pinyin: "wénzhāng",
        character_svg_path: "characters/0594/0594_03_20_char.svg",
        audio_path: "audio/zhang1.mp3",
        image_path: null,
      },
      fragment_masks: [
        {
          id: 911,
          character_unicode: "[7ae0]",
          character_id: 594,
          position: "BOTTOM",
          mask_svg_path: "characters/0594/0594_03_20_mask_b.svg",
        },
        {
          id: 912,
          character_unicode: "[7ae0]",
          character_id: 594,
          position: "TOP",
          mask_svg_path: "characters/0594/0594_03_20_mask_t.svg",
        },
      ],
      fragment_options: [
        {
          id: 4290,
          character_unicode: "[6500]",
          character_id: 2371,
          position: "WRONG",
          option_svg_path: "characters/2371/2371_option_b.svg",
          option_unicode: "[624b]",
          option_info: "relates to hand",
          temporary_remove: 0,
          specific_position: "TOP_BOTTOM_2",
        },
        {
          id: 4257,
          character_unicode: "[809a]",
          character_id: 2353,
          position: "WRONG",
          option_svg_path: "characters/2353/2353_option_r.svg",
          option_unicode: "[571f]",
          option_info: "relates to earth",
          temporary_remove: 0,
          specific_position: "LEFT_RIGHT_2",
        },
        {
          id: 912,
          character_unicode: "[7ae0]",
          character_id: 594,
          position: "TOP",
          option_svg_path: "characters/0594/0594_03_20_option_t.svg",
          option_unicode: "[7acb]",
          option_info: "relates to stand",
          temporary_remove: 0,
          specific_position: "TOP_BOTTOM_1",
        },
        {
          id: 4232,
          character_unicode: "[5b58]",
          character_id: 2339,
          position: "WRONG",
          option_svg_path: "characters/2339/2339_option_i.svg",
          option_unicode: "[5b50]",
          option_info: "relates to child",
          temporary_remove: 0,
          specific_position: "HALF_BOTTOM_RIGHT_INNER",
        },
        {
          id: 911,
          character_unicode: "[7ae0]",
          character_id: 594,
          position: "BOTTOM",
          option_svg_path: "characters/0594/0594_03_20_option_b.svg",
          option_unicode: "[65e9]",
          option_info: "",
          temporary_remove: 0,
          specific_position: "TOP_BOTTOM_2",
        },
      ],
    },
    {
      model_type: "TRAINING_DRAG_DROP",
      chinese_character: {
        id: 950,
        character_unicode: "[7a33]",
        chinese: "稳",
        structure_type: "LEFT_RIGHT",
        pinyin: "wěn",
        meaning: "steady, stable",
        word: "稳定",
        word_meaning: "stable",
        word_pinyin: "wěndìng",
        character_svg_path: "characters/0950/0950_06_35_char.svg",
        audio_path: "audio/wen3.mp3",
        image_path: null,
      },
      fragment_masks: [
        {
          id: 1578,
          character_unicode: "[7a33]",
          character_id: 950,
          position: "LEFT",
          mask_svg_path: "characters/0950/0950_06_35_mask_l.svg",
        },
        {
          id: 1579,
          character_unicode: "[7a33]",
          character_id: 950,
          position: "RIGHT",
          mask_svg_path: "characters/0950/0950_06_35_mask_r.svg",
        },
      ],
      fragment_options: [
        {
          id: 3423,
          character_unicode: "[5212]",
          character_id: 1929,
          position: "WRONG",
          option_svg_path: "characters/1929/1929_option_r.svg",
          option_unicode: "[5202]",
          option_info: "刂 relates to knife or sharp things",
          temporary_remove: 0,
          specific_position: "LEFT_RIGHT_2",
        },
        {
          id: 1578,
          character_unicode: "[7a33]",
          character_id: 950,
          position: "LEFT",
          option_svg_path: "characters/0950/0950_06_35_option_l.svg",
          option_unicode: "[79beX]",
          option_info: "relates to grain",
          temporary_remove: 0,
          specific_position: "LEFT_RIGHT_1",
        },
        {
          id: 1579,
          character_unicode: "[7a33]",
          character_id: 950,
          position: "RIGHT",
          option_svg_path: "characters/0950/0950_06_35_option_r.svg",
          option_unicode: "[6025]",
          option_info: "",
          temporary_remove: 0,
          specific_position: "LEFT_RIGHT_2",
        },
        {
          id: 4852,
          character_unicode: "[9884]",
          character_id: 2659,
          position: "WRONG",
          option_svg_path: "characters/2659/2659_option_l.svg",
          option_unicode: "[4e88]",
          option_info:
            "This part gives a hint of the pinyin spelling or pronunciation of the character",
          temporary_remove: 0,
          specific_position: "LEFT_RIGHT_1",
        },
        {
          id: 5026,
          character_unicode: "[65bd]",
          character_id: 2751,
          position: "WRONG",
          option_svg_path: "characters/2751/2751_option_l.svg",
          option_unicode: "[65b9]",
          option_info: "relates to square",
          temporary_remove: 0,
          specific_position: "LEFT_RIGHT_1",
        },
      ],
    },
    {
      model_type: "TRAINING_DRAG_DROP",
      chinese_character: {
        id: 829,
        character_unicode: "[95f2]",
        chinese: "闲",
        structure_type: "HALF_BOTTOM_CENTER",
        pinyin: "xián",
        meaning: "not busy, free",
        word: "休闲",
        word_meaning: "to have leisure",
        word_pinyin: "xiūxián",
        character_svg_path: "characters/0829/0829_05_30_char.svg",
        audio_path: "audio/xian2.mp3",
        image_path: null,
      },
      fragment_masks: [
        {
          id: 1337,
          character_unicode: "[95f2]",
          character_id: 829,
          position: "INNER",
          mask_svg_path: "characters/0829/0829_05_30_mask_i.svg",
        },
        {
          id: 1338,
          character_unicode: "[95f2]",
          character_id: 829,
          position: "OUTER",
          mask_svg_path: "characters/0829/0829_05_30_mask_o.svg",
        },
      ],
      fragment_options: [
        {
          id: 1337,
          character_unicode: "[95f2]",
          character_id: 829,
          position: "INNER",
          option_svg_path: "characters/0829/0829_05_30_option_i.svg",
          option_unicode: "[6728]",
          option_info: "relates to tree",
          temporary_remove: 0,
          specific_position: "HALF_BOTTOM_CENTER_INNER",
        },
        {
          id: 1912,
          character_unicode: "[4ead]",
          character_id: 1178,
          position: "WRONG",
          option_svg_path: "characters/1178/1178_option_b.svg",
          option_unicode: "[4e01]",
          option_info: "",
          temporary_remove: 0,
          specific_position: "TOP_MID_BOTTOM_3",
        },
        {
          id: 1338,
          character_unicode: "[95f2]",
          character_id: 829,
          position: "OUTER",
          option_svg_path: "characters/0829/0829_05_30_option_o.svg",
          option_unicode: "[95e8]",
          option_info: "relates to gate",
          temporary_remove: 0,
          specific_position: "HALF_BOTTOM_CENTER_OUTER",
        },
        {
          id: 4661,
          character_unicode: "[4f3a]",
          character_id: 2563,
          position: "WRONG",
          option_svg_path: "characters/2563/2563_option_l.svg",
          option_unicode: "[4ebb]",
          option_info: "",
          temporary_remove: 0,
          specific_position: "LEFT_RIGHT_1",
        },
        {
          id: 4647,
          character_unicode: "[811a]",
          character_id: 2557,
          position: "WRONG",
          option_svg_path: "characters/2557/2557_option_l.svg",
          option_unicode: "[6708]",
          option_info: "月 relates to body part",
          temporary_remove: 0,
          specific_position: "LEFT_RIGHT_1",
        },
      ],
    },
    {
      model_type: "TRAINING_DRAG_DROP",
      chinese_character: {
        id: 870,
        character_unicode: "[641e]",
        chinese: "搞",
        structure_type: "LEFT_RIGHT",
        pinyin: "gǎo",
        meaning: "to engage in",
        word: "搞音乐",
        word_meaning: "to engage in music industry",
        word_pinyin: "gǎo yīnyuè",
        character_svg_path: "characters/0870/0870_05_32_char.svg",
        audio_path: "audio/gao3.mp3",
        image_path: null,
      },
      fragment_masks: [
        {
          id: 1416,
          character_unicode: "[641e]",
          character_id: 870,
          position: "LEFT",
          mask_svg_path: "characters/0870/0870_05_32_mask_l.svg",
        },
        {
          id: 1417,
          character_unicode: "[641e]",
          character_id: 870,
          position: "RIGHT",
          mask_svg_path: "characters/0870/0870_05_32_mask_r.svg",
        },
      ],
      fragment_options: [
        {
          id: 1417,
          character_unicode: "[641e]",
          character_id: 870,
          position: "RIGHT",
          option_svg_path: "characters/0870/0870_05_32_option_r.svg",
          option_unicode: "[9ad8]",
          option_info:
            "This part gives a hint of the pinyin spelling or pronunciation of the character",
          temporary_remove: 0,
          specific_position: "LEFT_RIGHT_2",
        },
        {
          id: 1416,
          character_unicode: "[641e]",
          character_id: 870,
          position: "LEFT",
          option_svg_path: "characters/0870/0870_05_32_option_l.svg",
          option_unicode: "[624c]",
          option_info: "扌 relates to hand",
          temporary_remove: 0,
          specific_position: "LEFT_RIGHT_1",
        },
        {
          id: 4112,
          character_unicode: "[8038]",
          character_id: 2280,
          position: "WRONG",
          option_svg_path: "characters/2280/2280_option_b.svg",
          option_unicode: "[8033]",
          option_info: "relates to ear",
          temporary_remove: 0,
          specific_position: "TOP_BOTTOM_2",
        },
        {
          id: 1918,
          character_unicode: "[51b0]",
          character_id: 1180,
          position: "WRONG",
          option_svg_path: "characters/1180/1180_option_r.svg",
          option_unicode: "[6c34]",
          option_info: "水 relates to water",
          temporary_remove: 0,
          specific_position: "LEFT_RIGHT_2",
        },
        {
          id: 4548,
          character_unicode: "[884d]",
          character_id: 2502,
          position: "WRONG",
          option_svg_path: "characters/2502/2502_option_r.svg",
          option_unicode: "[4e8d]",
          option_info: "",
          temporary_remove: 0,
          specific_position: "LEFT_CENTER_RIGHT_3",
        },
      ],
    },
    {
      model_type: "TRAINING_DRAG_DROP",
      chinese_character: {
        id: 986,
        character_unicode: "[62a2]",
        chinese: "抢",
        structure_type: "LEFT_RIGHT",
        pinyin: "qiǎng",
        meaning: "to snatch, to fight for",
        word: "抢劫",
        word_meaning: "to rob, robbery",
        word_pinyin: "qiǎngjié",
        character_svg_path: "characters/0986/0986_06_37_char.svg",
        audio_path: "audio/qiang3.mp3",
        image_path: "mcq_images/0986.jpg",
      },
      fragment_masks: [
        {
          id: 1646,
          character_unicode: "[62a2]",
          character_id: 986,
          position: "LEFT",
          mask_svg_path: "characters/0986/0986_06_37_mask_l.svg",
        },
        {
          id: 1647,
          character_unicode: "[62a2]",
          character_id: 986,
          position: "RIGHT",
          mask_svg_path: "characters/0986/0986_06_37_mask_r.svg",
        },
      ],
      fragment_options: [
        {
          id: 1646,
          character_unicode: "[62a2]",
          character_id: 986,
          position: "LEFT",
          option_svg_path: "characters/0986/0986_06_37_option_l.svg",
          option_unicode: "[624c]",
          option_info: "扌 relates to hand",
          temporary_remove: 0,
          specific_position: "LEFT_RIGHT_1",
        },
        {
          id: 3617,
          character_unicode: "[5076]",
          character_id: 2028,
          position: "WRONG",
          option_svg_path: "characters/2028/2028_option_l.svg",
          option_unicode: "[4ebb]",
          option_info: "亻 relates to person",
          temporary_remove: 0,
          specific_position: "LEFT_RIGHT_1",
        },
        {
          id: 1647,
          character_unicode: "[62a2]",
          character_id: 986,
          position: "RIGHT",
          option_svg_path: "characters/0986/0986_06_37_option_r.svg",
          option_unicode: "[4ed3]",
          option_info: "",
          temporary_remove: 0,
          specific_position: "LEFT_RIGHT_2",
        },
        {
          id: 4339,
          character_unicode: "[5353]",
          character_id: 2395,
          position: "WRONG",
          option_svg_path: "characters/2395/2395_option_b.svg",
          option_unicode: "[5341]",
          option_info: "relates to ten",
          temporary_remove: 0,
          specific_position: "TOP_MID_BOTTOM_3",
        },
        {
          id: 2984,
          character_unicode: "[9a8c]",
          character_id: 1706,
          position: "WRONG",
          option_svg_path: "characters/1706/1706_option_r.svg",
          option_unicode: "[4f65]",
          option_info: "",
          temporary_remove: 0,
          specific_position: "LEFT_RIGHT_2",
        },
      ],
    },
    {
      model_type: "TRAINING_DRAG_DROP",
      chinese_character: {
        id: 868,
        character_unicode: "[51d1]",
        chinese: "凑",
        structure_type: "LEFT_RIGHT",
        pinyin: "còu",
        meaning: "gather together",
        word: "凑合",
        word_meaning: "(coll.) to make do, to be passable, to be not too bad",
        word_pinyin: "còuhe",
        character_svg_path: "characters/0868/0868_05_32_char.svg",
        audio_path: "audio/cou4.mp3",
        image_path: null,
      },
      fragment_masks: [
        {
          id: 1412,
          character_unicode: "[51d1]",
          character_id: 868,
          position: "LEFT",
          mask_svg_path: "characters/0868/0868_05_32_mask_l.svg",
        },
        {
          id: 1413,
          character_unicode: "[51d1]",
          character_id: 868,
          position: "RIGHT",
          mask_svg_path: "characters/0868/0868_05_32_mask_r.svg",
        },
      ],
      fragment_options: [
        {
          id: 2667,
          character_unicode: "[7edf]",
          character_id: 1549,
          position: "WRONG",
          option_svg_path: "characters/1549/1549_option_l.svg",
          option_unicode: "[7e9f]",
          option_info: "纟 relates to thread/textile",
          temporary_remove: 0,
          specific_position: "LEFT_RIGHT_1",
        },
        {
          id: 1683,
          character_unicode: "[8bc4]",
          character_id: 1004,
          position: "WRONG",
          option_svg_path: "characters/1004/1004_06_38_option_l.svg",
          option_unicode: "[8ba0]",
          option_info: " 讠relates to language",
          temporary_remove: 0,
          specific_position: "LEFT_RIGHT_1",
        },
        {
          id: 1413,
          character_unicode: "[51d1]",
          character_id: 868,
          position: "RIGHT",
          option_svg_path: "characters/0868/0868_05_32_option_r.svg",
          option_unicode: "[594f]",
          option_info: "",
          temporary_remove: 0,
          specific_position: "LEFT_RIGHT_2",
        },
        {
          id: 140,
          character_unicode: "[770b]",
          character_id: 124,
          position: "WRONG",
          option_svg_path: "characters/0124/0124_01_07_option_t.svg",
          option_unicode: "[9fb5]",
          option_info: "",
          temporary_remove: 0,
          specific_position: "TOP_BOTTOM_1",
        },
        {
          id: 1412,
          character_unicode: "[51d1]",
          character_id: 868,
          position: "LEFT",
          option_svg_path: "characters/0868/0868_05_32_option_l.svg",
          option_unicode: "[51ab]",
          option_info: "relates to ice",
          temporary_remove: 0,
          specific_position: "LEFT_RIGHT_1",
        },
      ],
    },
  ],
};

import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Cascader,
  Checkbox,
  ConfigProvider,
  Input,
  Modal,
  Popover,
} from "antd";
import Search from "antd/es/input/Search";
import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../apiHandlers/apiUtils";
import {
  getCharactersForLesson,
  getCharactersWithChinese,
} from "../../apiHandlers/characterApiHandler";
import { getRecommendedImages } from "../../apiHandlers/classroomTestApiHandler";
import { CLASSROOM_TEACHER_FEEDBACK_SUBMITTED } from "../../constants/api";
import { extractUniqueChineseCharactersToArray } from "../../utils/stringUtils";
import { updateToastAfterMinDuration } from "../../utils/toastUtils";
import CharacterOption from "./CharacterOption";
import styles from "./EditTestModal.module.scss";
import ImageSelectionFeedback from "./ImageSelectionFeedback";
import SelectImageComponent from "./SelectImageComponent";

/*
const optionsSample = [
    {
      value: '1',
      label: 'NUS',
      children: [
        {
          value: '1',
          label: 'Level 1',
          children: [
            {
              value: '1',
              label: 'Lesson 1',
            },
            {
              value: '2',
              label: 'Lesson 2',
            },
          ],
        },
        {
          value: '2',
          label: 'Level 2',
          children: [
            {
              value: '9',
              label: 'Lesson 9',
            },
            {
              value: '10',
              label: 'Lesson 10',
            },
          ],
        },
      ],
    },
    {
      value: '2',
      label: 'ps',
      children: [
        {
          value: '1',
          label: 'Level 1',
          children: [
            {
              value: '1',
              label: 'Lesson 1',
            },
          ],
        },
      ],
    },
    {
      value: '3',
      label: 'HSK',
      children: [
        {
          value: '1',
          label: 'Level 1',
          children: [
            {
              value: '1',
              label: 'Lesson 1',
            },
          ],
        },
      ],
    },
  ];
*/

const syllabusData = {
  nus: [8, 14, 20, 26, 32, 38], // level 1: lessons 1-8; level 2: lessons 9-14; ...
  ntu: [7, 13, 22], // same schema as nus
  ps: [20, 19, 17, 16, 15, 10], // level 1: lessons 1-20; level 2: lessons 1-19; ...
  hsk: [6, 6, 9, 15, 21, 31], // same schema as ps
};
const options = [];

// Function to generate lessons for a given syllabus and level
const generateLessonOptionObjs = (minLessonNumber, maxLessonNumber) => {
  const lessonOptionObjs = [];
  for (let i = minLessonNumber; i <= maxLessonNumber; i++) {
    lessonOptionObjs.push({
      value: i.toString(),
      label: `Lesson ${i}`,
    });
  }
  return lessonOptionObjs;
};

// Generate the options object based on syllabusData
for (const syllabus in syllabusData) {
  const levels = syllabusData[syllabus];

  const syllabusOptionObj = {
    value:
      syllabus === "nus"
        ? "1"
        : syllabus === "ps"
        ? "2"
        : syllabus === "hsk"
        ? "3"
        : "4",
    label:
      syllabus === "nus"
        ? "NUS"
        : syllabus === "ps"
        ? "Primary"
        : syllabus === "hsk"
        ? "HSK"
        : "NTU",
    children: [],
  };

  for (let i = 0; i < levels.length; i++) {
    const levelName = syllabus === "ps" ? "Primary" : "Level";
    let minLessonNumber = 1;
    if ((syllabus === "nus" || syllabus === "ntu") && i >= 1) {
      minLessonNumber = levels[i - 1] + 1;
    }
    const maxLessonNumber = levels[i];

    const levelOptionObj = {
      value: (i + 1).toString(),
      label: `${levelName} ${i + 1}`,
      children: generateLessonOptionObjs(minLessonNumber, maxLessonNumber),
    };
    syllabusOptionObj.children.push(levelOptionObj);
  }

  options.push(syllabusOptionObj);
}

// const checkIfImagesModified = (newImages, originalImages) => {
//   // Simple comparison logic to detect changes
//   if (!newImages) {
//     return false;
//   }
//   if (newImages?.length !== originalImages?.length) return true;
//   for (let i = 0; i < newImages.length; i++) {
//     if (newImages[i]?.id !== originalImages[i]?.id) return true;
//   }
//   return false;
// };

function EditTestModal({
  open,
  loading,
  onCancel,
  onConfirm,
  mode,
  existingTest,
}) {
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [candidateCharacters, setCandidateCharacters] = useState([]);
  const [selectedCharacters, setSelectedCharacters] = useState([]);
  const [testName, setTestName] = useState("");
  const [prevSearchedCharacters, setPrevSearchedCharacters] = useState([]);
  // Initialize selectedImages as an empty array
  const [selectedImages, setSelectedImages] = useState([]);
  const [showImageSelectModal, setShowImageSelectModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isSurveyModalOpen, setIsSurveyModalOpen] = useState(false);

  const isImageSelected = useMemo(() => {
    return !selectedImages.every((image) => image === null);
  }, [selectedImages]);

  // Update selectedImages when selectedCharacters changes
  useEffect(() => {
    setSelectedImages((prevImages) => {
      // If we have fewer images than characters, pad with nulls
      if (prevImages.length < selectedCharacters.length) {
        return [
          ...prevImages,
          ...Array(selectedCharacters.length - prevImages.length).fill(null),
        ];
      }
      // If we have more images than characters, truncate
      if (prevImages.length > selectedCharacters.length) {
        return prevImages.slice(0, selectedCharacters.length);
      }
      return prevImages;
    });
  }, [selectedCharacters]);

  // Handle existing test data
  useEffect(() => {
    if (existingTest) {
      setSelectedCharacters(existingTest.characters || []);
      setTestName(existingTest.name || "");
      setSelectedImages(existingTest.images || []);
    } else {
      cleanUpModalContents();
    }
  }, [existingTest]);

  // const prevImages = existingTest?.images || selectedImages;

  const handleNext = async () => {
    try {
      if (selectedCharacters.length === 0) {
        toast.info("Please select at least one character before proceeding.", {
          autoClose: 2000,
        });
        return;
      }
      if (testName.length === 0) {
        toast.info("Please enter test name before proceeding.", {
          autoClose: 2000,
        });
        return;
      }
      const characterIds = selectedCharacters.map((char) => char.id);
      // Get recommended images from the backend
      const recommendedImageUrls = await getRecommendedImages(characterIds);
    } catch (error) {
      console.error("Error fetching recommended images:", error);
      toast.error("Failed to fetch recommended images.");
    } finally {
      // Show the modal with the selected images
      if (selectedCharacters.length > 0 && testName.length > 0) {
        setShowImageSelectModal(true);
      }
    }
  };

  // // Function to handle image selection for a character by index
  const handleImageSelect = (index, image) => {
    const updatedImages = [...selectedImages];
    updatedImages[index] = image;
    setSelectedImages(updatedImages);
  };

  const handleLessonChange = async (lesson) => {
    if (lesson == null) {
      setSelectedLesson(null);
      setCandidateCharacters([]);
      return;
    } else if (selectedLesson != null) {
      if (
        lesson[0] === selectedLesson[0] &&
        lesson[1] === selectedLesson[1] &&
        lesson[2] === selectedLesson[2]
      )
        return;
    }

    setSelectedLesson(lesson);
    let toastId;
    const loadingStartTime = Date.now();
    try {
      toastId = toast.loading("Loading characters", { autoClose: 1000 });
      const lessonCharacters = await getCharactersForLesson({
        syllabus_id: lesson[0],
        level_number: lesson[1],
        lesson_number: lesson[2],
      });
      handleLoadingCharactersSuccess(
        lessonCharacters,
        toastId,
        loadingStartTime
      );
    } catch (err) {
      handleLoadingCharactersError(err, toastId, loadingStartTime);
    }
  };

  useEffect(() => {
    setPrevSearchedCharacters([]);
  }, [selectedLesson]);

  const handleSearchCharacters = async (value) => {
    if (!value) return;

    let toastId;
    const chineseCharacters = extractUniqueChineseCharactersToArray(value);
    if (chineseCharacters.length === 0) {
      toastId = "no-chinese-char-found-toast";
      toast.info("No Chinese characters were found in the text.", {
        toastId,
        autoClose: 2000,
      });
      return;
    } else if (chineseCharacters.join("") === prevSearchedCharacters.join("")) {
      toastId = "no-new-chinese-char-found-toast";
      toast.info("No new Chinese characters were found in the text.", {
        toastId,
        autoClose: 2000,
      });
      return;
    }

    const loadingStartTime = Date.now();
    try {
      toastId = toast.loading("Loading characters", { autoClose: 1000 });
      const charactersSearchResult = await getCharactersWithChinese(
        chineseCharacters
      );
      handleLoadingCharactersSuccess(
        charactersSearchResult,
        toastId,
        loadingStartTime
      );
      setSelectedLesson(null);
      setPrevSearchedCharacters(chineseCharacters);
    } catch (err) {
      handleLoadingCharactersError(err, toastId, loadingStartTime);
    }
  };

  const handleLoadingCharactersSuccess = (
    loadedCharacters,
    toastId,
    loadingStartTime
  ) => {
    setCandidateCharacters(
      // TODO: may be better if backend directly return whether this character can be "pin"ed
      loadedCharacters.filter((character) =>
        character.svg_path.includes("characters")
      )
    );
    updateToastAfterMinDuration(toastId, loadingStartTime, {
      render: "Characters loaded",
      type: toast.TYPE.SUCCESS,
      autoClose: 1000,
      isLoading: false,
      closeButton: null,
      hideProgressBar: true,
    });
  };

  const handleLoadingCharactersError = (err, toastId, loadingStartTime) => {
    updateToastAfterMinDuration(toastId, loadingStartTime, {
      render: getErrorMessage(err),
      type: toast.TYPE.ERROR,
      autoClose: null,
      isLoading: false,
      closeButton: null,
    });
  };

  const cleanUpModalContents = () => {
    setSelectedLesson(null);
    setCandidateCharacters([]);
    setSelectedCharacters([]);
    setTestName("");
    setPrevSearchedCharacters([]);
    setIsChecked(false);
    setShowImageSelectModal(false);
  };

  const callbackOnSubmit = () => {
    cleanUpModalContents();
    if (isImageSelected && localStorage.getItem(CLASSROOM_TEACHER_FEEDBACK_SUBMITTED) !== "1") {
      setIsSurveyModalOpen(true);
    }
  }

  const handleCancel = () => {
    onCancel(cleanUpModalContents);
  };

  const handleConfirm = async () => {
    // console.log(selectedImages);
    // TODO: disable cancel, character selection, etc., maybe have one props modalDisabled
    if (isImageSelected && !isChecked) {
      toast.info("Please read and check the acknowledgement.", {
        autoClose: 2000,
      });
      return;
    }

    onConfirm(
      {
        quiz_name: testName,
        character_ids: selectedCharacters.map((c) => c.id),
        selected_images: selectedImages.map((image) => ({
          id: image?.id,
          imageURL: image?.previewURL,
        })),
      },
      callbackOnSubmit,
    );
  };

  const imageSelectModalFooter = [
    <div key="disclaimer" style={{ textAlign: "left" }}>
      <Checkbox
        checked={isChecked}
        onChange={(e) => setIsChecked(e.target.checked)}
        disabled={!isImageSelected} // Only enable if at least one image is selected
      >
        I acknowledge that I am responsible for selecting relevant images for
        learning purposes.
      </Checkbox>
    </div>,
    <Button
      key="back"
      onClick={() => {
        setShowImageSelectModal(false);
        setIsChecked(false);
        setSelectedImages(Array(selectedCharacters.length).fill(null));
      }}
    >
      Back
    </Button>,
    <Button
      key="submit"
      type="primary"
      loading={loading}
      onClick={handleConfirm}
    >
      Confirm
    </Button>,
  ];

  const handleSelectCharacter = (character) => {
    if (selectedCharacters.length >= 10) {
      const toastId = "exceed-char-limit-toast";
      toast.info(
        "A maximum of 10 characters have been selected for the test.",
        { toastId, autoClose: 2000 }
      );
      return;
    }
    setSelectedCharacters((prevSelectedCharacters) => {
      return [...prevSelectedCharacters, character];
    });
  };

  const handleRemoveCharacter = (character) => {
    setSelectedCharacters((prev) => {
      const index = prev.findIndex((c) => c.id === character.id);
      if (index === -1) return prev;

      // Also remove the corresponding image
      setSelectedImages((prevImages) => {
        const newImages = [...prevImages];
        newImages.splice(index, 1);
        return newImages;
      });

      return prev.filter((c) => c.id !== character.id);
    });
  };

  const handleTestNameChange = (e) => {
    setTestName(e.target.value);
  };

  const footer = [
    <Button key="back" onClick={handleCancel}>
      Cancel
    </Button>,
    <Button key="next" type="primary" onClick={handleNext}>
      Next
    </Button>,
  ];

  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            contentBg: "#FFFCF8",
            headerBg: "#FFFCF8",
            titleFontSize: 22,
            wireframe: true,
          },
        },
      }}
    >
      {!showImageSelectModal ? (
        <Modal
          title={
            mode === CREATE_TEST_MODE
              ? "Create new test"
              : `Edit Test "${existingTest?.name}"`
          }
          centered
          open={open}
          onCancel={handleCancel}
          width={1000}
          footer={footer}
          maskClosable={false}
          destroyOnClose={true}
        >
          <div className={styles["modal-body"]}>
            <div className={styles["lesson-selector"]}>
              <ConfigProvider
                theme={{
                  components: {
                    Cascader: {
                      controlWidth: 270,
                      controlItemWidth: 80,
                      dropdownHeight: 250,
                    },
                  },
                  token: {
                    colorTextPlaceholder: "#8A8B86",
                  },
                }}
              >
                <Cascader
                  options={options}
                  onChange={handleLessonChange}
                  placeholder="Select a lesson"
                  allowClear={false}
                  value={selectedLesson}
                  size="large"
                />
              </ConfigProvider>
            </div>

            <div className={styles["search-bar"]}>
              <ConfigProvider
                theme={{
                  token: {
                    colorTextPlaceholder: "#8A8B86",
                  },
                }}
              >
                <Search
                  placeholder="Input text to search for characters"
                  onSearch={handleSearchCharacters}
                  enterButton
                  allowClear
                  size="large"
                  maxLength={400}
                />
              </ConfigProvider>
            </div>

            <div className={styles["candidate-characters-area"]}>
              {candidateCharacters.map((lessonCharacter) => {
                if (
                  selectedCharacters.some((c) => c.id === lessonCharacter.id)
                ) {
                  return (
                    <CharacterOption
                      key={lessonCharacter.id}
                      character={lessonCharacter}
                      bgColor="#58E440"
                      showSelectedTick={true}
                    />
                  );
                } else {
                  return (
                    <CharacterOption
                      key={lessonCharacter.id}
                      character={lessonCharacter}
                      bgColor="#D6F4F6"
                      enableSelect={true}
                      onSelect={handleSelectCharacter}
                    />
                  );
                }
              })}
            </div>

            <div className={styles["selected-characters-area"]}>
              {selectedCharacters.map((selectedCharacter) => {
                return (
                  <CharacterOption
                    key={selectedCharacter.id}
                    character={selectedCharacter}
                    bgColor="#D6F6D8"
                    enableRemove={true}
                    onRemove={handleRemoveCharacter}
                  />
                );
              })}
              {[...Array(10 - selectedCharacters.length).keys()].map(
                (index) => {
                  return (
                    <CharacterOption
                      key={`empty-${index}`}
                      bgColor="transparent"
                      outline={true}
                    />
                  );
                }
              )}
            </div>

            <div className={styles["name-input"]}>
              <Input
                size="large"
                bordered={false}
                placeholder="Enter a name for the test"
                maxLength={50}
                value={testName}
                onChange={handleTestNameChange}
                className={styles["name-input-text"]}
              />
            </div>
          </div>
        </Modal>
      ) : (
        <Modal
          styles={{
            body: {
              maxHeight: "85vh",
              overflowY: "auto",
            },
          }}
          title={`Select Images for Test "${testName ?? existingTest.name}"`}
          centered
          width={1000}
          open={open}
          onCancel={handleCancel}
          footer={imageSelectModalFooter}
          maskClosable={false}
          destroyOnClose={true}
        >
          {selectedCharacters.map((character, index) => (
            <div key={character.id} className={styles["image-selector-row"]}>
              <div className={styles["character-and-tooltip"]}>
                <CharacterOption
                  key={character.id}
                  character={character}
                  bgColor="#D6F6D8"
                />
                <Popover
                  placement="rightBottom"
                  title="Search images"
                  content={`Enter a search term to find relevant images for ${character.chinese}`}
                  trigger="hover"
                >
                  <QuestionCircleOutlined className={styles.helpIcon} />
                </Popover>
              </div>
              <SelectImageComponent
                characterId={character.id}
                onSelectImage={(image) => handleImageSelect(index, image)}
                // selectedImage={selectedImages[index]}
                defaultSearchTerm={character.meaning}
              />
            </div>
          ))}
        </Modal>
      )}

      <ImageSelectionFeedback
        userType="TEACHER"
        isSurveyModalOpen={isSurveyModalOpen}
        closeModal={() => {
          setIsSurveyModalOpen(false);
        }}
        callbackOnSuccessfulSubmit={() => {
          localStorage.setItem(CLASSROOM_TEACHER_FEEDBACK_SUBMITTED, "1");
        }}
      />
    </ConfigProvider>
  );
}

export default EditTestModal;

export const CREATE_TEST_MODE = "create";
export const UPDATE_TEST_MODE = "update";

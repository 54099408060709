import { green, red, volcano, yellow } from "@ant-design/colors";
import {
  CheckCircleOutlined,
  InfoCircleOutlined,
  SoundFilled,
} from "@ant-design/icons";
import { Button, Divider, Modal, Progress, Row } from "antd";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import nusLogos from "../../assets/nus_logos.png";
import encloseStructure from "../../assets/structure_svg/ENCLOSE.png";
import halfBottomCenterStructure from "../../assets/structure_svg/HALF_BOTTOM_CENTER.png";
import halfBottomLeftStructure from "../../assets/structure_svg/HALF_BOTTOM_LEFT.png";
import halfBottomRightStructure from "../../assets/structure_svg/HALF_BOTTOM_RIGHT.png";
import halfMidRightStructure from "../../assets/structure_svg/HALF_MID_RIGHT.png";
import halfTopCenterStructure from "../../assets/structure_svg/HALF_TOP_CENTER.png";
import halfTopRightStructure from "../../assets/structure_svg/HALF_TOP_RIGHT.png";
import leftCenterRightStructure from "../../assets/structure_svg/LEFT_CENTER_RIGHT.png";
import leftRightStructure from "../../assets/structure_svg/LEFT_RIGHT.png";
import topBottomStructure from "../../assets/structure_svg/TOP_BOTTOM.png";
import topMidBottomStructure from "../../assets/structure_svg/TOP_MID_BOTTOM.png";
import { CCL_ASSETS_PATH } from "../../constants/api.js";

import styles from "./SrsSessionComponent.module.scss";
import Timer, {
  COUNTDOWN_ENDING_TIMER,
  COUNTDOWN_HALF_TIMER,
  COUNTDOWN_TIMER,
} from "./Timer.js";

const correctOptionSoundEffect = new Audio(CCL_ASSETS_PATH + "audio/right.mp3");
const wrongOptionSoundEffect = new Audio(CCL_ASSETS_PATH + "audio/wrong.mp3");

// Game mode: (1) - Quick Play; (2) - Classroom mode
function SrsSessionComponent({
  gameContent,
  maxRoundCount,
  gameMode,
  onPerformanceDataUpdate,
}) {
  console.log(gameContent);
  const [currentCharacter, setCurrentCharacter] = useState({
    chinese: "",
    pinyin: "",
    meaning: "",
    structure: "",
    exampleWord: "",
    examplePinyin: "",
    exampleMeaning: "",
  });

  const [currentCharacterFiles, setCurrentCharacterFiles] = useState({
    pinyinAudioPath: "",
    structureImagePath: "",
    characterImagePath: "",
    mask1ImagePath: "",
    mask2ImagePath: "",
    mask3ImagePath: "",
  });

  const [currentRoundUserProgress, setCurrentRoundUserProgress] = useState({
    mask1Solved: false,
    mask2Solved: false,
    mask3Solved: false,
    progressMade: false,
    fullySolved: false,
    questionSkipped: false,
  });

  const [overallGameProgress, setOverallGameProgress] = useState({
    currentRoundCount: 0, // Updated on user clicking on "next" button; used to control the questions shown
    roundProgressed: 0, // Updated on user completion of a character; used to update progress bar before switching to next question
    gameHasEnded: false,
  });

  // States used for or affected by timer
  const [currentSeconds, setCurrentSeconds] = useState(COUNTDOWN_TIMER);
  const [isTimerRunning, setIsTimerRunning] = useState(true); // value will be returned by Timer component

  // States used for drag and drop
  const [isOuterDraggingOver, setIsOuterDraggingOver] = useState(false);
  const [options, setOptions] = useState([]);
  const [optionsTotalCount, setOptionsTotalCount] = useState(0);
  const [correctOptionsInfoDictionary, setCorrectOptionsInfoDictionary] =
    useState({});

  // States for game statistics
  const [performanceData, setPerformanceData] = useState({
    questionAttempts: [], // A list of questionAttempt, for the structure, see "currentQuestionAttempt"
    progressBarColours: [],
  });
  const [currentQuestionAttempt, setCurrentQuestionAttempt] = useState({
    characterId: "",
    characterDetails: null, // See "currentCharacter" for the layout
    timeTaken: null,
    status: "INCOMPLETE", // Possible values: "CORRECT", "SKIPPED", "TIMEOUT", "INCOMPLETE"
    dragDropAttempts: [], // A list of drag drop attempts, for the structure, see "newDragDropAttempt"
  }); // Updated on every success drop, question start, question complete
  const [currentQuestionDragDropAttempts, setCurrentQuestionDragDropAttempts] =
    useState([]); // Updated on every success drop

  const totalRoundCount =
    gameContent.length - 1 < maxRoundCount ? gameContent.length : maxRoundCount;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  /**
   * Update windowWidth state whenever the window is resized
   */
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  /**
   * Call parent-component function to handle question update, if the call-back function is given
   */
  useEffect(() => {
    if (onPerformanceDataUpdate) {
      onPerformanceDataUpdate(
        { ...performanceData },
        overallGameProgress.roundProgressed === totalRoundCount,
        false
      );
    }
  }, [performanceData]);

  /**
   * Update the states when the user click goes to a new round.
   * The states used for a new question will be initialised when the game switch to new question.
   * This includes the detail of the character involved, user progress on the question, times and options.
   */
  useEffect(() => {
    // Check if game has ended, update state and exit if it has ended
    if (overallGameProgress.currentRoundCount >= totalRoundCount) {
      setOverallGameProgress((prevGameProgress) => ({
        ...prevGameProgress,
        gameHasEnded: true,
      }));
      return;
    }

    const currentRound = gameContent[overallGameProgress.currentRoundCount];
    let doesCharacterStructureHasThreeParts =
      currentRound.chinese_character.structure_type === "TOP_MID_BOTTOM" ||
      currentRound.chinese_character.structure_type === "LEFT_CENTER_RIGHT";

    let tempCurrentCharacter = {
      chinese: currentRound.chinese_character.chinese,
      pinyin: currentRound.chinese_character.pinyin,
      meaning: currentRound.chinese_character.meaning,
      structure: currentRound.chinese_character.structure_type,
      exampleWord: currentRound.chinese_character.word,
      examplePinyin: currentRound.chinese_character.word_pinyin,
      exampleMeaning: currentRound.chinese_character.word_meaning,
    };

    setCurrentCharacter(tempCurrentCharacter);

    setCurrentCharacterFiles({
      pinyinAudioPath: `${CCL_ASSETS_PATH}${currentRound.chinese_character.audio_path}`,
      structureImagePath: `${CCL_ASSETS_PATH}structure_svg/${currentRound.chinese_character.structure_type}.svg`,
      characterImagePath: `${CCL_ASSETS_PATH}${currentRound.chinese_character.character_svg_path}`,
      mask1ImagePath: `${CCL_ASSETS_PATH}${currentRound.fragment_masks[0].mask_svg_path}`,
      mask2ImagePath: `${CCL_ASSETS_PATH}${currentRound.fragment_masks[1].mask_svg_path}`,
      mask3ImagePath: doesCharacterStructureHasThreeParts
        ? `${CCL_ASSETS_PATH}${currentRound.fragment_masks[2].mask_svg_path}`
        : "",
    });

    setCurrentRoundUserProgress({
      mask1Solved: false,
      mask2Solved: false,
      mask3Solved: doesCharacterStructureHasThreeParts ? false : true,
      progressMade: false,
      fullySolved: false,
      questionSkipped: false,
    });

    setCurrentSeconds(COUNTDOWN_TIMER);
    setIsTimerRunning(true);
    setIsOuterDraggingOver(false);

    const newOptions = [];
    const newCorrectOptionsInfoDictionary = {};

    for (let i = 0; i < currentRound.fragment_options.length; i++) {
      const option = currentRound.fragment_options[i];
      newOptions.push({
        id: `option-${i}`,
        optionImagePath: `${CCL_ASSETS_PATH}${option.option_svg_path}`,
        optionPosition: option.position,
        fragmentOptionId: option.id,
      });
      if (option.position != "WRONG" && option.option_info != "") {
        // Add option info to correctOptionsInfoDictionary for correct options that have an associated info
        newCorrectOptionsInfoDictionary[option.position] = option.option_info;
      }
    }

    setOptions(newOptions);
    setCorrectOptionsInfoDictionary(newCorrectOptionsInfoDictionary);
    // Option total count is used to determine the size of the option box needed
    // Assumption is made that top-mid-bot and left-center-right requires 6 options
    // For drag and drop to work for above two structure, the width of the options must not be > 12vw.
    setOptionsTotalCount(currentRound.fragment_options.length);

    const newQuestionAttempt = {
      characterId: currentRound.chinese_character.id,
      characterDetails: tempCurrentCharacter,
      timeTaken: null,
      status: "INCOMPLETE",
      dragDropAttempts: [],
    };
    setCurrentQuestionAttempt(newQuestionAttempt);
    setPerformanceData({
      ...performanceData,
      questionAttempts: [
        ...performanceData.questionAttempts,
        newQuestionAttempt,
      ],
    });
    setCurrentQuestionDragDropAttempts([]);
  }, [overallGameProgress.currentRoundCount]);

  /**
   * Update the statuses of the current round progress whenever the character mask solved-status changes.
   * This also means that this is called whenever user solves a part.
   *
   * The logic involved for characters with and without 3 parts are different.
   */
  useEffect(() => {
    const currentRound = gameContent[overallGameProgress.currentRoundCount];

    if (
      currentRound.chinese_character.structure_type === "TOP_MID_BOTTOM" ||
      currentRound.chinese_character.structure_type === "LEFT_CENTER_RIGHT"
    ) {
      setCurrentRoundUserProgress((prevRoundUserProgress) => ({
        ...prevRoundUserProgress,
        progressMade:
          currentRoundUserProgress.mask1Solved ||
          currentRoundUserProgress.mask2Solved ||
          currentRoundUserProgress.mask3Solved,
        fullySolved:
          currentRoundUserProgress.mask1Solved &&
          currentRoundUserProgress.mask2Solved &&
          currentRoundUserProgress.mask3Solved,
      }));
    } else {
      setCurrentRoundUserProgress((prevRoundUserProgress) => ({
        ...prevRoundUserProgress,
        progressMade:
          currentRoundUserProgress.mask1Solved ||
          currentRoundUserProgress.mask2Solved,
        fullySolved:
          currentRoundUserProgress.mask1Solved &&
          currentRoundUserProgress.mask2Solved,
      }));
    }
  }, [
    currentRoundUserProgress.mask1Solved,
    currentRoundUserProgress.mask2Solved,
    currentRoundUserProgress.mask3Solved,
  ]);

  /**
   * Update game statistics whenever user fully solved a character.
   *
   * Round progress is used instead of currentRoundCount as the former determines the value in progress bar
   * while the latter determines the character to show.
   *
   * On a fully solved page, the character should not be changed but the progress bar should be updated.
   */
  useEffect(() => {
    if (currentRoundUserProgress.fullySolved) {
      // Update number of rounds progressed
      setOverallGameProgress((prevGameProgress) => ({
        ...prevGameProgress,
        roundProgressed: prevGameProgress.roundProgressed + 1,
      }));
      // Stop timer
      setIsTimerRunning(false);
      // Update progress bar color (Green6, Yellow6, Volcano5, Red7)
      let tempProgressBarColours = performanceData.progressBarColours;
      if (currentSeconds > COUNTDOWN_HALF_TIMER) {
        tempProgressBarColours = [...tempProgressBarColours, green[6]];
      } else if (currentSeconds > COUNTDOWN_ENDING_TIMER) {
        tempProgressBarColours = [...tempProgressBarColours, yellow[5]];
      } else if (currentSeconds > 0) {
        tempProgressBarColours = [...tempProgressBarColours, volcano[5]];
      } else {
        tempProgressBarColours = [...tempProgressBarColours, red[7]];
      }
      // Update current question attempt

      /*
      Scenarios for timeTaken and status recorded within performanceData

      Normal situation
      timeTaken:10; status:"CORRECT"

      Skip after 3
      timeTaken:10; status: "SKIPPED"

      Time out
      timeTaken:30; status: "TIMEOUT"

      Teacher skip
      timeTaken:null; status: "INCOMPLETE"
       */
      const updatedQuestionAttempt = {
        ...currentQuestionAttempt,
        timeTaken: COUNTDOWN_TIMER - currentSeconds,
        status: currentSeconds > 0 ? "CORRECT" : "TIMEOUT",
      };
      setCurrentQuestionAttempt(updatedQuestionAttempt);
      // Update performance data with question attempt and progress bar colours.
      setPerformanceData((prevPerformanceData) => {
        return {
          ...prevPerformanceData,
          progressBarColours: tempProgressBarColours,
          questionAttempts: [
            ...prevPerformanceData.questionAttempts.slice(0, -1),
            updatedQuestionAttempt,
          ],
        };
      });
    }
  }, [currentRoundUserProgress.fullySolved]);

  /**
   *   Stop the timer when it ends. Skip the question too.
   */
  const handleTimerEnd = () => {
    setIsTimerRunning(false);

    setCurrentRoundUserProgress((prevRoundUserProgress) => ({
      ...prevRoundUserProgress,
      questionSkipped: true,
      mask1Solved: true,
      mask2Solved: true,
      mask3Solved: true,
    }));
  };

  const handleGetSeconds = (seconds) => {
    setCurrentSeconds(seconds); // Update currentSeconds in the parent component
  };

  /**
   * Update the mask solved-statuses whenever user drops a correct option.
   *
   * This function is used to find the correct state to update based on the structure position as the mask number for
   * different structures are different too.
   * @param optionPosition A string containing the position of the dropped option
   */
  function updatePositionStatus(optionPosition) {
    let maskToUpdate = 0;
    switch (currentCharacter.structure) {
      case "LEFT_RIGHT":
        if (optionPosition === "LEFT") {
          maskToUpdate = 1;
        }
        if (optionPosition === "RIGHT") {
          maskToUpdate = 2;
        }
        break;
      case "ENCLOSE":
      case "HALF_BOTTOM_CENTER":
      case "HALF_BOTTOM_LEFT":
      case "HALF_BOTTOM_RIGHT":
      case "HALF_MID_RIGHT":
      case "HALF_TOP_CENTER":
      case "HALF_TOP_RIGHT":
        if (optionPosition === "INNER") {
          maskToUpdate = 1;
        }
        if (optionPosition === "OUTER") {
          maskToUpdate = 2;
        }
        break;
      case "LEFT_CENTER_RIGHT":
        if (optionPosition === "LEFT") {
          maskToUpdate = 1;
        }
        if (optionPosition === "MID") {
          maskToUpdate = 2;
        }
        if (optionPosition === "RIGHT") {
          maskToUpdate = 3;
        }
        break;
      case "TOP_BOTTOM":
        if (optionPosition === "BOTTOM") {
          maskToUpdate = 1;
        }
        if (optionPosition === "TOP") {
          maskToUpdate = 2;
        }
        break;
      case "TOP_MID_BOTTOM":
        if (optionPosition === "BOTTOM") {
          maskToUpdate = 1;
        }
        if (optionPosition === "MID") {
          maskToUpdate = 2;
        }
        if (optionPosition === "TOP") {
          maskToUpdate = 3;
        }
        break;
      default:
        break;
    }

    setCurrentRoundUserProgress((prevRoundUserProgress) => ({
      ...prevRoundUserProgress,
      mask1Solved:
        maskToUpdate === 1 ? true : prevRoundUserProgress.mask1Solved,
      mask2Solved:
        maskToUpdate === 2 ? true : prevRoundUserProgress.mask2Solved,
      mask3Solved:
        maskToUpdate === 3 ? true : prevRoundUserProgress.mask3Solved,
    }));
  }

  /**
   * Update the user game statistics on every drag drop attempt.
   * This function is used instead of relying on useEffect to ensure correctness.
   * Also, performanceData and currentQuestionAttempt states are both updated for every success drag and drop instead
   * of question ends to cater for classroom mode where game may end earlier by the instructors.
   *
   * @param fragmentOptionId
   * @param dropPosition
   * @param isCorrectDrop
   */
  function recordDragDropAttempt(
    fragmentOptionId,
    extractedDropPosition,
    isCorrectDrop
  ) {
    let newDragDropAttempt = {
      fragmentOptionId: fragmentOptionId,
      dropPosition: extractedDropPosition,
      correct: isCorrectDrop,
    };
    // Add a new drag drop attempt to current one
    const updatedDragDropAttempts = [
      ...currentQuestionDragDropAttempts,
      newDragDropAttempt,
    ];
    // Update the drag drop attempts in current question attempt
    const updatedQuestionAttempt = {
      ...currentQuestionAttempt, // Assuming currentQuestionAttempt is already defined
      dragDropAttempts: updatedDragDropAttempts,
    };

    setCurrentQuestionDragDropAttempts(updatedDragDropAttempts);
    setCurrentQuestionAttempt(updatedQuestionAttempt);
    setPerformanceData((prevData) => {
      const newData = { ...prevData };
      newData.questionAttempts[newData.questionAttempts.length - 1] =
        updatedQuestionAttempt;
      return newData;
    });
  }

  /**
   * Handle the drag-and-drop result.
   *
   * On a successful drop (draggable option dropped onto droppable box), check if user dropped the correct option.
   *
   * If correct drop (correct draggable option dropped onto matching droppable box), update position status and play
   * correct sound.
   *
   * If incorrect drop (incorrect draggable option dropped onto non-matching droppable box), play incorrect sound.
   *
   * @param result See react-beautiful-dnd.
   * @param provided See react-beautiful-dnd.
   */
  const handleDragEnd = (result, provided) => {
    const { source, destination } = result;
    // Check if drop locations exist
    if (!destination) {
      return; // Put the item back
    }

    // Index below refers to the index of the draggable options.
    // It matches the index where the option is stored within `options` variable (even after splice).
    // For the wrong options, the position will be "WRONG" instead of the original position.
    const optionPosition = options[source.index].optionPosition;

    // Extract the destination droppable id
    let extractedDropPosition = destination.droppableId;
    // Check if drop position contains a number
    if (/\d$/.test(destination.droppableId)) {
      // If number is found, remove the last character
      // This number is added during the cutting of a character structure into multiple parts
      // or the option box (as all draggable is required to be wrapped by droppable)
      // (e.g. outer -> outer1, outer2) or (e.g. optionsBox1, optionsBox2)
      extractedDropPosition = destination.droppableId.slice(0, -1);
    }

    if (extractedDropPosition === "optionsBox") {
      // This happened when user tap on the option without moving on touchscreen
      // We will simply return the option back to its position and do nothing
      return;
    }

    let isCorrectDrop = destination.droppableId.startsWith(optionPosition);
    recordDragDropAttempt(
      options[source.index].fragmentOptionId,
      extractedDropPosition,
      isCorrectDrop
    );
    // Handle success drop
    if (isCorrectDrop) {
      // Remove option on correct drop
      const newOptions = [...options];
      newOptions.splice(source.index, 1);
      setOptions(newOptions);

      updatePositionStatus(optionPosition);

      // Play correct drop sound
      correctOptionSoundEffect.play().catch((e) => {
        console.log("Sound not played in Safari: " + e);
      });
    } else {
      wrongOptionSoundEffect.play().catch((e) => {
        console.log("Sound not played in Safari: " + e);
      });
    }
  };

  function playPinyin(audioPath) {
    const pinyinSoundEffect = new Audio(audioPath);
    pinyinSoundEffect.play();
  }

  return (
    <div className={styles.gameBar}>
      {overallGameProgress.gameHasEnded ? (
        "The game has ended. Something went wrong on game statistics."
      ) : (
        <DragDropContext onDragEnd={handleDragEnd}>
          <div className={styles.nusLogoImageBox}>
            <img
              src={nusLogos}
              alt="SOC and FASS Logos"
              className={styles.nusLogoImage}
            />
          </div>
          <Timer
            onTimerEnd={handleTimerEnd}
            isTimerRunning={isTimerRunning}
            getSeconds={handleGetSeconds}
            roundCount={overallGameProgress.currentRoundCount}
          />
          <div className={styles.message}>
            <h2 className={`${styles.infoTop} ${styles.info}`}>
              <b>Pinyin</b>: {currentCharacter.pinyin}
              <Button
                type="text"
                size="medium"
                icon={<SoundFilled />}
                onClick={() =>
                  playPinyin(currentCharacterFiles.pinyinAudioPath)
                }
              />
              <br />
              <b>Meaning</b>: {currentCharacter.meaning}
              <br />
              <br />
              {currentRoundUserProgress.fullySolved ? (
                <>
                  <b>Example</b>: {currentCharacter.exampleWord} (
                  {currentCharacter.examplePinyin}),{" "}
                  {currentCharacter.exampleMeaning}
                </>
              ) : (
                <span className={`${styles.hidden}`}>
                  <b>Example</b>: -
                </span>
              )}
            </h2>

            <Divider className={styles.messageDivider} />
          </div>
          <div className={styles.belowMessage}>
            <div className={styles.characterBox}>
              <div className={styles.centeredBox}>
                <DropBox
                  currentCharacterFiles={currentCharacterFiles}
                  currentRoundUserProgress={currentRoundUserProgress}
                  characterStructure={currentCharacter.structure}
                  correctOptionsInfoDictionary={correctOptionsInfoDictionary}
                  setIsOuterDraggingOver={setIsOuterDraggingOver}
                  isOuterDraggingOver={isOuterDraggingOver}
                />
                {currentRoundUserProgress.fullySolved &&
                !currentRoundUserProgress.questionSkipped ? (
                  <CheckCircleOutlined className={styles.tickIcon} />
                ) : (
                  <></>
                )}
              </div>
            </div>

            {currentRoundUserProgress.fullySolved ? (
              <div className={styles.buttonBox}>
                {overallGameProgress.roundProgressed === totalRoundCount ? (
                  gameMode === 1 ? (
                    // Quick play mode, to game summary
                    <Link
                      to="/quickplay/summary"
                      state={{
                        performanceData,
                      }}
                    >
                      <Button
                        type="primary"
                        size="large"
                        block={true}
                        className={styles.nextButton}
                      >
                        Complete
                      </Button>
                    </Link>
                  ) : (
                    // Classroom Mode
                    <Button
                      type="primary"
                      size="large"
                      block={true}
                      className={styles.nextButton}
                      onClick={() => {
                        onPerformanceDataUpdate(
                          { ...performanceData },
                          true,
                          true
                        );
                      }}
                    >
                      Complete
                    </Button>
                  )
                ) : (
                  <Button
                    type="primary"
                    size="large"
                    block={true}
                    className={styles.nextButton}
                    onClick={() =>
                      setOverallGameProgress((prevGameProgress) => ({
                        ...prevGameProgress,
                        currentRoundCount:
                          prevGameProgress.currentRoundCount + 1,
                      }))
                    }
                  >
                    Next
                  </Button>
                )}
              </div>
            ) : (
              <CharacterOption
                options={options}
                optionsTotalCount={optionsTotalCount}
              />
            )}
            <Row className={styles.progressBarBox}>
              <p className={styles.progressBarPreWord}>Speed:</p>
              <Progress
                percent={
                  (overallGameProgress.roundProgressed / totalRoundCount) * 100
                }
                steps={totalRoundCount}
                strokeColor={performanceData.progressBarColours}
                size={windowWidth <= 640 ? [20, 18] : [40, 18]}
                status="active"
                showInfo={false}
                className={styles.progressBar}
              />
            </Row>
          </div>
        </DragDropContext>
      )}
    </div>
  );
}

function DropBox({
  currentCharacterFiles,
  currentRoundUserProgress,
  characterStructure,
  correctOptionsInfoDictionary,
  setIsOuterDraggingOver,
  isOuterDraggingOver,
}) {
  let structureImage;
  switch (characterStructure) {
    case "LEFT_RIGHT":
      structureImage = leftRightStructure;
      break;
    case "ENCLOSE":
      structureImage = encloseStructure;
      break;
    case "HALF_BOTTOM_CENTER":
      structureImage = halfBottomCenterStructure;
      break;
    case "HALF_BOTTOM_LEFT":
      structureImage = halfBottomLeftStructure;
      break;
    case "HALF_BOTTOM_RIGHT":
      structureImage = halfBottomRightStructure;
      break;
    case "HALF_MID_RIGHT":
      structureImage = halfMidRightStructure;
      break;
    case "HALF_TOP_CENTER":
      structureImage = halfTopCenterStructure;
      break;
    case "HALF_TOP_RIGHT":
      structureImage = halfTopRightStructure;
      break;
    case "LEFT_CENTER_RIGHT":
      structureImage = leftCenterRightStructure;
      break;
    case "TOP_BOTTOM":
      structureImage = topBottomStructure;
      break;
    case "TOP_MID_BOTTOM":
      structureImage = topMidBottomStructure;
      break;
    default:
      console.log("Something has went wrong with structure selection.");
      structureImage = leftRightStructure; // Display left right as default since it is the most common structure
      break;
  }

  return (
    <div className={styles.dropBox}>
      <img
        src={structureImage}
        className={`${styles.stackedContent} ${styles.structureImage}`}
        alt="structure"
      />
      <Mask
        currentRoundUserProgress={currentRoundUserProgress}
        currentCharacterFiles={currentCharacterFiles}
      />
      <DropDivider
        characterStructure={characterStructure}
        setIsOuterDraggingOver={setIsOuterDraggingOver}
        isOuterDraggingOver={isOuterDraggingOver}
        isFullySolved={currentRoundUserProgress.fullySolved}
        correctOptionsInfoDictionary={correctOptionsInfoDictionary}
      />
    </div>
  );
}

function DropDivider({
  characterStructure,
  setIsOuterDraggingOver,
  isOuterDraggingOver,
  correctOptionsInfoDictionary,
  isFullySolved,
}) {
  return (
    <div className={styles.stackedContent}>
      <DropAreasCreation
        characterStructure={characterStructure}
        setIsOuterDraggingOver={setIsOuterDraggingOver}
        isOuterDraggingOver={isOuterDraggingOver}
        isFullySolved={isFullySolved}
        correctOptionsInfoDictionary={correctOptionsInfoDictionary}
      />
    </div>
  );
}

function DropAreasCreation({
  characterStructure,
  setIsOuterDraggingOver,
  isOuterDraggingOver,
  isFullySolved,
  correctOptionsInfoDictionary,
}) {
  switch (characterStructure) {
    case "LEFT_RIGHT":
      return (
        <div className={styles.hStack}>
          <DropArea
            correctOptionsInfoDictionary={correctOptionsInfoDictionary}
            isFullySolved={isFullySolved}
            setIsOuterDraggingOver={setIsOuterDraggingOver}
            isOuterDraggingOver={isOuterDraggingOver}
            droppableId="LEFT"
            areaStyles={{ width: "50%" }}
          />
          <DropArea
            correctOptionsInfoDictionary={correctOptionsInfoDictionary}
            isFullySolved={isFullySolved}
            setIsOuterDraggingOver={setIsOuterDraggingOver}
            isOuterDraggingOver={isOuterDraggingOver}
            droppableId="RIGHT"
            areaStyles={{ width: "50%" }}
          />
        </div>
      );
    case "ENCLOSE":
      return (
        <div className={styles.hStack}>
          <DropArea
            correctOptionsInfoDictionary={correctOptionsInfoDictionary}
            isFullySolved={isFullySolved}
            setIsOuterDraggingOver={setIsOuterDraggingOver}
            isOuterDraggingOver={isOuterDraggingOver}
            droppableId="OUTER1"
            areaStyles={{ width: "25%", height: "100%" }}
          />
          <div style={{ width: "50%", height: "100%" }}>
            <div className={styles.vStack}>
              <DropArea
                correctOptionsInfoDictionary={correctOptionsInfoDictionary}
                isFullySolved={isFullySolved}
                setIsOuterDraggingOver={setIsOuterDraggingOver}
                isOuterDraggingOver={isOuterDraggingOver}
                droppableId="OUTER2"
                areaStyles={{ height: "25%" }}
              />
              <DropArea
                correctOptionsInfoDictionary={correctOptionsInfoDictionary}
                isFullySolved={isFullySolved}
                setIsOuterDraggingOver={setIsOuterDraggingOver}
                isOuterDraggingOver={isOuterDraggingOver}
                droppableId="INNER"
                areaStyles={{ height: "50%" }}
              />
              <DropArea
                correctOptionsInfoDictionary={correctOptionsInfoDictionary}
                isFullySolved={isFullySolved}
                setIsOuterDraggingOver={setIsOuterDraggingOver}
                isOuterDraggingOver={isOuterDraggingOver}
                droppableId="OUTER3"
                areaStyles={{ height: "25%" }}
              />
            </div>
          </div>

          <DropArea
            correctOptionsInfoDictionary={correctOptionsInfoDictionary}
            isFullySolved={isFullySolved}
            setIsOuterDraggingOver={setIsOuterDraggingOver}
            isOuterDraggingOver={isOuterDraggingOver}
            droppableId="OUTER4"
            areaStyles={{ width: "25%", height: "100%" }}
          />
        </div>
      );
    case "HALF_BOTTOM_CENTER":
      return (
        <div className={styles.hStack}>
          <DropArea
            correctOptionsInfoDictionary={correctOptionsInfoDictionary}
            isFullySolved={isFullySolved}
            setIsOuterDraggingOver={setIsOuterDraggingOver}
            isOuterDraggingOver={isOuterDraggingOver}
            droppableId="OUTER1"
            areaStyles={{ width: "25%", height: "100%" }}
          />
          <div style={{ width: "50%", height: "100%" }}>
            <div className={styles.vStack}>
              <DropArea
                correctOptionsInfoDictionary={correctOptionsInfoDictionary}
                isFullySolved={isFullySolved}
                setIsOuterDraggingOver={setIsOuterDraggingOver}
                isOuterDraggingOver={isOuterDraggingOver}
                droppableId="OUTER2"
                areaStyles={{ height: "34.33333%" }}
              />
              <DropArea
                correctOptionsInfoDictionary={correctOptionsInfoDictionary}
                isFullySolved={isFullySolved}
                setIsOuterDraggingOver={setIsOuterDraggingOver}
                isOuterDraggingOver={isOuterDraggingOver}
                droppableId="INNER"
                areaStyles={{ height: "65.66666%" }}
              />
            </div>
          </div>
          <DropArea
            correctOptionsInfoDictionary={correctOptionsInfoDictionary}
            isFullySolved={isFullySolved}
            setIsOuterDraggingOver={setIsOuterDraggingOver}
            isOuterDraggingOver={isOuterDraggingOver}
            droppableId="OUTER3"
            areaStyles={{ width: "25%", height: "100%" }}
          />
        </div>
      );
    case "HALF_BOTTOM_LEFT":
      return (
        <div className={styles.hStack}>
          <div style={{ width: "66.66666%", height: "100%" }}>
            <div className={styles.vStack}>
              <DropArea
                correctOptionsInfoDictionary={correctOptionsInfoDictionary}
                isFullySolved={isFullySolved}
                setIsOuterDraggingOver={setIsOuterDraggingOver}
                isOuterDraggingOver={isOuterDraggingOver}
                droppableId="OUTER1"
                areaStyles={{ height: "34.33333%" }}
              />
              <DropArea
                correctOptionsInfoDictionary={correctOptionsInfoDictionary}
                isFullySolved={isFullySolved}
                setIsOuterDraggingOver={setIsOuterDraggingOver}
                isOuterDraggingOver={isOuterDraggingOver}
                droppableId="INNER"
                areaStyles={{ height: "65.66666%" }}
              />
            </div>
          </div>
          <div style={{ width: "33.33333%", height: "100%" }}>
            <div className={styles.vStack}>
              <DropArea
                correctOptionsInfoDictionary={correctOptionsInfoDictionary}
                isFullySolved={isFullySolved}
                setIsOuterDraggingOver={setIsOuterDraggingOver}
                isOuterDraggingOver={isOuterDraggingOver}
                droppableId="OUTER2"
                areaStyles={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
        </div>
      );
    case "HALF_BOTTOM_RIGHT":
      return (
        <div className={styles.hStack}>
          <div style={{ width: "34.33333%", height: "100%" }}>
            <div className={styles.vStack}>
              <DropArea
                correctOptionsInfoDictionary={correctOptionsInfoDictionary}
                isFullySolved={isFullySolved}
                setIsOuterDraggingOver={setIsOuterDraggingOver}
                isOuterDraggingOver={isOuterDraggingOver}
                droppableId="OUTER1"
                areaStyles={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
          <div style={{ width: "65.66666%", height: "100%" }}>
            <div className={styles.vStack}>
              <DropArea
                correctOptionsInfoDictionary={correctOptionsInfoDictionary}
                isFullySolved={isFullySolved}
                setIsOuterDraggingOver={setIsOuterDraggingOver}
                isOuterDraggingOver={isOuterDraggingOver}
                droppableId="OUTER2"
                areaStyles={{ height: "34.33333%" }}
              />
              <DropArea
                correctOptionsInfoDictionary={correctOptionsInfoDictionary}
                isFullySolved={isFullySolved}
                setIsOuterDraggingOver={setIsOuterDraggingOver}
                isOuterDraggingOver={isOuterDraggingOver}
                droppableId="INNER"
                areaStyles={{ height: "65.66666%" }}
              />
            </div>
          </div>
        </div>
      );
    case "HALF_MID_RIGHT":
      return (
        <div className={styles.hStack}>
          <div style={{ width: "33.33333%", height: "100%" }}>
            <div className={styles.vStack}>
              <DropArea
                correctOptionsInfoDictionary={correctOptionsInfoDictionary}
                isFullySolved={isFullySolved}
                setIsOuterDraggingOver={setIsOuterDraggingOver}
                isOuterDraggingOver={isOuterDraggingOver}
                droppableId="OUTER1"
                areaStyles={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
          <div style={{ width: "66.66666%", height: "100%" }}>
            <div className={styles.vStack}>
              <DropArea
                correctOptionsInfoDictionary={correctOptionsInfoDictionary}
                isFullySolved={isFullySolved}
                setIsOuterDraggingOver={setIsOuterDraggingOver}
                isOuterDraggingOver={isOuterDraggingOver}
                droppableId="OUTER2"
                areaStyles={{ height: "26%" }}
              />
              <DropArea
                correctOptionsInfoDictionary={correctOptionsInfoDictionary}
                isFullySolved={isFullySolved}
                setIsOuterDraggingOver={setIsOuterDraggingOver}
                isOuterDraggingOver={isOuterDraggingOver}
                droppableId="INNER"
                areaStyles={{ height: "48%" }}
              />
              <DropArea
                correctOptionsInfoDictionary={correctOptionsInfoDictionary}
                isFullySolved={isFullySolved}
                setIsOuterDraggingOver={setIsOuterDraggingOver}
                isOuterDraggingOver={isOuterDraggingOver}
                droppableId="OUTER3"
                areaStyles={{ height: "26%" }}
              />
            </div>
          </div>
        </div>
      );
    case "HALF_TOP_CENTER":
      return (
        <div className={styles.hStack}>
          <DropArea
            correctOptionsInfoDictionary={correctOptionsInfoDictionary}
            isFullySolved={isFullySolved}
            setIsOuterDraggingOver={setIsOuterDraggingOver}
            isOuterDraggingOver={isOuterDraggingOver}
            droppableId="OUTER1"
            areaStyles={{ width: "25%", height: "100%" }}
          />
          <div style={{ width: "50%", height: "100%" }}>
            <div className={styles.vStack}>
              <DropArea
                correctOptionsInfoDictionary={correctOptionsInfoDictionary}
                isFullySolved={isFullySolved}
                setIsOuterDraggingOver={setIsOuterDraggingOver}
                isOuterDraggingOver={isOuterDraggingOver}
                droppableId="INNER"
                areaStyles={{ height: "65.66666%" }}
              />
              <DropArea
                correctOptionsInfoDictionary={correctOptionsInfoDictionary}
                isFullySolved={isFullySolved}
                setIsOuterDraggingOver={setIsOuterDraggingOver}
                isOuterDraggingOver={isOuterDraggingOver}
                droppableId="OUTER2"
                areaStyles={{ height: "34.33333%" }}
              />
            </div>
          </div>
          <DropArea
            correctOptionsInfoDictionary={correctOptionsInfoDictionary}
            isFullySolved={isFullySolved}
            setIsOuterDraggingOver={setIsOuterDraggingOver}
            isOuterDraggingOver={isOuterDraggingOver}
            droppableId="OUTER3"
            areaStyles={{ width: "25%", height: "100%" }}
          />
        </div>
      );
    case "HALF_TOP_RIGHT":
      return (
        <div className={styles.hStack}>
          <div style={{ width: "33.33333%", height: "100%" }}>
            <div className={styles.vStack}>
              <DropArea
                correctOptionsInfoDictionary={correctOptionsInfoDictionary}
                isFullySolved={isFullySolved}
                setIsOuterDraggingOver={setIsOuterDraggingOver}
                isOuterDraggingOver={isOuterDraggingOver}
                droppableId="OUTER1"
                areaStyles={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
          <div style={{ width: "66.66666%", height: "100%" }}>
            <div className={styles.vStack}>
              <DropArea
                correctOptionsInfoDictionary={correctOptionsInfoDictionary}
                isFullySolved={isFullySolved}
                setIsOuterDraggingOver={setIsOuterDraggingOver}
                isOuterDraggingOver={isOuterDraggingOver}
                droppableId="INNER"
                areaStyles={{ height: "65.66666%" }}
              />
              <DropArea
                correctOptionsInfoDictionary={correctOptionsInfoDictionary}
                isFullySolved={isFullySolved}
                setIsOuterDraggingOver={setIsOuterDraggingOver}
                isOuterDraggingOver={isOuterDraggingOver}
                droppableId="OUTER2"
                areaStyles={{ height: "34.33333%" }}
              />
            </div>
          </div>
        </div>
      );
    case "LEFT_CENTER_RIGHT":
      return (
        <div className={styles.hStack}>
          <DropArea
            correctOptionsInfoDictionary={correctOptionsInfoDictionary}
            isFullySolved={isFullySolved}
            setIsOuterDraggingOver={setIsOuterDraggingOver}
            isOuterDraggingOver={isOuterDraggingOver}
            droppableId="LEFT"
            areaStyles={{ width: "33.33333%" }}
          />
          <DropArea
            correctOptionsInfoDictionary={correctOptionsInfoDictionary}
            isFullySolved={isFullySolved}
            setIsOuterDraggingOver={setIsOuterDraggingOver}
            isOuterDraggingOver={isOuterDraggingOver}
            droppableId="MID"
            areaStyles={{ width: "33.33333%" }}
          />
          <DropArea
            correctOptionsInfoDictionary={correctOptionsInfoDictionary}
            isFullySolved={isFullySolved}
            setIsOuterDraggingOver={setIsOuterDraggingOver}
            isOuterDraggingOver={isOuterDraggingOver}
            droppableId="RIGHT"
            areaStyles={{ width: "33.33333%" }}
          />
        </div>
      );
    case "TOP_BOTTOM":
      return (
        <div className={styles.vStack}>
          <DropArea
            correctOptionsInfoDictionary={correctOptionsInfoDictionary}
            isFullySolved={isFullySolved}
            setIsOuterDraggingOver={setIsOuterDraggingOver}
            isOuterDraggingOver={isOuterDraggingOver}
            droppableId="TOP"
            areaStyles={{ height: "50%" }}
          />
          <DropArea
            correctOptionsInfoDictionary={correctOptionsInfoDictionary}
            isFullySolved={isFullySolved}
            setIsOuterDraggingOver={setIsOuterDraggingOver}
            isOuterDraggingOver={isOuterDraggingOver}
            droppableId="BOTTOM"
            areaStyles={{ height: "50%" }}
          />
        </div>
      );
    case "TOP_MID_BOTTOM":
      return (
        <div className={styles.vStack}>
          <DropArea
            correctOptionsInfoDictionary={correctOptionsInfoDictionary}
            isFullySolved={isFullySolved}
            setIsOuterDraggingOver={setIsOuterDraggingOver}
            isOuterDraggingOver={isOuterDraggingOver}
            droppableId="TOP"
            areaStyles={{ height: "33.33333%" }}
          />
          <DropArea
            correctOptionsInfoDictionary={correctOptionsInfoDictionary}
            isFullySolved={isFullySolved}
            setIsOuterDraggingOver={setIsOuterDraggingOver}
            isOuterDraggingOver={isOuterDraggingOver}
            droppableId="MID"
            areaStyles={{ height: "33.33333%" }}
          />
          <DropArea
            correctOptionsInfoDictionary={correctOptionsInfoDictionary}
            isFullySolved={isFullySolved}
            setIsOuterDraggingOver={setIsOuterDraggingOver}
            isOuterDraggingOver={isOuterDraggingOver}
            droppableId="BOTTOM"
            areaStyles={{ height: "33.33333%" }}
          />
        </div>
      );
    default:
      break;
  }
  return <div></div>;
}

function DropArea({
  correctOptionsInfoDictionary,
  droppableId,
  areaStyles,
  setIsOuterDraggingOver,
  isOuterDraggingOver,
  isFullySolved,
}) {
  const isOuter = droppableId.startsWith("OUTER");
  return (
    <Droppable droppableId={droppableId}>
      {(provided, snapshot) => (
        <DropBar
          provided={provided}
          snapshot={snapshot}
          isOuter={isOuter}
          areaStyles={areaStyles}
          isFullySolved={isFullySolved}
          correctOptionsInfoDictionary={correctOptionsInfoDictionary}
          droppableId={droppableId}
          setIsOuterDraggingOver={setIsOuterDraggingOver}
          isOuterDraggingOver={isOuterDraggingOver}
        />
      )}
    </Droppable>
  );
}

function DropBar({
  provided,
  snapshot,
  isOuter,
  areaStyles,
  setIsOuterDraggingOver,
  isOuterDraggingOver,
  correctOptionsInfoDictionary,
  droppableId,
  isFullySolved,
}) {
  let showOptionInfo = false;
  let optionInfo = "";
  let optionOnBottomRight = false;

  if (droppableId === "OUTER1" && correctOptionsInfoDictionary["OUTER"]) {
    showOptionInfo = true;
    optionInfo = correctOptionsInfoDictionary["OUTER"];
  }

  if (correctOptionsInfoDictionary[droppableId]) {
    showOptionInfo = true;
    optionInfo = correctOptionsInfoDictionary[droppableId];
    if (droppableId === "INNER") {
      optionOnBottomRight = true;
    }
  }

  useEffect(() => {
    if (isOuter) {
      setIsOuterDraggingOver(snapshot.isDraggingOver);
    }
  }, [snapshot.isDraggingOver]);

  return (
    <div
      {...provided.droppableProps}
      ref={provided.innerRef}
      style={areaStyles}
      className={`${styles.dropBar} ${
        snapshot.isDraggingOver ? styles.dropBarOnDragOver : ""
      } ${isOuter && isOuterDraggingOver ? styles.outerOnDragOver : ""}`}
    >
      {showOptionInfo && isFullySolved ? (
        <OptionInfo
          optionInfo={optionInfo}
          optionOnBottomRight={optionOnBottomRight}
        />
      ) : (
        <></>
      )}
      {provided.placeholder}
    </div>
  );
}

function OptionInfo({ optionInfo, optionOnBottomRight }) {
  const [isModalOpen, setModalOpen] = useState(false);
  return (
    <>
      <Modal
        open={isModalOpen}
        title="Information"
        footer={null}
        open={isModalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        centered
      >
        <p>{optionInfo}</p>
      </Modal>
      <Button
        shape="circle"
        icon={<InfoCircleOutlined />}
        onClick={() => setModalOpen(true)}
        size={"small"}
        className={`${
          optionOnBottomRight
            ? styles.infoButtonOnBottomRight
            : styles.infoButton
        }`}
      />
    </>
  );
}

function Mask({ currentCharacterFiles, currentRoundUserProgress }) {
  return (
    <div>
      {currentRoundUserProgress.progressMade ? (
        <img
          src={currentCharacterFiles.characterImagePath}
          className={`${styles.stackedContent} ${styles.characterAndMaskStack}`}
          alt="character"
        />
      ) : (
        <></>
      )}
      {currentRoundUserProgress.mask1Solved ? (
        <></>
      ) : (
        <img
          src={currentCharacterFiles.mask1ImagePath}
          className={`${styles.stackedContent} ${styles.characterAndMaskStack}`}
          alt="mask1"
        />
      )}
      {currentRoundUserProgress.mask2Solved ? (
        <></>
      ) : (
        <img
          src={currentCharacterFiles.mask2ImagePath}
          className={`${styles.stackedContent} ${styles.characterAndMaskStack}`}
          alt="mask2"
        />
      )}
      {currentRoundUserProgress.mask3Solved ? (
        <></>
      ) : (
        <img
          src={currentCharacterFiles.mask3ImagePath}
          className={`${styles.stackedContent} ${styles.characterAndMaskStack}`}
          alt="mask3"
        />
      )}
    </div>
  );
}

function CharacterOption({ options, optionsTotalCount }) {
  return (
    <div className={styles.characterOptionsBox}>
      {options.map((option, index) => (
        <div className={styles.inline} key={index}>
          <Droppable
            droppableId={`optionsBox${index + 1}`}
            isDropDisabled={true}
          >
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                <Draggable
                  key={option.id}
                  draggableId={option.id}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={`${
                        optionsTotalCount === 6
                          ? styles.smallOptionCell
                          : styles.optionCell
                      } 
                          ${
                            snapshot.isDragging ? styles.optionCellOnDrag : ""
                          }`}
                    >
                      <img
                        src={option.optionImagePath}
                        className={styles.optionImage}
                        alt={`Option ${index + 1}`}
                      />
                    </div>
                  )}
                </Draggable>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      ))}
    </div>
  );
}

export default SrsSessionComponent;

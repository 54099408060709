import PageLayout from "./PageLayout";
import DragAndDropMatchGame from "../components/DragAndDropMatchGame";
import { useState } from "react";

const QuickPlayMatchGamePage = () => {
  const [showHeader, setShowHeader] = useState(false);
  const body = (
    <div>
      <DragAndDropMatchGame setShowHeader={setShowHeader} />
    </div>
  );

  return (
    <PageLayout
      showDownloadHeader={showHeader}
      body={body}
      displayMode="overlay"
    />
  );
};

export default QuickPlayMatchGamePage;

import React from "react";
import Lottie from "react-lottie";
import * as animationData from "../../assets/animation/StarAnimation.json";

const StarAnimation = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      <Lottie
        options={defaultOptions}
        height={75}
        width={75}
        isClickToPauseDisabled
      />
    </div>
  );
};

export default StarAnimation;

import { Button, Col, Collapse, ConfigProvider, Image, Row } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getErrorCode, getErrorMessage } from "../../apiHandlers/apiUtils";
import { initiateNewAssignedSession } from "../../apiHandlers/classroomTestSessionApiHandler";
import { useAuth } from "../../contexts/AuthContext";
import { showOrUpdateExistingErrorToast } from "../../utils/toastUtils";
import styles from "./ClassroomTestItem.module.scss";
import { openClassroomTestSessionShareModal } from "./ClassroomTestSessionShareModal";

const TEST_TO_ASSIGN_NOT_FOUND_ERROR_MSG = "Oops! We couldn't find this test. It may have been deleted. Please use another test.";

function ClassroomTestItem({ test, onEditClick, onDeleteClick }) {
  const [assignLoading, setAssignLoading] = useState(false);

  const { user } = useAuth();

  const handleEditClick = () => {
    onEditClick(test);
  };

  const handleDeleteClick = () => {
    onDeleteClick(test);
  };

  const handleAssignClick = async () => {
    setAssignLoading(true);
    setTimeout(async () => {
      try {
        const sessionInfo = await initiateNewAssignedSession(test.id);
        toast.success("Assigned session created successfully!", { autoClose: 2000 });
        setTimeout(() => {
          openClassroomTestSessionShareModal(sessionInfo);
          setAssignLoading(false);
        }, 400);
      } catch (err) {
        const toastId = "assign-test-error-toast";
        const errorCode = getErrorCode(err);
        if (errorCode === 404) {
          showOrUpdateExistingErrorToast(toastId, TEST_TO_ASSIGN_NOT_FOUND_ERROR_MSG, { autoClose: 5000 });
        } else {
          showOrUpdateExistingErrorToast(toastId, getErrorMessage(err));
        }
        setAssignLoading(false);
      }
      // This timeout is completely optional
    }, 500);
  };

  return (
    <div className={styles["test-box"]}>
      <Row align="middle">
        <Col span={6}>
          <div className={styles["test-name"]}>{test.name}</div>
          <div className={styles["test-created"]}>Creation: {test.createdAt}</div>
          <div className={styles["test-last-modified"]}>Last Edit: {test.lastModifiedAt}</div>
        </Col>
        <Col span={12}>
          <div className={styles["character-container"]}>
            {
              test.characters.map((character) => {
                return (
                  <span key={character.id} className={styles.character}>{character.chinese}</span>
                );
              })
            }
          </div>
        </Col>
        <Col span={6}>
          <Row justify="space-evenly" align="middle">
            <Col xs={24} lg={10}>
              <div className={styles["btn-control"]}>
                <ConfigProvider theme={{
                  components: {
                    Button: {
                      colorPrimary: "#02924D",
                      contentFontSizeLG: 21,
                      fontWeight: "500",
                      paddingInlineLG: 0,
                      algorithm: true,
                    },
                  },
                }}>
                  <Link to={`/classroom/lobby?testId=${test.id}`} target="_blank" className={styles["start-link"]}>
                    <Button type="primary" block size="large">
                      Start
                    </Button>
                  </Link>
                </ConfigProvider>
              </div>
              <div className={styles["btn-control"]}>
                <ConfigProvider theme={{
                  components: {
                    Button: {
                      // colorPrimary: "#02924D",
                      contentFontSize: 17,
                      fontWeight: "500",
                      paddingInline: 0,
                      algorithm: true,
                    },
                  },
                }}>
                  <Button type="default" block size="middle" loading={assignLoading} onClick={handleAssignClick}>
                    {assignLoading ? "" : "Assign"}
                  </Button>
                </ConfigProvider>
              </div>
            </Col>
            <Col xs={24} lg={10}>
              <div className={styles["btn-control"]}>
                <ConfigProvider theme={{
                  components: {
                    Button: {
                      colorPrimary: "#004AAD",
                      contentFontSize: 17,
                      fontWeight: "500",
                      paddingInline: 0,
                      algorithm: true,
                    },
                  },
                }}>
                  <Button type="primary" block size="middle" onClick={handleEditClick}>Edit</Button>
                </ConfigProvider>
              </div>
              <div className={styles["btn-control"]}>
                <ConfigProvider theme={{
                  components: {
                    Button: {
                      colorPrimary: "#FF3131",
                      contentFontSize: 17,
                      fontWeight: "500",
                      paddingInline: 0,
                      algorithm: true,
                    },
                  },
                }}>
                  <Button type="primary" block size="middle" onClick={handleDeleteClick}>Delete</Button>
                </ConfigProvider>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Collapse className={styles["collapse"]}
  items={[
    {
      key: "1",
      label: "Selected Images",
      children: (
        <div className={styles.imageContainer}>
          {!test.imageUrls || !test.characters ? (
            <p>Loading...</p>
          ) : (
            test.imageUrls.map((imageUrl, index) => (
              <div key={index} className={styles.imageWrapper}>
                <div className={styles.characterSmall}>
                  {test.characters[index]?.chinese || "?"}
                </div>
                {imageUrl ? (
                  <Image
                    src={imageUrl}
                    width={80}
                    height={80}
                    preview={true}
                    className={styles.image}
                  />
                ) : (
                  <div className={styles.noImagePlaceholder}>No Image</div>
                )}
              </div>
            ))
          )}
        </div>
      ),
    },
  ]}
/>
    </div>
  );
}

export default ClassroomTestItem;

import { api } from "./api";

const QUICKPLAY_SESSION_ENDPOINT = "/web/quick_play/get_session";
const QUICKPLAY_ADD_STATISTICS_ENDPOINT = "/web/quick_play/add_statistics";
const QUICKPLAY_UPDATE_STATISTICS_ENDPOINT =
  "/web/quick_play/update_click_statistics";

const SRS_SESSION_ENDPOINT = "/web/quick_play/get_srs_session";

export const getQuickPlaySession = (diff_level) => {
  // console.log("diff_level:", diff_level);
  return api.get(`${QUICKPLAY_SESSION_ENDPOINT}?diff_level=${diff_level}`);
};

export const addQuickPlayStatistics = (time_taken) => {
  return api.get(
    `${QUICKPLAY_ADD_STATISTICS_ENDPOINT}?time_taken=${time_taken}`
  );
};

export const updateQuickPlayStatistics = (parameter, uuid) => {
  return api.get(
    `${QUICKPLAY_UPDATE_STATISTICS_ENDPOINT}?parameter=${parameter}&uuid=${uuid}`
  );
};

export const getSrsSession = (user_id) => {
  return api.get(`${SRS_SESSION_ENDPOINT}?user_id=${user_id}`);
};

export const addSrsSession = (user_id, character_id, recall_attempt) => {
  return api.post("/web/research/add_review", {
    user_id,
    character_id,
    recall_attempt, // Send data in the body as a JSON object
  });
};

import { Spin } from "antd";
import React, { useState } from "react";
import { scaffoldingPresetData } from "../../utils/gameData";
import DragAndDropGame from "../components/DragAndDropGame";
import PageLayout from "./PageLayout";
import styles from "./QuickPlayGamePage.module.scss";

const QuickPlayGameTestPage = () => {
  const [gameData, setGameData] = useState(scaffoldingPresetData.data);

  //   useEffect(() => {
  //     async function fetchData() {
  //       const diffLevel = -1;
  //       try {
  //         const data = await getQuickPlaySession(diffLevel);
  //         // console.log("data", data);
  //         setGameData(data);
  //       } catch (error) {
  //         console.error("Error fetching data:", error);
  //       }
  //     }

  //     fetchData();
  //   }, []);

  const body = (
    <div className={styles.gameMenu}>
      {gameData === null ? (
        <div className={styles.loading}>
          <Spin size="large" />
        </div>
      ) : (
        <DragAndDropGame
          gameContent={gameData}
          maxRoundCount={15}
          gameMode={4}
        />
      )}
    </div>
  );

  return <PageLayout body={body} displayMode={"overlay"} />;
};

export default QuickPlayGameTestPage;

import {
  CheckOutlined,
  EllipsisOutlined,
  MessageOutlined,
  QuestionCircleOutlined,
  ReloadOutlined,
  ShareAltOutlined,
  SoundFilled,
  WechatFilled,
} from "@ant-design/icons";
import {
  Button,
  Divider,
  FloatButton,
  Form,
  Input,
  message,
  Modal,
  Popover,
  Progress,
  Radio,
  Tour,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { ProgressBar } from "react-progressbar-fancy";
import {
  TelegramIcon,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from "react-share";
import { api } from "../../apiHandlers/api.js";
import {
  submitMatchItAttempt,
  submitMatchItFeedback,
} from "../../apiHandlers/matchitApiHandler.js";
import TrophyAnimation from "../../assets/animation/win.gif";
import nusLogos from "../../assets/nus_logos.png";
import encloseStructure from "../../assets/structure_svg/ENCLOSE.png";
import halfBottomCenterStructure from "../../assets/structure_svg/HALF_BOTTOM_CENTER.png";
import halfBottomLeftStructure from "../../assets/structure_svg/HALF_BOTTOM_LEFT.png";
import halfBottomRightStructure from "../../assets/structure_svg/HALF_BOTTOM_RIGHT.png";
import halfMidRightStructure from "../../assets/structure_svg/HALF_MID_RIGHT.png";
import halfTopCenterStructure from "../../assets/structure_svg/HALF_TOP_CENTER.png";
import halfTopRightStructure from "../../assets/structure_svg/HALF_TOP_RIGHT.png";
import leftCenterRightStructure from "../../assets/structure_svg/LEFT_CENTER_RIGHT.png";
import leftRightStructure from "../../assets/structure_svg/LEFT_RIGHT.png";
import topBottomStructure from "../../assets/structure_svg/TOP_BOTTOM.png";
import topMidBottomStructure from "../../assets/structure_svg/TOP_MID_BOTTOM.png";
import { CCL_ASSETS_PATH } from "../../constants/api";
import CorrectAnimation from "./CorrectAnimation.js";
import styles from "./DragAndDropMatchGame.module.scss";
import StarAnimation from "./StarAnimation.js";
import TimesUpAnimation from "./TimesUpAnimation.js";
import WrongAnimation from "./WrongAnimation.js";
import TextArea from "antd/es/input/TextArea.js";

const correctOptionSoundEffect = new Audio(CCL_ASSETS_PATH + "audio/right.mp3");
const wrongOptionSoundEffect = new Audio(CCL_ASSETS_PATH + "audio/wrong.mp3");

function DragAndDropMatchGame({ setShowHeader }) {
  // States for datetime
  const [dateNow, setDateNow] = useState(null);

  // States for timer
  const [timeLeft, setTimeLeft] = useState(1); // Initial time in seconds
  const [strokeColor, setStrokeColor] = useState("#52c41a"); // Initial color (green)
  const [paused, setPaused] = useState(
    localStorage.getItem("openWalkthrough") === "false" ? false : true
  ); // Track pause state

  // States for possible characters
  const [possibleCharacters, setPossibleCharacters] = useState([]); // Store all the characters that can be formed
  const [formedCharacters, setFormedCharacters] = useState([]); // Store the characters that user has formed

  // State for when a character that has been formed is selected again
  const [duplicateCharacter, setDuplicateCharacter] = useState(null);

  // States for selected radical options
  const [selectedOptions, setSelectedOptions] = useState([]);

  // States for randomly generated radicals
  const [options, setOptions] = useState([]);

  // States for each character modal
  const [characterFormed, setCharacterFormed] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [resolveModalClosure, setResolveModalClosure] = useState(null);

  // States for meaning modal
  const [meaningCharacter, setMeaningCharacter] = useState(null);
  const [isMeaningModalVisible, setIsMeaningModalVisible] = useState(false);

  // States for ending summary modal
  const [isSharingModalVisible, setIsSharingModalVisible] = useState(false);

  // States for survey modal
  const [isSurveyModalVisible, setIsSurveyModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  // State for freezing the game when it ends (either time runs out or completed all options)
  const [isFreeze, setIsFreeze] = useState(false);

  // State for width size
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // States for notification message
  const [messageApi, contextHolder] = message.useMessage();

  // States for animation
  const [showCorrectAnimation, setShowCorrectAnimation] = useState(false);
  const [showWrongAnimation, setShowWrongAnimation] = useState(false);
  const [showTimesUpAnimation, setShowTimesUpAnimation] = useState(false);

  // States for copying sharing content
  const [copied, setCopied] = useState(false);

  const milestones = [25, 50, 75, 100];

  // States for floatbutton
  const [floatButtonOpen, setFloatButtonOpen] = useState(
    localStorage.getItem("openWalkthrough") === "false" ? false : true
  );

  // States for tour
  const [open, setOpen] = useState(
    localStorage.getItem("openWalkthrough") === "false" ? false : true
  );
  const refMinus1 = useRef(null);
  const ref0 = useRef(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);

  const steps = [
    {
      title: "MatchIt! Walkthrough",
      description:
        "Welcome to MatchIt! If you are new, let's learn the rules together :)",
      target: () => refMinus1.current,
    },
    {
      title: "Instructions",
      description: "Click here to access the rules and walkthrough",
      target: () => ref0.current,
    },
    {
      title: "Possible Characters",
      description:
        "The greyed out boxes contain Chinese Characters that can be formed",
      target: () => ref1.current,
    },
    {
      title: "Radical Fragments",
      description:
        "Select any 2 possible radicals to form a valid Chinese Character",
      target: () => ref2.current,
    },
    {
      title: "Selection Area",
      description:
        "You may view the selected options in this area and remove any unwanted options by clicking on the red cross",
      target: () => ref3.current,
    },
    {
      title: "Progress Bar & Timer",
      description:
        "As you form more Chinese Characters, the progress bar will fill up. However, there is a timer counting down! Best of luck XD",
      target: () => ref4.current,
    },
  ];

  // States for ending tour
  const [openEndingTour, setOpenEndingTour] = useState(false);

  const endRef0 = useRef(null);
  const endRef1 = useRef(null);
  const endRef2 = useRef(null);

  const endingSteps = [
    {
      title: "Review",
      description: "Click to learn more about the character details",
      target: () => endRef0.current,
    },
    {
      title: "Share",
      description: "Check your score and share",
      target: () => endRef1.current,
    },
    {
      title: "Feedback",
      description: "Give us your feedback!",
      target: () => endRef2.current,
    },
  ];

  // Shuffle function for Fisher-Yates Shuffle
  const shuffle = (array) => {
    // Iterate over the array in reverse order
    for (let i = array.length - 1; i > 0; i--) {
      // Generate Random Index
      const j = Math.floor(Math.random() * (i + 1));

      // Swap elements
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const getDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear().toString().slice(-2);
    const formattedDate = day + "/" + month + "/" + year;
    return formattedDate;
  };

  const success = (message) => {
    messageApi.open({
      type: "success",
      content: message,
    });
  };

  const error = (message) => {
    messageApi.open({
      type: "error",
      content: message,
    });
  };

  const warning = (message) => {
    messageApi.open({
      type: "warning",
      content: message,
    });
  };

  // Setup event listeners for window is resized
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Fetch the options and possible characters on page load
  useEffect(() => {
    fetchOptions();
  }, []);

  useEffect(() => {
    if (paused) return; // Do nothing if paused

    const timerInterval = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(timerInterval);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timerInterval); // Cleanup on unmount
  }, [paused]);

  useEffect(() => {
    // Update the stroke color based on time left
    if (timeLeft > 30) {
      setStrokeColor("#52c41a"); // Green
    } else if (timeLeft > 10) {
      setStrokeColor("#faad14"); // Orange
    } else {
      setStrokeColor("#ff4d4f"); // Red
      if (timeLeft === 0) {
        setSelectedOptions([]);
        setShowTimesUpAnimation(true);
        setIsFreeze(true);
        setDateNow(getDate());
        setFloatButtonOpen(true);
        setShowHeader(true);

        setTimeout(() => {
          setShowTimesUpAnimation(false);
          setOpenEndingTour(true);
          setIsSurveyModalVisible(true);
        }, 2500);

        handleTimesUp();
      }

      return () => setShowHeader(false);
    }
  }, [timeLeft]);

  const handleTimesUp = async () => {
    try {
      await submitMatchItAttempt({
        score: formedCharacters.length,
        total_score: possibleCharacters.length,
        time_taken: possibleCharacters.length * 10 - timeLeft,
      });
    } catch (error) {
      console.error("Error submitting attempt:", error);
    }
  };

  // Checks if the current combination is a possible character
  const checkCombination = async () => {
    try {
      const response = await api.post("/web/matchit/check_word", {
        option1: selectedOptions[0],
        option2: selectedOptions[1],
      });

      if (response.length > 0) {
        for (let i = 0; i < response.length; i++) {
          const data = response[i];

          if (formedCharacters.includes(data.character_unicode)) {
            setDuplicateCharacter(data.character_unicode);
            setTimeout(() => {
              setDuplicateCharacter(null);
            }, 2000);
            continue;
          } else {
            if (formedCharacters.length + 1 === possibleCharacters.length) {
              setPaused(true);
              setDateNow(getDate());
              setFloatButtonOpen(true);
              setShowHeader(true);
              await handleTimesUp();
            }

            setShowCorrectAnimation(true);
            setCharacterFormed(data);
            setTimeout(() => {
              setIsModalVisible(true);
              setShowCorrectAnimation(false);
            }, 2000);

            await delay(2000);

            await showModalAndWaitForClose();
          }
        }
      } else {
        setShowWrongAnimation(true);
      }
    } catch (err) {
      setShowWrongAnimation(true);
    }
  };

  useEffect(() => {
    if (showWrongAnimation) {
      setTimeout(() => {
        setShowWrongAnimation(false);
        setSelectedOptions([]);
      }, 2000);
    }
  }, [showWrongAnimation]);

  useEffect(() => {
    if (selectedOptions.length === 2) {
      checkCombination();
    }
  }, [selectedOptions]);

  /**
   * Fetch & set the options, replace duplicates and all possible characters that can be formed with the selected options
   */
  const fetchOptions = async () => {
    // Retrieve 5 random words -> split into 10 radicals
    const data = await api.get("/web/matchit/get_words");
    const formattedOptions = []; // Stores the formatted options
    const optionUnicodeSet = new Set(); // Keep track of duplicate options

    for (let i = 0; i < data.length; i++) {
      const option = data[i];
      if (!optionUnicodeSet.has(option.option_unicode)) {
        formattedOptions.push({
          id: `option-${i}`,
          optionImagePath: `${CCL_ASSETS_PATH}${option.option_svg_path}`,
          optionPosition: option.position,
          fragmentOptionId: option.id,
          optionUnicode: option.option_unicode,
        });
        optionUnicodeSet.add(option.option_unicode);
      }
    }

    // If there exist duplicate and the options are less than 10, we find replacement for the duplicated options
    if (optionUnicodeSet.size < 10) {
      const replacement = await api.post(
        "/web/matchit/get_options_to_replace",
        {
          limit: 10 - optionUnicodeSet.size,
          unicodes: Array.from(optionUnicodeSet),
        }
      );

      for (let i = 0; i < replacement.length; i++) {
        if (optionUnicodeSet.size === 10) {
          break;
        }
        const option = replacement[i];
        formattedOptions.push({
          id: `option-${i}`,
          optionImagePath: `${CCL_ASSETS_PATH}${option.option_svg_path}`,
          optionPosition: option.position,
          fragmentOptionId: option.id,
          optionUnicode: option.option_unicode,
        });
        optionUnicodeSet.add(option.option_unicode);
      }
    }

    // Shuffle the radical options
    shuffle(formattedOptions);
    setOptions(formattedOptions);

    // Retrieve the characters that can be formed with the radical list
    const possibleCharacters = await api.post(
      "/web/matchit/get_possible_combinations",
      {
        options: Array.from(optionUnicodeSet),
      }
    );

    setPossibleCharacters(possibleCharacters);
    setTimeLeft(10 * possibleCharacters.length);
  };

  // Handle button click for selecting options
  const handleOptionClick = (optionUnicode) => {
    if (selectedOptions.length === 2) {
      return;
    }

    setSelectedOptions((prev) => {
      if (prev.includes(optionUnicode)) {
        // If already selected, remove it
        return prev.filter((unicode) => unicode !== optionUnicode);
      } else {
        // If not selected, add it
        return [...prev, optionUnicode];
      }
    });
  };

  // Handle undoing a selection
  const handleUndoSelection = (optionUnicode) => {
    setSelectedOptions((prev) =>
      prev.filter((unicode) => unicode !== optionUnicode)
    );
  };

  // Handle closing the Word Formed Modal
  const handleModalClose = () => {
    if (paused) {
      setIsFreeze(true);
      setShowTimesUpAnimation(true);
      setTimeout(() => {
        setShowTimesUpAnimation(false);
        setOpenEndingTour(true);
        setIsSurveyModalVisible(true);
      }, 2500);
    }

    setSelectedOptions([]);
    setIsModalVisible(false);
    setFormedCharacters((prev) => {
      const newCharacters = new Set(prev);
      newCharacters.add(characterFormed.character_unicode);
      return [...newCharacters];
    });
    if (resolveModalClosure) {
      resolveModalClosure(); // Resolve the promise to continue the loop
      setResolveModalClosure(null);
    }
  };

  const showModalAndWaitForClose = () => {
    return new Promise((resolve) => {
      setResolveModalClosure(() => resolve); // Save the resolve function for later
      setIsModalVisible(true); // Show the modal
    });
  };

  const delay = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  // Handle closing the Meaning Modal
  const handleMeaningModalClose = () => {
    setIsMeaningModalVisible(false);
  };

  // Handle finding the correct structure image
  const getStructure = (characterStructure) => {
    switch (characterStructure) {
      case "LEFT_RIGHT":
        return leftRightStructure;
      case "ENCLOSE":
        return encloseStructure;
      case "HALF_BOTTOM_CENTER":
        return halfBottomCenterStructure;
      case "HALF_BOTTOM_LEFT":
        return halfBottomLeftStructure;
      case "HALF_BOTTOM_RIGHT":
        return halfBottomRightStructure;
      case "HALF_MID_RIGHT":
        return halfMidRightStructure;
      case "HALF_TOP_CENTER":
        return halfTopCenterStructure;
      case "HALF_TOP_RIGHT":
        return halfTopRightStructure;
      case "LEFT_CENTER_RIGHT":
        return leftCenterRightStructure;
      case "TOP_BOTTOM":
        return topBottomStructure;
      case "TOP_MID_BOTTOM":
        return topMidBottomStructure;
      default:
        console.log("Something has went wrong with structure selection.");
        return leftRightStructure; // Display left right as default since it is the most common structure
    }
  };

  function playPinyin(audioPath) {
    const pinyinSoundEffect = new Audio(audioPath);
    pinyinSoundEffect.play();
  }

  // Convert results into a formatted string
  const sharingTextCommon =
    `MatchIt! | ${dateNow} | ${possibleCharacters.length * 10 - timeLeft}s | ${
      formedCharacters.length
    }/${possibleCharacters.length}\n\n` +
    possibleCharacters
      .map((char, index) =>
        formedCharacters.includes(char.character_unicode)
          ? `✅${char.chinese}`
          : `❌${char.chinese}`
      )
      .reduce((acc, cur, index) => {
        const lineIndex = Math.floor(index / 5);
        acc[lineIndex] = (acc[lineIndex] || []).concat(cur);
        return acc;
      }, [])
      .map((line) => line.join(" ")) // Join each line with spaces
      .join("\n") +
    `\n\n`;

  const shareText =
    sharingTextCommon + `Play Now: https://pinit-nus.com/matchit/`;

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(shareText);
      setCopied(true);
      success("Copied to Clipboard");
      setTimeout(() => setCopied(false), 2000); // Reset after 2s
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    try {
      const response = await submitMatchItFeedback(values);
      success("Feedback submitted successfully");
      setIsSurveyModalVisible(false);
      setIsSharingModalVisible(true);
      form.resetFields();
    } catch (err) {
      error(err);
    }
    setIsLoading(false);
  };

  return (
    <div className={styles.gameContentBox}>
      {contextHolder}
      {showCorrectAnimation && (
        <div className={styles.animationBox}>
          <CorrectAnimation />
        </div>
      )}
      {showWrongAnimation && (
        <div className={styles.animationBox}>
          <WrongAnimation />
        </div>
      )}
      {showTimesUpAnimation && (
        <div className={styles.animationBox}>
          <TimesUpAnimation />
        </div>
      )}
      {/* Modal For Word Formed */}
      <Modal
        // title="Word Formed"
        open={isModalVisible}
        footer={null}
        onOk={handleModalClose}
        onCancel={handleModalClose}
      >
        {characterFormed && (
          <div className={styles.modalWordFormed}>
            <div className={`${styles.infoTop} ${styles.info}`}>
              <b>Pinyin</b>: {characterFormed.pinyin}
              <Button
                type="text"
                size="medium"
                icon={<SoundFilled />}
                onClick={() =>
                  playPinyin(CCL_ASSETS_PATH + characterFormed.audio_path)
                }
              />
              <br />
              <b>Meaning</b>: {characterFormed.meaning}
              <br />
              <>
                <b>Example</b>: {characterFormed.word} (
                {characterFormed.word_pinyin}), {characterFormed.word_meaning}
              </>
            </div>
            <div className={styles.imageContainer}>
              <img
                src={getStructure(characterFormed.structure_type)}
                className={`${styles.structureImage}`}
                alt="structure"
              />
              <img
                src={`${CCL_ASSETS_PATH}${characterFormed.character_svg_path}`}
                alt={`Modal Character`}
                className={styles.modalImage}
              />
            </div>
          </div>
        )}
      </Modal>
      {/* Modal For Word Meaning */}
      <Modal
        open={isMeaningModalVisible}
        footer={null}
        onOk={handleMeaningModalClose}
        onCancel={handleMeaningModalClose}
        zIndex={1001}
      >
        {meaningCharacter && (
          <>
            <h2 className={`${styles.infoTop} ${styles.info}`}>
              <b>Pinyin</b>: {meaningCharacter.pinyin}
              <Button
                type="text"
                size="medium"
                icon={<SoundFilled />}
                onClick={() =>
                  playPinyin(CCL_ASSETS_PATH + meaningCharacter.audio_path)
                }
              />
              <br />
              <b>Meaning</b>: {meaningCharacter.meaning}
              <br />
              <>
                <b>Example</b>: {meaningCharacter.word} (
                {meaningCharacter.word_pinyin}), {meaningCharacter.word_meaning}
              </>
            </h2>
            <div className={styles.imageContainer}>
              <img
                src={getStructure(meaningCharacter.structure_type)}
                className={`${styles.structureImage}`}
                alt="structure"
              />
              <img
                src={`${CCL_ASSETS_PATH}${meaningCharacter.character_svg_path}`}
                alt={`Modal Character`}
                className={styles.modalImage}
              />
            </div>
          </>
        )}
      </Modal>
      {/* Sharing Modal */}
      <Modal
        open={isSharingModalVisible}
        footer={null}
        onCancel={() => setIsSharingModalVisible(false)}
        onClose={() => setIsSharingModalVisible(false)}
        zIndex={1002}
      >
        <div className={styles.shareModal}>
          <div className={styles.trophyGif}>
            <img
              src={TrophyAnimation}
              alt="Trophy Gif"
              className={styles.trophyImage}
            />
          </div>
          <div className={styles.congratulations}>Congratulations!</div>
          <Divider className={styles.divider} />
          <div className={styles.shareModalPreviewImageContainer}>
            <div className={styles.statisticsTitle}>Statistics</div>
            <div className={styles.statisticsContainer}>
              <div>
                <div className={styles.statisticsWord}>
                  {Math.min(
                    Math.round(
                      (formedCharacters.length / possibleCharacters.length) *
                        100
                    ),
                    100
                  )}
                  %
                </div>
                <div>Correctness</div>
              </div>
              <div>
                <div className={styles.statisticsWord}>
                  {possibleCharacters.length * 10 - timeLeft}s
                </div>
                <div>Time Taken</div>
              </div>
              <div>
                <div className={styles.statisticsWord}>
                  {dateNow && dateNow}
                </div>
                <div>Date</div>
              </div>
            </div>
          </div>
          <div>
            <div className={styles.previewText}>Preview</div>
            <TextArea
              value={shareText}
              rows={6}
              disabled
              style={{
                backgroundColor: "white", // Keep background white
                color: "black", // Keep text color normal
                cursor: "not-allowed", // Indicate it's disabled
                opacity: 1, // Prevent greying out
              }}
            ></TextArea>
          </div>
          <div className={styles.shareStatistics}>
            <div>
              <Button
                className={styles.shareStatisticsButton}
                type="primary"
                icon={copied ? <CheckOutlined /> : <ShareAltOutlined />}
                iconPosition="end"
                size="large"
                onClick={copyToClipboard}
              >
                {copied ? "Copied!" : "Share Record"}
              </Button>
            </div>
            <div>
              <Button
                icon={<ReloadOutlined />}
                iconPosition="end"
                size="large"
                onClick={() => window.location.reload()}
                style={{
                  margin: "0.5rem auto 0",
                  padding: "0 1.5rem",
                  maxWidth: "12rem",
                  minWidth: "12rem",
                }}
              >
                Try Again
              </Button>
            </div>
          </div>
          <Divider className={styles.divider} />
          <div className={styles.shareModalIconsContainer}>
            <Button
              type="primary"
              shape="circle"
              icon={<WechatFilled style={{ fontSize: "32px" }} />}
              style={{ background: "#09B83E", width: "48px", height: "48px" }}
              href={"weixin://dl/chat"}
              onClick={copyToClipboard}
            />
            {/* In Twitter, the url is added below the title automatically. */}
            <TwitterShareButton
              children={<XIcon size={48} round={true} />}
              title={sharingTextCommon}
              url={"https://pinit-nus.com/matchit/"}
            />
            <WhatsappShareButton
              children={<WhatsappIcon size={48} round={true} />}
              title={sharingTextCommon}
              url={"https://pinit-nus.com/matchit/"}
            />
            <TelegramShareButton
              children={<TelegramIcon size={48} round={true} />}
              title={sharingTextCommon}
              url={"https://pinit-nus.com/matchit/"}
            />
          </div>
        </div>
      </Modal>
      <Modal
        title={"Feedback"}
        open={isSurveyModalVisible}
        footer={null}
        onCancel={() => {
          setIsSurveyModalVisible(false);
          setIsSharingModalVisible(true);
        }}
        onClose={() => {
          setIsSurveyModalVisible(false);
          setIsSharingModalVisible(true);
        }}
        zIndex={1002}
      >
        <div>
          Thank you for playing MatchIt! Your feedback is invaluable in helping
          us improve the app! 🎉
        </div>
        <div style={{ fontWeight: "bold", fontSize: "12px" }}>
          <div>1 - Strongly Disagree</div>
          <div>2 - Disagree</div>
          <div>3 - Neutral</div>
          <div>4 - Agree</div>
          <div>5 - Strongly Agree</div>
        </div>
        <Divider />
        <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
          <Form.Item
            label="1. The game exposed me to new Chinese Characters I was not familiar with."
            name="exposure"
            rules={[{ required: true, message: "Please rate your experience" }]}
          >
            <Radio.Group
              options={[
                {
                  value: "STRONGLY_DISAGREE",
                  label: "1",
                  style: { fontSize: "12px" },
                },
                { value: "DISAGREE", label: "2", style: { fontSize: "12px" } },
                { value: "NEUTRAL", label: "3", style: { fontSize: "12px" } },
                { value: "AGREE", label: "4", style: { fontSize: "12px" } },
                {
                  value: "STRONGLY_AGREE",
                  label: "5",
                  style: { fontSize: "12px" },
                },
              ]}
              style={{ display: "flex", justifyContent: "space-evenly" }}
            />
          </Form.Item>

          <Form.Item
            label="2. The game increased my interest towards learning Chinese Characters."
            name="interest"
            rules={[{ required: true, message: "Please rate your experience" }]}
          >
            <Radio.Group
              options={[
                {
                  value: "STRONGLY_DISAGREE",
                  label: "1",
                  style: { fontSize: "12px" },
                },
                { value: "DISAGREE", label: "2", style: { fontSize: "12px" } },
                { value: "NEUTRAL", label: "3", style: { fontSize: "12px" } },
                { value: "AGREE", label: "4", style: { fontSize: "12px" } },
                {
                  value: "STRONGLY_AGREE",
                  label: "5",
                  style: { fontSize: "12px" },
                },
              ]}
              style={{ display: "flex", justifyContent: "space-evenly" }}
            />
          </Form.Item>

          <Form.Item
            label="3. Playing the game helped me recognize Chinese Characters more easily."
            name="recognition"
            rules={[{ required: true, message: "Please rate your experience" }]}
          >
            <Radio.Group
              options={[
                {
                  value: "STRONGLY_DISAGREE",
                  label: "1",
                  style: { fontSize: "12px" },
                },
                { value: "DISAGREE", label: "2", style: { fontSize: "12px" } },
                { value: "NEUTRAL", label: "3", style: { fontSize: "12px" } },
                { value: "AGREE", label: "4", style: { fontSize: "12px" } },
                {
                  value: "STRONGLY_AGREE",
                  label: "5",
                  style: { fontSize: "12px" },
                },
              ]}
              style={{ display: "flex", justifyContent: "space-evenly" }}
            />
          </Form.Item>

          <Form.Item
            label="4. I would recommend this game to others who want to learn Chinese Characters."
            name="recommendation"
            rules={[{ required: true, message: "Please rate your experience" }]}
          >
            <Radio.Group
              options={[
                {
                  value: "STRONGLY_DISAGREE",
                  label: "1",
                  style: { fontSize: "12px" },
                },
                { value: "DISAGREE", label: "2", style: { fontSize: "12px" } },
                { value: "NEUTRAL", label: "3", style: { fontSize: "12px" } },
                { value: "AGREE", label: "4", style: { fontSize: "12px" } },
                {
                  value: "STRONGLY_AGREE",
                  label: "5",
                  style: { fontSize: "12px" },
                },
              ]}
              style={{ display: "flex", justifyContent: "space-evenly" }}
            />
          </Form.Item>

          <Form.Item
            label="5. I would continue playing this game to learn more Chinese Characters."
            name="continuation"
            rules={[{ required: true, message: "Please rate your experience" }]}
          >
            <Radio.Group
              options={[
                {
                  value: "STRONGLY_DISAGREE",
                  label: "1",
                  style: { fontSize: "12px" },
                },
                { value: "DISAGREE", label: "2", style: { fontSize: "12px" } },
                { value: "NEUTRAL", label: "3", style: { fontSize: "12px" } },
                { value: "AGREE", label: "4", style: { fontSize: "12px" } },
                {
                  value: "STRONGLY_AGREE",
                  label: "5",
                  style: { fontSize: "12px" },
                },
              ]}
              style={{ display: "flex", justifyContent: "space-evenly" }}
            />
          </Form.Item>

          <Form.Item label="6. Any other feedback?" name="feedback">
            <Input.TextArea rows={3} placeholder="Share your thoughts..." />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Submit Feedback
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <div className={styles.nusLogoImageBox}>
        <img
          src={nusLogos}
          alt="SOC and FASS Logos"
          className={styles.nusLogoImage}
        />
      </div>
      {/* Header Box */}
      <div className={styles.headerBox} ref={ref4}>
        <div className={styles.gameTitle}>MatchIt!</div>
        <div
          className={styles.progressContainer}
          style={{ position: "relative", display: "flex" }}
        >
          {milestones.map((milestone) => (
            <div
              key={milestone}
              className="milestone"
              style={{
                left: `${milestone - 10}%`,
                zIndex: 3,
                position: "absolute",
                top: -40,
              }}
            >
              {Math.min(
                Math.round(
                  (formedCharacters.length / possibleCharacters.length) * 100
                ),
                100
              ) >= milestone && <StarAnimation />}
            </div>
          ))}
          <ProgressBar
            className={styles.progressBar}
            progressColor={"red"}
            score={Math.min(
              Math.round(
                (formedCharacters.length / possibleCharacters.length) * 100
              ),
              100
            )}
          />
        </div>

        <Progress
          type="circle"
          percent={(timeLeft / 60) * 100} // Convert time left to a percentage
          strokeColor={strokeColor} // Dynamic stroke color
          format={() => `${timeLeft}s`} // Display time left in seconds
          size={60} // Adjust size as needed
          className={styles.timer}
        />
      </div>
      <div className={styles.possibleCharacterContainer} ref={ref1}>
        <div className={styles.possibleCharacterCount}>
          Total Possible Characters: {possibleCharacters.length}
        </div>
        <div className={styles.possibleCharacterGrid}>
          {possibleCharacters.map((char, index) => (
            <button
              key={"answer-" + char.id}
              className={
                duplicateCharacter === char.character_unicode
                  ? styles.duplicateCharacterCard
                  : formedCharacters.includes(char.character_unicode)
                  ? styles.characterCard
                  : styles.greyedOutCharacterCard
              }
              onClick={() => {
                setMeaningCharacter(char);
                setIsMeaningModalVisible(true);
              }}
              disabled={!isFreeze}
              ref={index === 0 ? endRef0 : null}
            >
              <img
                src={`${CCL_ASSETS_PATH}${char.character_svg_path}`}
                alt={`Character ${char.id}`}
                className={
                  isFreeze
                    ? styles.possibleCharacterImage
                    : duplicateCharacter === char.character_unicode
                    ? styles.duplicateCharacterImage
                    : formedCharacters.includes(char.character_unicode)
                    ? styles.possibleCharacterImage
                    : styles.greyedOutCharacterImage
                }
              />
            </button>
          ))}
        </div>
      </div>

      {/* Display selected options */}
      <div className={styles.selectedOptionsContainer} ref={ref3}>
        {selectedOptions.map((optionUnicode, index) => {
          const selectedOption = options.find(
            (option) => option.optionUnicode === optionUnicode
          );
          return (
            <div
              key={"selectedbox-" + optionUnicode}
              className={styles.selectedBox}
            >
              <div
                key={"selected-" + optionUnicode}
                className={styles.selectedOptionCard}
              >
                <img
                  src={selectedOption.optionImagePath}
                  alt={`Selected Option ${optionUnicode}`}
                  className={styles.selectedOptionImage}
                />
                <button
                  className={styles.crossButton}
                  onClick={() => handleUndoSelection(optionUnicode)}
                  disabled={isFreeze}
                >
                  ×
                </button>
              </div>

              {index < selectedOptions.length - 1 && (
                <div className={styles.plusSign}>+</div>
              )}
            </div>
          );
        })}
      </div>

      {/* Bottom character selection buttons */}
      <div className={styles.characterGrid} ref={ref2}>
        {options.map((option) => (
          <button
            key={"button-" + option.optionUnicode}
            className={`${styles.characterButton} ${
              selectedOptions.includes(option.optionUnicode)
                ? styles.selected
                : ""
            }`}
            onClick={() => handleOptionClick(option.optionUnicode)}
            disabled={
              selectedOptions.includes(option.optionUnicode) || isFreeze
            } // Disable selected buttons
          >
            <div
              className={`${
                options.length >= 6 ? styles.smallOptionCell : styles.optionCell
              } `}
            >
              <img
                src={option.optionImagePath}
                alt={`Option ${option.optionUnicode}`}
                className={styles.optionImage}
              />
            </div>
          </button>
        ))}
      </div>
      <Tour
        open={open}
        onClose={() => {
          localStorage.setItem("openWalkthrough", false);
          setOpen(false);
          setPaused(false);
          setFloatButtonOpen(false);
        }}
        steps={steps}
        disabledInteraction
      />
      <Tour
        open={openEndingTour}
        onClose={() => {
          setOpenEndingTour(false);
        }}
        steps={endingSteps}
        mask={false}
        placement="left"
        zIndex={1000}
      />
      <FloatButton.Group
        shape="circle"
        style={{ insetInlineEnd: "1.5vw" }}
        open={floatButtonOpen}
        trigger="click"
        onClick={() => setFloatButtonOpen(!floatButtonOpen)}
        icon={
          <EllipsisOutlined
            style={{ transform: "rotate(90deg)" }}
            ref={refMinus1}
          />
        }
      >
        <Popover
          placement="top"
          content={<div>Feedback</div>}
          style={{ textAlign: "center" }}
        >
          <FloatButton
            icon={<MessageOutlined />}
            onClick={() => setIsSurveyModalVisible(true)}
            disabled={!isFreeze}
            style={{
              backgroundColor: !isFreeze ? "#C6C6C6" : "white",
              cursor: !isFreeze ? "not-allowed" : "pointer",
            }}
          />
        </Popover>
        <Popover
          placement="top"
          content={<div>Share</div>}
          style={{ textAlign: "center" }}
        >
          <FloatButton
            icon={<ShareAltOutlined />}
            style={{
              backgroundColor: !isFreeze ? "#C6C6C6" : "white",
              cursor: !isFreeze ? "not-allowed" : "pointer",
            }}
            onClick={() => {
              setIsSharingModalVisible(true);
            }}
            disabled={!isFreeze}
            ref={endRef1}
          />
        </Popover>
        <Popover
          placement="top"
          content={<div>Walkthrough</div>}
          style={{ textAlign: "center" }}
        >
          <FloatButton
            icon={<QuestionCircleOutlined />}
            ref={ref0}
            onClick={() => {
              setOpen(true);
              setPaused(true);
            }}
          />
        </Popover>
      </FloatButton.Group>
    </div>
  );
}

export default DragAndDropMatchGame;

import { ConfigProvider, Divider, Empty, Image, Pagination, Spin } from "antd";
import Search from "antd/es/input/Search";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { getImages } from "../../apiHandlers/imageSearchApi";
import styles from "./SelectImageComponent.module.scss";

const SelectImageComponent = ({
  characterId,
  onSelectImage,
  defaultSearchTerm,
}) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [searchTerm, setSearchTerm] = useState(defaultSearchTerm);
  const [images, setImages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalImages, setTotalImages] = useState(0);
  const [loading, setLoading] = useState(false);
  const cache = useRef(new Map()); // Client cache for storing images from api

  const imagesPerPage = 3;
  const isManualSearch = searchTerm !== defaultSearchTerm;

  const fetchImages = useCallback(async (query, page) => {
    const cacheKey = `${query}-${page}`;

    if (cache.current.has(cacheKey)) {
      const { hits, totalHits } = cache.current.get(cacheKey);
      setImages(hits);
      setTotalImages(totalHits);
      return;
    }

    setLoading(true);
    try {
      const { hits, totalHits } = await getImages(characterId, query, page, imagesPerPage, isManualSearch);
      cache.current.set(cacheKey, { hits, totalHits });
      setImages(hits);
      setTotalImages(totalHits);
    } catch (error) {
      console.error("Error fetching images:", error);
      setImages([]);
      setTotalImages(0);
    } finally {
      setLoading(false);
    }
  }, [characterId, imagesPerPage, isManualSearch]);

  useEffect(() => {
    if (searchTerm) {
      fetchImages(searchTerm, currentPage);
    }
  }, [searchTerm, currentPage, fetchImages]);

  // Toggle selection and deselection of an image
  const handleImageClick = (image) => {
    if (selectedImage?.id === image.id) {
      // Deselect if the clicked image is already selected
      setSelectedImage(null);
      onSelectImage(null); // Pass null to the parent to indicate deselection
    } else {
      // Select the image if it isn't currently selected
      setSelectedImage(image);
      onSelectImage(image); // Pass the selected image back to the parent
    }
  };
  const handleSearch = (value) => {
    setSearchTerm(value);
    setCurrentPage(1);
  };

  return (
    <div>
      <div className={styles.searchInput}>
        <ConfigProvider
          theme={{
            token: {
              colorTextPlaceholder: "#8A8B86",
            },
          }}
        >
          <Search
            placeholder="Search images..."
            defaultValue={defaultSearchTerm}
            onSearch={handleSearch}
            enterButton
            allowClear
            size="large"
          />
        </ConfigProvider>
      </div>

      <Spin spinning={loading} tip="Loading..." size="large">
        {loading ? (
          <div className={styles.loadingContainer}></div>
        ) : images.length ? (
          <div className={styles.imageGrid}>
            {images.map((image, index) => (
              <div
                key={image.webformatURL}
                className={`${styles.imageWrapper} ${
                  selectedImage?.id === image.id ? styles.selected : ""
                }`}
                onClick={() => handleImageClick(image)}
              >
                <Image
                  src={image.webformatURL}
                  width={100}
                  height={100}
                  preview={false}
                  className={styles.image}
                />
                <p className={styles.imageLabel}>
                  {image.tags.split(",").slice(0, 3).join(", ")}
                </p>
              </div>
            ))}
          </div>
        ) : (
          <div className={styles.loadingContainer}>
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No images found."
            />
          </div>
        )}
      </Spin>

      <Pagination
        current={currentPage}
        onChange={(page) => setCurrentPage(page)}
        pageSize={imagesPerPage}
        total={totalImages}
        showSizeChanger={false}
        className={styles.pagination}
      />
      <Divider />
    </div>
  );
};

export default SelectImageComponent;

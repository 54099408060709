import { Button, Modal, Rate } from "antd";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styles from "./ClassroomFeedback.module.scss";
import TextArea from "antd/es/input/TextArea";
import {
  submitClassroomFeedbackForTeacher,
} from "../../apiHandlers/classroomFeedbackApiHandler";


const ImageSelectionFeedback = ({
    userType,
    userId,
    isSurveyModalOpen,
    closeModal,
    callbackOnSuccessfulSubmit = () => {},
  }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [data, setData] = useState({});
  
    useEffect(() => {
      setData((prevData) => ({ ...prevData, userType, userId }));
    }, [userType, userId]);
  
    const submitData = async (submitByClosingPanel) => {
      if (isSubmitting) return;
  
      // const isFormFilled = Object.keys(data).some(key => key !== "userType" && key !== "userId");
      const isFormFilled = ["userType", "easeOfUse", "imageRelevance", "engagement", "learning", "satisfaction"].every(key => !!data?.[key] &&
        data["willingToInterview"] !== undefined);
      if (!isFormFilled) {
        toast.info("Please respond to all required fields", {
          autoClose: 2000,
        });
        return;
      }
  
      setIsSubmitting(true);
      try {
        await submitClassroomFeedbackForTeacher(data);
        toast.success("Thank you for your feedback! :)", { autoClose: 1000 });
        setIsSubmitting(false);
        callbackOnSuccessfulSubmit();
        return true;
      } catch (error) {
        if (!submitByClosingPanel) {
          toast.error("Oops, couldn't submit feedback. Please check your connection and try again later.", { autoClose: 4000 });
        }
      }
      setIsSubmitting(false);
      return false;
    };
  
    const handleClosePanel = () => {
      closeModal();
      // await submitData(true);
    };
  
    const handleSubmitClicked = async () => {
      const success = await submitData(false);
      if (success) closeModal();
    };
  
    return (
      <Modal
      styles={{
        body: {
          maxHeight: "85vh",
          overflowY: "auto",
        },
      }}
        open={isSurveyModalOpen}
        footer={          
        <Button type="primary" size={"large"} onClick={handleSubmitClicked} loading={isSubmitting}> 
          Submit
        </Button>
}
        onOk={handleClosePanel}
        onCancel={handleClosePanel}
        centered
        width={850}
      >
        <div className={styles.surveyPanel}>
          <h3 style={{ fontSize: 18, color: "#004AAD" }}>🌟 Thank you for using the Image Selection feature!</h3>
          <h3 style={{ fontSize: 18, color: "#004AAD" }}>Your feedback is invaluable in helping us improve this app. 🚀</h3>
  
          {[
            { label: "I found it easy to search and select images for characters.", key: "easeOfUse" },
            { label: "The suggested images (from the first 3 pages) were relevant and matched the meanings of the characters.", key: "imageRelevance" },
            { label: "These images will help make learning more fun and engaging.", key: "engagement" },
            { label: "These images will help students better understand and remember the characters.", key: "learning" },
            { label: "I am satisfied with the image selection feature overall.", key: "satisfaction" },
          ].map((item) => (
            <div key={item.key}>
              <p className={styles.shareSubheading}>{item.label}</p>
              <Rate
                style={{ color: "#ffbd03", fontSize: 40 }}
                className={styles.madeCenter}
                allowClear={false}
                onChange={(value) => setData((prevData) => ({ ...prevData, [item.key]: value }))}
              />
            </div>
          ))}
  
          <p className={styles.shareSubheading}>
            (Optional) Do you have any suggestions for improvement?
          </p>
          <TextArea
            style={{ fontSize: 20 }}
            className={styles.madeCenter}
            maxLength={980}
            autoSize={{ minRows: 2 }}
            onChange={(e) => setData((prevData) => ({ ...prevData, feedback: e.target.value }))}
          />

          <p className={styles.shareSubheading}>
            I am open to being interviewed to share more about my experience.
          </p>
          <div className={styles.madeCenter} style={{ marginBottom: 20 }}>
              <Button
                  type={data.willingToInterview ? "primary" : "default"}
                  onClick={() => setData((prevData) => ({ ...prevData, willingToInterview: true }))}
                  style={{ marginRight: 10 }}
              >
                  Yes
              </Button>
              <Button
                  type={data.willingToInterview === false ? "primary" : "default"}
                  onClick={() => setData((prevData) => ({ ...prevData, willingToInterview: false }))}
              >
                  No
              </Button>
          </div>
  
        </div>
      </Modal>
    );
  };
  
  export default ImageSelectionFeedback;
  
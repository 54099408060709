import { Progress } from "antd";
import React, { useEffect, useState } from "react";
import styles from "./Timer.module.scss";

export const COUNTDOWN_TIMER = 30;
export const COUNTDOWN_HALF_TIMER = 20;
export const COUNTDOWN_ENDING_TIMER = 10;

function Timer({ onTimerEnd, isTimerRunning, getSeconds, roundCount }) {
  const [seconds, setSeconds] = useState(COUNTDOWN_TIMER);
  // isTimeOver is used to differentiate from time end informed by running
  const [isTimeOver, setIsTimeOver] = useState(false);
  const [isTimeEnding, setIsTimeEnding] = useState(false);

  useEffect(() => {
    if (isTimerRunning) {
      const interval = setInterval(() => {
        setSeconds((prevSeconds) => {
          const newSeconds = prevSeconds - 1;
          getSeconds(newSeconds); // Notify the parent component of the updated seconds value
          return newSeconds;
        });
      }, 1000);

      return () => clearInterval(interval);
    } else {
      setIsTimeEnding(false);
    }
  }, [isTimerRunning]);

  useEffect(() => {
    // Magnify countdown effect when time is ending
    if (seconds === COUNTDOWN_ENDING_TIMER) {
      setIsTimeEnding(true);
    }
    // Notify parents to skip game when time is ending and update timer style
    if (seconds === 0) {
      onTimerEnd(); // Notify the parent
      setIsTimeEnding(false);
      setIsTimeOver(true);
    }
  }, [seconds]);

  // Reset timer on new character round
  useEffect(() => {
    setSeconds(COUNTDOWN_TIMER);
    setIsTimeOver(false);
    setIsTimeEnding(false);
  }, [roundCount]);

  // Timer color is Green6, Yellow6, Volcano5, Red7
  return (
    <Progress
      type="circle"
      percent={(seconds / COUNTDOWN_TIMER) * 100}
      format={() => (isTimeOver ? "SKIP" : `${seconds}s`)}
      strokeColor={
        seconds > COUNTDOWN_HALF_TIMER
          ? "#52c41a"
          : seconds > COUNTDOWN_ENDING_TIMER
          ? "#fadb14"
          : "#ff7a45"
      }
      trailColor={isTimeOver ? "#cf1322" : "rgba(0, 0, 0, 0.06)"}
      size={50}
      className={`${styles.timer} ${isTimeEnding ? styles.endingTimer : ""}`}
    />
  );
}

export default Timer;

import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { getQuickPlaySession, getSrsSession } from "../../apiHandlers/gameApi";
// import { gameContent } from "../../utils/gameData";
import DragAndDropGame from "../components/DragAndDropGame";
import PageLayout from "./PageLayout";
import styles from "./SrsReviewSessionPage.module.scss";
import SrsSessionComponent from "../components/SrsSessionComponent";
import { useLocation, useNavigate } from "react-router-dom";

const SrsReviewSessionPage = () => {
  const queryParams = new URLSearchParams(useLocation().search);
  const userId = queryParams.get("user_id");
  const [gameData, setGameData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      const diffLevel = -1;
      try {
        const data = await getSrsSession(userId);
        // console.log("data", data);
        if (Array.isArray(data) && data.length === 0) {
          navigate("/srs-research", { state: { isEmpty: true }});
        } else {
          setGameData(data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  // console.log(gameData);
  const body = (
    <div className={styles.gameMenu}>
      {gameData === null ? (
        <div className={styles.loading}>
          <Spin size="large" />
        </div>
      ) : (
        <DragAndDropGame
          gameContent={gameData}
          maxRoundCount={20}
          gameMode={3}
          userId={userId}
        />
      )}
    </div>
  );

  return <PageLayout body={body} displayMode={"overlay"} />;
};

export default SrsReviewSessionPage;
